import { Row, Col, Input, Button, Select, Space } from 'antd';
import { SearchOutlined, UserAddOutlined } from '@ant-design/icons';

const { Option } = Select;

export function SearchBar({ 
  filtro, 
  setFiltro, 
  CarregaDados, 
  isloading,
  setIsModalOpenCadastrar,
  statusFiltro,
  setStatusFiltro,
  clienteFiltro,
  setClienteFiltro,
  clientes
}) {
  return (
    <div className="search-container">
      {/* Título da página */}
      <Row className="header-row">
        <Col span={24}>
          <div className="page-header">
            <span className="page-title">Contadores</span>
          </div>
        </Col>
      </Row>

      {/* Filtros */}
      <Row gutter={[24, 16]} className="filters-row">
        {/* Busca */}
        <Col xs={24} lg={6}>
          <div className="filter-item">
            <label>Buscar contador</label>
            <Input
              placeholder="Digite para buscar..."
              value={filtro}
              onChange={(e) => setFiltro(e.target.value)}
              prefix={<SearchOutlined style={{ color: '#8c8c8c' }} />}
            />
          </div>
        </Col>
        
        {/* Cliente Vinculado */}
        <Col xs={24} lg={10}>
          <div className="filter-item">
            <label>Cliente vinculado</label>
            <Select
              showSearch
              placeholder="Selecione o cliente"
              style={{ width: '100%' }}
              value={clienteFiltro}
              onChange={setClienteFiltro}
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {clientes?.map(cliente => (
                <Option key={cliente.VALOR} value={cliente.VALOR}>
                  {cliente.LABEL}
                </Option>
              ))}
            </Select>
          </div>
        </Col>

        {/* Status */}
        <Col xs={24} lg={3}>
          <div className="filter-item">
            <label>Status</label>
            <Select
              placeholder="Status"
              style={{ width: '100%' }}
              value={statusFiltro}
              onChange={setStatusFiltro}
              allowClear
            >
              <Option value="A">Ativo</Option>
              <Option value="I">Inativo</Option>
            </Select>
          </div>
        </Col>

        {/* Botões */}
        <Col xs={24} lg={5}>
          <div className="buttons-container">
            <Button 
              type="primary"
              onClick={CarregaDados}
              loading={isloading}
              icon={<SearchOutlined />}
            >
              Buscar
            </Button>

            <Button
              type="primary"
              icon={<UserAddOutlined />}
              onClick={() => setIsModalOpenCadastrar(true)}
            >
              Novo
            </Button>
          </div>
        </Col>
      </Row>

      <style jsx>{`
        .search-container {
          background: #fff;
          padding: 24px;
          border-radius: 8px;
          margin-bottom: 24px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }

        .header-row {
          margin-bottom: 24px;
        }

        .page-header {
          border-bottom: 2px solid #1890ff;
          padding-bottom: 8px;
        }

        .page-title {
          font-size: 18px;
          font-weight: 500;
        }

        .filters-row {
          margin-top: 8px;
        }

        .filter-item {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .filter-item label {
          font-size: 14px;
          font-weight: 500;
          color: #262626;
        }

        .buttons-container {
          display: flex;
          gap: 8px;
          height: 100%;
          align-items: flex-end;
        }

        @media (max-width: 992px) {
          .buttons-container {
            margin-top: 24px;
            justify-content: flex-end;
          }
        }

        :global(.ant-input-affix-wrapper) {
          height: 32px;
        }

        :global(.ant-select-selector) {
          height: 32px !important;
        }

        :global(.ant-select-selection-search-input) {
          height: 30px !important;
        }

        :global(.ant-btn) {
          height: 32px;
          padding: 4px 16px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        :global(.anticon) {
          margin-right: 8px;
        }
      `}</style>
    </div>
  );
}
