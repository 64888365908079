import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import { mask, mensagem } from "../../../Helpers/UsaGeral.js";
import { BtContato, BtDetalhe, Container } from "./style";
import { SearchOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions/index.js";
import { useClientes } from "../../../Services/Clientes/useClientes.js";
import { useUsuario } from "../../../Contexts/auth.js";
import TextArea from "antd/es/input/TextArea.js";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import axios from "axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useChamados } from "../../../Services/Chamados/useChamados.js";
import { FileAddOutlined, SendOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const bucket_upload = "sistema-atendimento";

export function AbrirChamados() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [statusCliente, setStatusCliente] = useState("S");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listarModulos, setListaModulos] = useState([]);
  const [listarTelas, setListarTelas] = useState([]);
  const [listarTipos, setListarTipos] = useState([]);
  const [listarStatus, setListarStatus] = useState([]);
  const [dadosCliente, setClientes] = useState([]);
  const navigate = useNavigate();

  const { user } = useUsuario();

  const [projetos, setProjetos] = useState("1");
  const [modulos, setModulos] = useState();

  const [tela, setTela] = useState();
  const [cliente, setCliente] = useState();
  const [tipo, setTipo] = useState();
  const [status, setStatus] = useState();
  const [banco, setBanco] = useState("");
  const [detalhes, setDetalhes] = useState("");

  // Estado para controlar carregamento individual
  const [loadingStates, setLoadingStates] = useState({
    clientes: false,
    modulos: false,
    telas: false,
    tipos: false,
    status: false,
    submit: false
  });

  // Função genérica para tratar erros
  const handleRequestError = (error, message) => {
    console.error(error);
    mensagem('error', message);
    return [];
  };

  // Funções de carregamento melhoradas
  async function CarregaDadosCliente() {
    setLoadingStates(prev => ({ ...prev, clientes: true }));
    try {
      const api = await useChamados();
      const response = await api.ConsultaClientes(user.TOKEN);
      
      if (!response?.data) {
        throw new Error('Dados de clientes não encontrados');
      }
      
      setClientes(response.data);
    } catch (error) {
      handleRequestError(error, 'Erro ao carregar clientes');
      setClientes([]);
    } finally {
      setLoadingStates(prev => ({ ...prev, clientes: false }));
    }
  }

  async function CarregaDadosModulos() {
    setLoadingStates(prev => ({ ...prev, modulos: true }));
    try {
      const api = await useChamados();
      const response = await api.ConsultaModulo(user.TOKEN);
      
      if (response.status !== 200 || !response?.data) {
        throw new Error('Dados de módulos não encontrados');
      }
      
      setListaModulos(response.data);
    } catch (error) {
      handleRequestError(error, 'Erro ao carregar módulos');
      setListaModulos([]);
    } finally {
      setLoadingStates(prev => ({ ...prev, modulos: false }));
    }
  }

  async function CarregaDadosTelas() {
    const api = await useChamados();
    await api.ConsultaTelas(user.TOKEN).then((res) => {
      console.log(res);
      setListarTelas((V) => res.data);
    });
  }

  async function CarregaDadosTipos() {
    const api = await useChamados();
    await api.TiposChamados(user.TOKEN).then((res) => {
      setListarTipos(() => res.data);
    });
  }

  async function CarregaDadosStatus() {
    const api = await useChamados();
    await api.StatusChamados(user.TOKEN).then((res) => {
      setListarStatus(() => res.data);
    });
  }

  // Função para carregar todos os dados iniciais
  const carregarDadosIniciais = async () => {
    setLoading(true);
    try {
      await Promise.all([
        CarregaDadosCliente(),
        CarregaDadosModulos(),
        CarregaDadosTelas(),
        CarregaDadosTipos(),
        CarregaDadosStatus()
      ]);
    } catch (error) {
      console.error('Erro ao carregar dados iniciais:', error);
      mensagem('error', 'Erro ao carregar dados necessários');
    } finally {
      setLoading(false);
    }
  };

  // Melhorando o submit do formulário
  const AbrirChamados = async (dados) => {
    setLoadingStates(prev => ({ ...prev, submit: true }));
    try {
      const api = await useChamados();
      const response = await api.AbrirChamado(user.TOKEN, dados);
      
      if (!response || response.status !== 200) {
        throw new Error('Erro ao cadastrar chamado');
      }

      mensagem("sucess", "Chamado cadastrado com sucesso!");
      navigate("/ConsultarChamados");
    } catch (error) {
      console.error('Erro ao abrir chamado:', error);
      mensagem('error', 'Erro ao cadastrar chamado. Por favor, tente novamente.');
      throw error; // Propaga o erro para tratamento no onFinish
    } finally {
      setLoadingStates(prev => ({ ...prev, submit: false }));
    }
  };

  const onFinish = async (values) => {
    try {
      const dados = {
        ID_EMPRESA: 1,
        COD_CLIENTE: Number(values.cliente.substring(0, values.cliente.indexOf("-")).trim()),
        MODULO_SISTEMA: values.modulos,
        CODIGO_TELA: values.tela,
        CODIGO_ATENDENTE: user.USUARIO,
        TIPO_CHAMADO: Number(values.tipo.substring(0, values.tipo.indexOf("-")).trim()),
        COD_COLABORADOR_INFORMANTE: user.USUARIO,
        TIPO_SOLICITACAO: "MANUAL",
        COD_FRANQUEADO: 0,
        COD_ANALISTA_SOLICITADO: '',
        NOME_BANCO_DADOS: values.banco,
        DETALHES: values.obs,
        STATUS_CHAMADO: Number(values.status.substring(0, values.status.indexOf("-")).trim()),
        STATUS_CLIENTE: statusCliente,
        DATA_HRS_MOVIMENTACAO: new Date(),
        COD_COLABORADOR_SOLICITADO: user.USUARIO,
        PROJETO_MCN: Number(values.projeto.substring(0, values.projeto.indexOf("-")).trim()),
        FILA_PRIORIDADE: 0,
        UPLOADS: uploadedFiles
      };

      await AbrirChamados(dados);
    } catch (error) {
      // Erro já tratado em AbrirChamados
    }
  };

  // Inicialização dos dados
  useEffect(() => {
    carregarDadosIniciais();
  }, []);

  const props = {
    action: "https://tutoriais.mcnsistemas.net.br/api/uploads/enviarArquivo",

    onChange({ file, fileList }) {
      if (file.status === "done") {
        setUploadedFiles(fileList);
        console.log(file);
      }
    },
  };

  return (
    <div style={{ 
      background: '#f0f2f5',
      minHeight: '100vh',
      padding: '24px'
    }}>
      <div style={{ 
        background: '#fff',
        padding: '24px',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        maxWidth: '1200px',
        margin: '0 auto'
      }}>
        {/* Cabeçalho */}
        <div style={{ 
          borderBottom: '2px solid #1890ff',
          marginBottom: '24px',
          paddingBottom: '12px',
          display: 'flex',
          alignItems: 'center',
          gap: '12px'
        }}>
          <FileAddOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
          <h1 style={{ 
            margin: 0,
            fontSize: '20px',
            fontWeight: 500,
            color: '#1890ff'
          }}>
            Abrir Chamado
          </h1>
        </div>

        {/* Formulário */}
        <Form
          layout="vertical"
          onFinish={onFinish}
          style={{ maxWidth: '100%' }}
        >
          {/* Projeto e Módulos */}
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item 
                label="Projeto" 
                name="projeto" 
                required
                rules={[{ required: true, message: 'Selecione o projeto' }]}
              >
                <Select
                  showSearch
                  placeholder="Selecione o projeto"
                  onChange={setProjetos}
                  value={projetos}
                  loading={loading}
                  style={{ width: '100%' }}
                >
                  <Option value="1 - MCN SISTEMA GERENCIAL">
                    1 - MCN SISTEMA GERENCIAL
                  </Option>
                  <Option value="2 - MOBILE NFE">2 - MOBILE NFE</Option>
                  <Option value="3 - PDV EXTERNO">3 - PDV EXTERNO</Option>
                  <Option value="4 - BI/CRM">4 - BI/CRM</Option>
                  <Option value="5 - MOBILE VENDA EXTERNA">
                    5 - MOBILE VENDA EXTERNA
                  </Option>
                  <Option value="6 - MOBILE INVENTARIO">
                    6 - MOBILE INVENTARIO
                  </Option>
                  <Option value="7 - MOBILE ENTREGA">
                    7 - MOBILE ENTREGA
                  </Option>
                  <Option value="8 - MOBILE CONSULTA PRECO">
                    8 - MOBILE CONSULTA PRECO
                  </Option>
                  <Option value="9 - MCN SUPORTE">9 - MCN SUPORTE</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item 
                label="Módulos" 
                name="modulos"
                rules={[{ required: true, message: 'Selecione o módulo' }]}
              >
                <Select
                  showSearch
                  placeholder="Selecione o módulo"
                  onChange={setModulos}
                  value={modulos}
                  loading={loading}
                  style={{ width: '100%' }}
                >
                  {listarModulos.map(el => (
                    <Option key={el.CODIGO_MODULO} value={el.CODIGO_MODULO}>
                      {el.DESCRICAO_MODULO}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Tela e Cliente */}
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item 
                label="Tela" 
                name="tela"
                rules={[{ required: true, message: 'Informe a tela' }]}
              >
                <Input placeholder="Digite o nome da tela" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item 
                label="Cliente" 
                name="cliente"
                rules={[{ required: true, message: 'Selecione o cliente' }]}
              >
                <Select
                  showSearch
                  placeholder="Selecione o cliente"
                  onChange={setCliente}
                  value={cliente}
                  options={dadosCliente}
                  loading={loadingStates.clientes}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Tipo e Status */}
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item 
                label="Tipo" 
                name="tipo"
                rules={[{ required: true, message: 'Selecione o tipo' }]}
              >
                <Select
                  showSearch
                  placeholder="Selecione o tipo"
                  options={listarTipos}
                  loading={loading}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item 
                label="Status" 
                name="status"
                rules={[{ required: true, message: 'Selecione o status' }]}
              >
                <Select
                  showSearch
                  placeholder="Selecione o status"
                  onChange={setStatus}
                  value={status}
                  options={listarStatus}
                  loading={loading}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Banco de Dados */}
          <Form.Item 
            label="Nome Banco de Dados" 
            name="banco"
            rules={[{ required: true, message: 'Informe o banco de dados' }]}
          >
            <Input placeholder="Digite o nome do banco de dados" />
          </Form.Item>

          {/* Detalhes */}
          <Form.Item 
            label="Detalhes" 
            name="obs"
            rules={[{ required: true, message: 'Descreva os detalhes do chamado' }]}
          >
            <TextArea 
              showCount 
              maxLength={1000} 
              rows={6}
              placeholder="Descreva os detalhes do chamado..."
              style={{ resize: 'none' }}
            />
          </Form.Item>

          {/* Upload */}
          <Form.Item label="Anexos" name="uploads">
            <Dragger {...props} multiple style={{ padding: '24px' }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
              </p>
              <p className="ant-upload-text" style={{ fontSize: '16px', margin: '16px 0' }}>
                Clique ou arraste arquivos para fazer upload
              </p>
              <p className="ant-upload-hint" style={{ color: '#666' }}>
                Suporte para upload de múltiplos arquivos
              </p>
            </Dragger>
          </Form.Item>

          {/* Botão Submit com loading state específico */}
          <Form.Item style={{ marginTop: '24px' }}>
            <Button 
              type="primary" 
              htmlType="submit"
              size="large"
              block
              loading={loadingStates.submit}
              icon={<SendOutlined />}
              style={{ height: '48px', fontSize: '16px' }}
            >
              {loadingStates.submit ? 'Cadastrando...' : 'Abrir Chamado'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
