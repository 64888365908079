import { useState } from 'react';
import { 
  Input, 
  Avatar, 
  Button, 
  List,
  Badge,
  Tooltip,
  Divider 
} from 'antd';
import { 
  UserOutlined, 
  TeamOutlined, 
  ArrowLeftOutlined,
  SendOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { 
  ChatInternalContainer, 
  ChatHeader, 
  ContactList,
  ContactItem,
  Messages,
  MessageInput,
  Message
} from './styles';

export function ChatInterno({ onClose }) {
  const [selectedContact, setSelectedContact] = useState(null);
  const [searchText, setSearchText] = useState('');

  // Dados mockados
  const contacts = [
    {
      id: 1,
      name: 'Grupo Suporte',
      type: 'group',
      members: ['João', 'Maria', 'Pedro'],
      unread: 3,
      lastMessage: 'Precisamos resolver o caso do cliente X',
      lastTime: '10:30'
    },
    {
      id: 2,
      name: 'Maria Silva',
      type: 'individual',
      status: 'online',
      unread: 1,
      lastMessage: 'Ok, vou verificar',
      lastTime: '09:45'
    },
    {
      id: 3,
      name: 'Pedro Santos',
      type: 'individual',
      status: 'offline',
      lastMessage: 'Transferi o atendimento',
      lastTime: '09:30'
    }
  ];

  const messages = [
    {
      id: 1,
      sender: 'Maria Silva',
      text: 'Olá, pode me ajudar com um caso?',
      time: '09:30',
      isMe: false
    },
    {
      id: 2,
      sender: 'Você',
      text: 'Claro! Qual é o caso?',
      time: '09:31',
      isMe: true
    },
    {
      id: 3,
      sender: 'Maria Silva',
      text: 'É sobre o cliente que está com problema no acesso',
      time: '09:32',
      isMe: false
    }
  ];

  const filteredContacts = contacts.filter(contact => 
    contact.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <ChatInternalContainer>
      <ChatHeader>
        {selectedContact ? (
          <>
            <Button 
              type="text" 
              icon={<ArrowLeftOutlined />} 
              onClick={() => setSelectedContact(null)}
            />
            <Avatar 
              icon={selectedContact.type === 'group' ? <TeamOutlined /> : <UserOutlined />}
              style={{ marginRight: 8 }}
            />
            <div className="contact-info">
              <h3>{selectedContact.name}</h3>
              {selectedContact.type === 'group' ? (
                <span>{selectedContact.members.length} membros</span>
              ) : (
                <span>{selectedContact.status === 'online' ? 'Online' : 'Offline'}</span>
              )}
            </div>
          </>
        ) : (
          <h3>Chat Interno</h3>
        )}
      </ChatHeader>

      {!selectedContact ? (
        <>
          <div className="search-container">
            <Input
              prefix={<SearchOutlined />}
              placeholder="Buscar contato ou grupo..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <ContactList>
            {filteredContacts.map(contact => (
              <ContactItem 
                key={contact.id}
                onClick={() => setSelectedContact(contact)}
              >
                <Avatar 
                  icon={contact.type === 'group' ? <TeamOutlined /> : <UserOutlined />}
                  style={{ 
                    backgroundColor: contact.type === 'group' ? '#1890ff' : '#52c41a'
                  }}
                />
                <div className="contact-info">
                  <div className="contact-header">
                    <h4>{contact.name}</h4>
                    <span className="time">{contact.lastTime}</span>
                  </div>
                  <div className="contact-preview">
                    <p>{contact.lastMessage}</p>
                    {contact.unread > 0 && (
                      <Badge count={contact.unread} />
                    )}
                  </div>
                  {contact.type === 'group' && (
                    <div className="group-members">
                      {contact.members.join(', ')}
                    </div>
                  )}
                </div>
              </ContactItem>
            ))}
          </ContactList>
        </>
      ) : (
        <>
          <Messages>
            {messages.map(message => (
              <Message
                key={message.id}
                className={message.isMe ? 'sent' : 'received'}
              >
                <div className="message-content">
                  {!message.isMe && (
                    <span className="sender">{message.sender}</span>
                  )}
                  <p>{message.text}</p>
                  <span className="time">{message.time}</span>
                </div>
              </Message>
            ))}
          </Messages>
          <MessageInput>
            <Input
              placeholder="Digite sua mensagem..."
            />
            <Button type="primary" icon={<SendOutlined />} />
          </MessageInput>
        </>
      )}
    </ChatInternalContainer>
  );
} 