import { Input } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  justify-content: space-evenly;
  align-items: center;
`;
export const Coluna = styled.div`
   height : 100vh;
   display: flex;
   /* flex: 1; */
   justify-content: center;
   align-items: center;
   text-align: center;  
`;

export const ColunaLogo = styled(Coluna)`
 background-color: #0b3b61;
 width: 40%;
 @media(max-width: 800px) {
      height : 0%;
      width: 0%;
      display: none;
   }
`;

export const ColunaLogin = styled(Coluna)`
  align-items: center;
  flex: 1;
  width: 100%;
  background: linear-gradient(227.1deg,  #3C4473 5.17%, #0b3b61 96.03%);
  @media(max-width: 800px) {
      height : 100vh;
      width: 100%;
   }
`;

export const AreaLogo = styled.div`
margin-top:  -10px;
width : 397px;
height : 500px;
text-align : center;
`;

export const ContainerLogin = styled.div`
  
  width : 60%;
  max-width: 500px;
  height : 400px;
  border-radius: 15px;
`;

export const FormLoginBody = styled.div`
   display: flex;
   flex : 1;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;

   & form{
      width: 90%;
      & div {
         width: 100%;
         text-align: center;
         margin: 0 auto;
         padding: 0;
      }
      & label {
         font-size: 1.3rem;
         font-weight: 400;
         text-align: left;
         margin-bottom:  5px;
         margin-left: 5px;
         color: var(--text-tittle);
      }
      & input {
         width: 100%;
         padding: 10px;
         margin-bottom:  0;
         border : none;
         font-weight: 500;
         margin-top: 2px;
         font-size: 1.2rem;
         color: #464646;
         border-radius: 8px;
         text-transform: uppercase;
         &:focus{
            outline: .1rem solid #9c9c9c;
         }     
      }

      & button {
         width: 100%;
         padding: 12px 8px;
         background-color: var(--azul);
         color: #FFF;
         border:  none;
         font-size: 1.2rem;
         font-weight: 600;
         border-radius:  8px;
         transition: filter 0.2s;
         margin-bottom:  10px;
         &:hover{
            filter: brightness(1.2); 
         }
         &:active{
            filter: contrast(1.2);
         }
      }
   }
`;

export const FormLogin = styled.form`
  width: 100%;
  padding :  15px; 
`;

export const Conteudo = styled.div`
   
  display: flex;
  align-items: center;
   justify-content: space-between;
  background: rgba(255,255,255,0.2);
  box-shadow: rgba(0, 0, 0, 0.6) 1px 1px 6px; 
  margin-top:  5px;
  border-radius: 20px;
   & div.class-qrcode{
      margin-right: 5px;
   } 
 
  @media(max-width: 800px) {
     flex-direction: column; 
     
     & div.class-qrcode{
      display: none !important;
     }
    }
`;

export const Password = styled(Input.Password)`
    border-radius: 8px;
    margin-bottom:  15px;
    
         & #Senha {
         border : none;
         margin: 0;
         font-weight: 500;
         font-size: 1.4rem;
         color: #464646;         
         text-transform:none;
         &:focus{
            outline-style: none;
         }     
      }

`



