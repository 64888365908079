import { Container } from "./style";
import {MenuOutlined} from '@ant-design/icons';
import { Badge, Button, Tag } from "antd";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined
  } from '@ant-design/icons';

export function MenuTopo({toggleCollapsed, collapsed }){

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0"); // Obtém o dia e garante 2 dígitos
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Obtém o mês e garante 2 dígitos (mês começa em 0)
    const year = today.getFullYear(); // Obtém o ano

    const secondDigitDay = parseInt(day[1], 10);
    const secondDigitMonth = parseInt(month[1], 10);

    // Soma os dois dígitos
    const sumOfDigits = secondDigitDay + secondDigitMonth;

    // Inverte o dia e o mês
    const reversedDay = day.split("").reverse().join("");
    const reversedMonth = month.split("").reverse().join("");

    // Concatena os resultados
    const result = sumOfDigits + reversedMonth + reversedDay;
    //console.log(result);
    return result;
  }

    return (
        <Container>   

            <Button
              type="primary"
              onClick={toggleCollapsed}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
            <div>
              <Tag color="processing" style={{fontSize: '16px'}}>Senha do dia:  {getCurrentDate()}</Tag>
            </div>
        </Container> 
    )
}