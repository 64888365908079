import axios from 'axios';

let url ; 
if (process.env.REACT_APP_SERVER_STATUS  === 'dev') { 
  url = 'http://127.0.0.1:8000/api/' 
}else{
  url = 'https://suporte.mcnsistemas.net.br/api'   
}

export const api = axios.create({
    baseURL : url, 
    headers : {
         'Content-Type' : 'application/json',
         'Accept': 'application/json', 
         'Access-Control-Allow-Origin' : '*',  
         'preflightContinue': false,
        },
    timeout : 60000,
    httpsAgent : false, 
    timeoutErrorMessage: 'Timeout error'
});
