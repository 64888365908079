
import { Container, Imagem, Titulo } from "./style";
import { Col, Row } from "antd";
import Lottie from "lottie-react"
import develop from "../../Assets/develop.json"

export function Home(){
  return (
    <Container> 
    <Row 
    style={{width:'100%', maxWidth:'600px' ,
     background: 'rgba(145,195,250,1)', 
     boxShadow: 'rgba(0, 0, 0, 0.45) 0px 1px 4px' , 
     borderRadius: '20px' ,color: 'white',
     marginTop : '50px'
     }}>  
      <Col span={24} 
       style={{
           display: 'flex',
           alignContent: 'center', 
           justifyContent: 'center'
       }}>
          <Titulo>Em Desenvolvimento</Titulo>
      </Col>
      <Col span={24}>
          <Lottie  animationData={develop} loop={true} autoPlay={true}/> 
      </Col>
   </Row>      
</Container>
  );
};