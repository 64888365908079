import styled  from "styled-components";



export const Container = styled.div`
    display: flex;

    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0 15px;
`;