import { api } from "../api"

export const useBackup = async() => ({ 
    Consulta :  async (dados , token)=>{
        const response = await api.post('/backup/consulta', 
        {        
                ...dados
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     DetalhesConsulta :  async (id , token)=>{
        const response = await api.post('/backup/detalhes', 
        {id},
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     delete :  async (id , token)=>{
        const response = await api.post('/contador/delete', 
        {id},
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     ConsultaClientesSelec :  async (token)=>{
        const response = await api.post('/clientes/listarSelect', 
        {  },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },


  })