import { useState } from 'react';
import { 
  Card, 
  Row, 
  Col, 
  Button, 
  Modal, 
  Form, 
  Input, 
  Select, 
  DatePicker,
  Typography,
  Tag,
  Space,
  Tabs,
  Dropdown,
  Menu,
  Empty,
  Switch,
  Radio
} from 'antd';
import { 
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ShareAltOutlined,
  MoreOutlined,
  FileTextOutlined,
  UnorderedListOutlined,
  InboxOutlined,
  TeamOutlined,
  ClockCircleOutlined,
  UserOutlined,
  FilterOutlined,
  SearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

export function Anotacoes() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [tipoAnotacao, setTipoAnotacao] = useState('texto'); // texto ou lista
  const [filtros, setFiltros] = useState({
    categoria: 'todas',
    periodo: null,
    busca: ''
  });

  // Dados fictícios
  const [anotacoes, setAnotacoes] = useState([
    {
      id: 1,
      assunto: 'Reunião de Planejamento',
      conteudo: 'Discutir metas do próximo trimestre...',
      tipo: 'texto',
      categoria: 'ativa',
      dataCriacao: '2024-03-18',
      criador: 'João Silva',
      compartilhada: true,
      itens: []
    },
    {
      id: 2,
      assunto: 'Checklist Projeto X',
      conteudo: '',
      tipo: 'lista',
      categoria: 'ativa',
      dataCriacao: '2024-03-17',
      criador: 'Maria Santos',
      compartilhada: false,
      itens: [
        { id: 1, texto: 'Definir escopo', concluido: true },
        { id: 2, texto: 'Criar cronograma', concluido: false }
      ]
    }
  ]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setTipoAnotacao('texto');
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      console.log('Valores do form:', values);
      // Aqui vai a lógica de salvamento
      handleCancel();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Filtra anotações baseado nos critérios
  const getAnotacoesFiltradas = () => {
    return anotacoes.filter(anotacao => {
      const matchCategoria = filtros.categoria === 'todas' || anotacao.categoria === filtros.categoria;
      const matchPeriodo = !filtros.periodo || 
        moment(anotacao.dataCriacao).isBetween(filtros.periodo[0], filtros.periodo[1], 'day', '[]');
      const matchBusca = !filtros.busca || 
        anotacao.assunto.toLowerCase().includes(filtros.busca.toLowerCase()) ||
        anotacao.conteudo.toLowerCase().includes(filtros.busca.toLowerCase());
      
      return matchCategoria && matchPeriodo && matchBusca;
    });
  };

  const menuAnotacao = (anotacao) => (
    <Menu>
      <Menu.Item key="editar" icon={<EditOutlined />}>
        Editar
      </Menu.Item>
      <Menu.Item key="compartilhar" icon={<ShareAltOutlined />}>
        {anotacao.compartilhada ? 'Tornar Privada' : 'Compartilhar'}
      </Menu.Item>
      <Menu.Item key="arquivar" icon={<InboxOutlined />}>
        {anotacao.categoria === 'arquivada' ? 'Desarquivar' : 'Arquivar'}
      </Menu.Item>
      <Menu.Item key="excluir" icon={<DeleteOutlined />} danger>
        Excluir
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ 
      background: '#f0f2f5',
      minHeight: '100vh',
      padding: '24px'
    }}>
      <div style={{ 
        maxWidth: '1200px',
        margin: '0 auto'
      }}>
        {/* Cabeçalho */}
        <Row justify="space-between" align="middle" style={{ marginBottom: '24px' }}>
          <Col>
            <Title level={2}>
              <FileTextOutlined style={{ marginRight: '8px' }} />
              Minhas Anotações
            </Title>
          </Col>
          <Col>
            <Button 
              type="primary" 
              icon={<PlusOutlined />}
              onClick={showModal}
              size="large"
            >
              Nova Anotação
            </Button>
          </Col>
        </Row>

        {/* Filtros */}
        <Card style={{ marginBottom: '24px' }}>
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={8}>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Buscar anotações..."
                onChange={(e) => setFiltros({ ...filtros, busca: e.target.value })}
                allowClear
              />
            </Col>
            <Col xs={24} sm={8}>
              <RangePicker
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                locale={locale}
                onChange={(dates) => setFiltros({ ...filtros, periodo: dates })}
                placeholder={['Data início', 'Data fim']}
              />
            </Col>
            <Col xs={24} sm={8}>
              <Radio.Group
                value={filtros.categoria}
                onChange={(e) => setFiltros({ ...filtros, categoria: e.target.value })}
                buttonStyle="solid"
              >
                <Radio.Button value="todas">Todas</Radio.Button>
                <Radio.Button value="ativa">Ativas</Radio.Button>
                <Radio.Button value="arquivada">Arquivadas</Radio.Button>
                <Radio.Button value="compartilhada">Compartilhadas</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Card>

        {/* Lista de Anotações */}
        <Row gutter={[16, 16]}>
          {getAnotacoesFiltradas().map(anotacao => (
            <Col xs={24} sm={12} lg={8} key={anotacao.id}>
              <Card
                title={
                  <div style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between' 
                  }}>
                    <Text strong>{anotacao.assunto}</Text>
                    <Dropdown overlay={menuAnotacao(anotacao)} trigger={['click']}>
                      <Button type="text" icon={<MoreOutlined />} />
                    </Dropdown>
                  </div>
                }
                extra={
                  anotacao.tipo === 'texto' ? 
                    <FileTextOutlined /> : 
                    <UnorderedListOutlined />
                }
                style={{ 
                  height: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
              >
                {anotacao.tipo === 'texto' ? (
                  <Paragraph ellipsis={{ rows: 3 }}>
                    {anotacao.conteudo}
                  </Paragraph>
                ) : (
                  <ul style={{ paddingLeft: '20px' }}>
                    {anotacao.itens.map(item => (
                      <li key={item.id} style={{ 
                        textDecoration: item.concluido ? 'line-through' : 'none',
                        color: item.concluido ? '#999' : 'inherit'
                      }}>
                        {item.texto}
                      </li>
                    ))}
                  </ul>
                )}
                <div style={{ marginTop: '16px' }}>
                  <Space size={[0, 8]} wrap>
                    <Tag icon={<ClockCircleOutlined />}>
                      {moment(anotacao.dataCriacao).format('DD/MM/YYYY')}
                    </Tag>
                    {anotacao.compartilhada && (
                      <Tag color="blue" icon={<TeamOutlined />}>
                        Compartilhada
                      </Tag>
                    )}
                    <Tag color="cyan" icon={<UserOutlined />}>
                      {anotacao.criador}
                    </Tag>
                  </Space>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Modal de Cadastro/Edição */}
        <Modal
          title={
            <Space>
              <FileTextOutlined />
              <span>Nova Anotação</span>
            </Space>
          }
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          width={700}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="assunto"
              label="Assunto"
              rules={[{ required: true, message: 'Informe o assunto' }]}
            >
              <Input placeholder="Digite o assunto da anotação" />
            </Form.Item>

            <Form.Item label="Tipo de Anotação">
              <Radio.Group 
                value={tipoAnotacao} 
                onChange={(e) => setTipoAnotacao(e.target.value)}
                buttonStyle="solid"
              >
                <Radio.Button value="texto">Texto</Radio.Button>
                <Radio.Button value="lista">Lista</Radio.Button>
              </Radio.Group>
            </Form.Item>

            {tipoAnotacao === 'texto' ? (
              <Form.Item
                name="conteudo"
                label="Conteúdo"
                rules={[{ required: true, message: 'Digite o conteúdo' }]}
              >
                <TextArea 
                  rows={6} 
                  placeholder="Digite sua anotação..."
                  showCount
                  maxLength={1000}
                />
              </Form.Item>
            ) : (
              <Form.List name="itens">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item required={false} key={field.key}>
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Digite o item ou remova-o",
                            },
                          ]}
                          noStyle
                        >
                          <Input 
                            placeholder="Digite um item da lista"
                            style={{ width: '90%' }}
                          />
                        </Form.Item>
                        <DeleteOutlined
                          onClick={() => remove(field.name)}
                          style={{ marginLeft: '8px' }}
                        />
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        block
                      >
                        Adicionar Item
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}

            <Form.Item
              name="compartilhada"
              valuePropName="checked"
            >
              <Switch 
                checkedChildren={<ShareAltOutlined />}
                unCheckedChildren={<ShareAltOutlined />}
              /> Compartilhar com todos
            </Form.Item>

            <Form.Item style={{ marginTop: '24px', textAlign: 'right' }}>
              <Button onClick={handleCancel} style={{ marginRight: '8px' }}>
                Cancelar
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}