import { api } from "../api"

export const useClientes = async() => ({ 
    ConsultaClientes :  async (filtro , token)=>{
        const response = await api.post('/Clientes/', 
        {        
                filtro : filtro
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },

     ConsultaContatosClientes :  async (id , token)=>{
        const response = await api.post('/Clientes/contato', 
        {        
                id
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     ConsultaClientesSelec :  async (token)=>{
        const response = await api.post('/clientes/listarSelect', 
        {  },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },


  })