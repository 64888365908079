import { api } from "../api"

export const useContatos = async() => ({ 
    Consulta :  async (filtro , token)=>{
        const response = await api.post('/contato/id', 
        {        
                id : filtro
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     ConsultaContatos :  async (filtro , token)=>{
        const response = await api.post('/contato/listar', 
        {        
                id : filtro
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     Salvar :  async (dados ,id, token)=>{
        const response = await api.post('/contato/Salvar', 
        {...dados,id},
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     delete :  async (id , token)=>{
        const response = await api.post('/contato/deletar', 
        {id},
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     ConsultaClientesSelec :  async (token)=>{
        const response = await api.post('/clientes/listarSelect', 
        {  },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },

     CadastrarContato :  async (dados , token)=>{
        const response = await api.post('/contato/cadastrar', 
        {...dados},
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },


  })