import { useState, useEffect, useCallback } from 'react';
import { 
  Table, 
  Button, 
  Modal, 
  Form, 
  DatePicker, 
  Select, 
  Row, 
  Col, 
  Tag, 
  Tabs,
  Card,
  Statistic
} from 'antd';
import { 
  CalendarOutlined, 
  UserOutlined, 
  CodeOutlined,
  CustomerServiceOutlined,
  PlusOutlined,
  TeamOutlined,
  SearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

export function Escala() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [escalas, setEscalas] = useState([
    {
      id: 1,
      data: '2024-03-18',
      suporte: [
        { id: 1, nome: 'João Silva', tipo: 'suporte' },
        { id: 2, nome: 'Maria Santos', tipo: 'suporte' },
        { id: 3, nome: 'Pedro Costa', tipo: 'suporte' }
      ],
      desenvolvimento: [
        { id: 4, nome: 'Ana Oliveira', tipo: 'desenvolvimento' },
        { id: 5, nome: 'Carlos Souza', tipo: 'desenvolvimento' }
      ]
    },
    {
      id: 2,
      data: '2024-03-19',
      suporte: [
        { id: 6, nome: 'Paula Lima', tipo: 'suporte' },
        { id: 7, nome: 'Roberto Alves', tipo: 'suporte' }
      ],
      desenvolvimento: [
        { id: 8, nome: 'Marcos Paulo', tipo: 'desenvolvimento' },
        { id: 9, nome: 'Julia Costa', tipo: 'desenvolvimento' },
        { id: 10, nome: 'Fernando Silva', tipo: 'desenvolvimento' }
      ]
    }
    // ... mais dados fictícios
  ]);

  const [funcionarios, setFuncionarios] = useState({
    suporte: [
      { id: 1, nome: 'João Silva' },
      { id: 2, nome: 'Maria Santos' },
      { id: 3, nome: 'Pedro Costa' },
      { id: 6, nome: 'Paula Lima' },
      { id: 7, nome: 'Roberto Alves' }
    ],
    desenvolvimento: [
      { id: 4, nome: 'Ana Oliveira' },
      { id: 5, nome: 'Carlos Souza' },
      { id: 8, nome: 'Marcos Paulo' },
      { id: 9, nome: 'Julia Costa' },
      { id: 10, nome: 'Fernando Silva' }
    ]
  });

  // Estado para as datas
  const [dateRange, setDateRange] = useState([
    moment(),
    moment()
  ]);

  // Função para atualizar as datas
  const handleDateChange = useCallback((dates) => {
    if (dates) {
      setDateRange(dates);
      // Aqui você pode adicionar a lógica para filtrar os dados
      // Ex: filtrarEscalas(dates[0], dates[1]);
    } else {
      setDateRange([null, null]);
    }
  }, []);

  // Filtrar dados quando as datas mudarem
  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      // Lógica para filtrar os dados baseado nas datas
      const escalasFiltradas = escalas.filter(escala => 
        moment(escala.data).isBetween(dateRange[0], dateRange[1], 'day', '[]')
      );
      // Atualizar dados filtrados se necessário
    }
  }, [dateRange]);

  // Colunas da tabela principal
  const columns = [
    {
      title: "Data",
      dataIndex: "data",
      width: '15%',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.data).unix() - moment(b.data).unix(),
      render: (date) => (
        <span>
          <CalendarOutlined style={{ marginRight: 8 }} />
          {moment(date).format("DD/MM/YYYY")}
        </span>
      )
    },
    {
      title: "Suporte",
      dataIndex: "suporte",
      width: '35%',
      render: (funcionarios) => (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
          {funcionarios?.map(func => (
            <Tag 
              color="blue" 
              icon={<CustomerServiceOutlined />}
              style={{ padding: '4px 8px', margin: 0 }}
            >
              {func.nome}
            </Tag>
          ))}
        </div>
      )
    },
    {
      title: "Desenvolvimento",
      dataIndex: "desenvolvimento",
      width: '35%',
      render: (funcionarios) => (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
          {funcionarios?.map(func => (
            <Tag 
              color="green" 
              icon={<CodeOutlined />}
              style={{ padding: '4px 8px', margin: 0 }}
            >
              {func.nome}
            </Tag>
          ))}
        </div>
      )
    },
    {
      title: "Ações",
      width: '15%',
      render: (_, record) => (
        <Button 
          type="primary"
          onClick={() => handleEdit(record)}
          style={{ borderRadius: '6px' }}
        >
          Editar
        </Button>
      )
    }
  ];

  // Modal de Cadastro/Edição
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleEdit = (record) => {
    form.setFieldsValue({
      data: moment(record.data),
      suporte: record.suporte?.map(f => f.id),
      desenvolvimento: record.desenvolvimento?.map(f => f.id)
    });
    setIsModalOpen(true);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // Lógica de salvamento
      console.log(values);
      handleCancel();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ 
      background: '#f0f2f5',
      minHeight: '100vh',
      padding: '24px'
    }}>
      <div style={{ 
        maxWidth: '1200px',
        margin: '0 auto'
      }}>
        {/* Cabeçalho com Cards */}
        <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
          <Col xs={24} sm={24} md={8}>
            <Card hoverable>
              <Statistic
                title={<span style={{ fontSize: '16px' }}>Escala Hoje</span>}
                value={escalas.filter(e => moment(e.data).isSame(moment(), 'day')).length > 0 ? 'Configurada' : 'Não Configurada'}
                valueStyle={{ color: escalas.filter(e => moment(e.data).isSame(moment(), 'day')).length > 0 ? '#52c41a' : '#ff4d4f' }}
                prefix={<CalendarOutlined />}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card hoverable>
              <Statistic
                title={<span style={{ fontSize: '16px' }}>Equipe Suporte</span>}
                value={funcionarios.suporte.length}
                prefix={<CustomerServiceOutlined style={{ color: '#1890ff' }} />}
                valueStyle={{ color: '#1890ff' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card hoverable>
              <Statistic
                title={<span style={{ fontSize: '16px' }}>Equipe Desenvolvimento</span>}
                value={funcionarios.desenvolvimento.length}
                prefix={<CodeOutlined style={{ color: '#52c41a' }} />}
                valueStyle={{ color: '#52c41a' }}
              />
            </Card>
          </Col>
        </Row>

        {/* Filtros e Ações */}
        <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
          <Col xs={24} md={16}>
            <Card size="small">
              <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                <DatePicker.RangePicker
                  value={dateRange}
                  onChange={handleDateChange}
                  format="DD/MM/YYYY"
                  style={{ width: '100%' }}
                  placeholder={['Data Início', 'Data Fim']}
                  allowClear
                  locale={locale}
                //   ranges={{
                //     'Hoje': [moment(), moment()],
                //     'Próximos 7 dias': [moment(), moment().add(7, 'days')],
                //     'Próximos 15 dias': [moment(), moment().add(15, 'days')],
                //     'Próximo mês': [moment(), moment().add(1, 'month')]
                //   }}
                //   disabledDate={(current) => {
                //     return current && current < moment().startOf('day');
                //   }}
                />
                <Button 
                  type="primary" 
                  icon={<SearchOutlined />}
                  onClick={() => {
                    // Lógica de busca aqui
                    if (dateRange[0] && dateRange[1]) {
                      // filtrarEscalas(dateRange[0], dateRange[1]);
                    }
                  }}
                >
                  Buscar
                </Button>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              type="primary" 
              icon={<PlusOutlined />}
              onClick={showModal}
              size="large"
              style={{ width: '100%' }}
            >
              Nova Escala
            </Button>
          </Col>
        </Row>

        {/* Tabela de Escalas */}
        <Table
          columns={[
            {
              title: "Data",
              dataIndex: "data",
              width: '15%',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => moment(a.data).unix() - moment(b.data).unix(),
              render: (date) => (
                <Tag 
                  color={moment(date).isSame(moment(), 'day') ? 'green' : 'default'}
                  style={{ padding: '4px 8px' }}
                >
                  <CalendarOutlined style={{ marginRight: 8 }} />
                  {moment(date).format("DD/MM/YYYY")}
                  {moment(date).isSame(moment(), 'day') && " (Hoje)"}
                </Tag>
              )
            },
            {
              title: "Suporte",
              dataIndex: "suporte",
              width: '35%',
              render: (funcionarios) => (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                  {funcionarios?.map(func => (
                    <Tag 
                      color="blue" 
                      icon={<CustomerServiceOutlined />}
                      style={{ padding: '4px 8px', margin: 0 }}
                    >
                      {func.nome}
                    </Tag>
                  ))}
                </div>
              )
            },
            {
              title: "Desenvolvimento",
              dataIndex: "desenvolvimento",
              width: '35%',
              render: (funcionarios) => (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                  {funcionarios?.map(func => (
                    <Tag 
                      color="green" 
                      icon={<CodeOutlined />}
                      style={{ padding: '4px 8px', margin: 0 }}
                    >
                      {func.nome}
                    </Tag>
                  ))}
                </div>
              )
            },
            {
              title: "Ações",
              width: '15%',
              render: (_, record) => (
                <Button 
                  type="primary"
                  onClick={() => handleEdit(record)}
                  style={{ borderRadius: '6px' }}
                >
                  Editar
                </Button>
              )
            }
          ]}
          dataSource={escalas}
          loading={loading}
          pagination={{
            pageSize: 7,
            showTotal: (total) => `Total: ${total} dias`,
            showSizeChanger: false,
            showQuickJumper: true
          }}
          style={{ 
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
          }}
        />

        {/* Modal de Cadastro/Edição */}
        <Modal
          title={
            <div style={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: '12px'
            }}>
              <TeamOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
              <span>Cadastro de Escala</span>
            </div>
          }
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          width={800}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="data"
              label="Data"
              rules={[{ required: true, message: 'Selecione a data' }]}
            >
              <DatePicker 
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                placeholder="Selecione a data"
              />
            </Form.Item>

            <Tabs
              items={[
                {
                  key: 'suporte',
                  label: (
                    <span>
                      <CustomerServiceOutlined /> Suporte
                    </span>
                  ),
                  children: (
                    <Form.Item
                      name="suporte"
                      label="Funcionários do Suporte"
                    >
                      <Select
                        mode="multiple"
                        placeholder="Selecione os funcionários"
                        style={{ width: '100%' }}
                        options={funcionarios.suporte.map(f => ({
                          label: f.nome,
                          value: f.id
                        }))}
                      />
                    </Form.Item>
                  ),
                },
                {
                  key: 'desenvolvimento',
                  label: (
                    <span>
                      <CodeOutlined /> Desenvolvimento
                    </span>
                  ),
                  children: (
                    <Form.Item
                      name="desenvolvimento"
                      label="Funcionários do Desenvolvimento"
                    >
                      <Select
                        mode="multiple"
                        placeholder="Selecione os funcionários"
                        style={{ width: '100%' }}
                        options={funcionarios.desenvolvimento.map(f => ({
                          label: f.nome,
                          value: f.id
                        }))}
                      />
                    </Form.Item>
                  ),
                },
              ]}
            />

            <Form.Item style={{ marginTop: '24px', textAlign: 'right' }}>
              <Button onClick={handleCancel} style={{ marginRight: '8px' }}>
                Cancelar
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}