import { api } from "../api"

export const useContadores = async() => ({ 
    ConsultaContadores :  async (filtro , token)=>{
        const response = await api.post('/contador/', 
        {        
                filtro : filtro
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     Salvar :  async (dados , token)=>{
        const response = await api.post('/contador/Salvar', 
        dados,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     delete :  async (id , token)=>{
        const response = await api.post('/contador/delete', 
        {id},
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     ConsultaClientesSelec :  async (token)=>{
        const response = await api.post('/clientes/listarSelect', 
        {  },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },

     addCliente :  async (contador , cliente ,token)=>{
        const response = await api.post('/contador/addCliente', 
        {  
            contador,
            cliente  
        },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },

     consultaClientesContador :  async (contador ,token)=>{
        const response = await api.post('/contador/consultaClientesContador', 
        {  
            contador,              
        },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },
     deleteClientesContador :  async (contador , cliente ,token)=>{
        const response = await api.post('/contador/deleteClientesContador', 
        {  
            contador,  
            cliente            
        },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },
     AtualizaStatusContador: async (dados ,token)=>{
        const response = await api.put('/contador/status', 
            dados,
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },

  })