import { DownOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Dropdown, Row, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Search from "antd/es/transfer/search";
import { Container } from "./style";
const items = [
  {
    key: "1",
    label: "Action 1",
  },
  {
    key: "2",
    label: "Action 2",
  },
];

export function ChamadosProgramacao() {
  const expandedRowRender = () => {
    const columns = [
      {
        title: "Sequecia",
        dataIndex: "ID_MOVIMENTO",
        key: "ID_MOVIMENTO",
      },
      {
        title: "Colaborador",
        dataIndex: "COD_COLABORADOR_SOLICITADO",
        key: "COD_COLABORADOR_SOLICITADO",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "state",
      },
      {
        title: "Data Movimentação",
        dataIndex: "dtMovimentacao",
        key: "dtMovimentacao",
      },
      {
        title: "Action",
        dataIndex: "operation",
        key: "operation",
        render: () => (
          <Space size="middle">
            <Button type="primary" size="small">
              Detalhes
            </Button>
            <Button type="ghost" size="small">
              Editar
            </Button>
          </Space>
        ),
      },
    ];
    const data = [
      {
        ID_MOVIMENTO: 1,
        COD_COLABORADOR_SOLICITADO: "NILVA",
        status: "ABERTURA CHAMADO",
        dtMovimentacao: "06-03-2023 12:35",
      },
      {
        ID_MOVIMENTO: 2,
        COD_COLABORADOR_SOLICITADO: "UDENILSON",
        status: "ENVIADO PARA ANALISTA ESPECIALIZADO",
        dtMovimentacao: "06-03-2023 12:38",
      },
      {
        ID_MOVIMENTO: 2,
        COD_COLABORADOR_SOLICITADO: "UDENILSON",
        status: "EM ANALISE PELO ESPECIALISTA",
        dtMovimentacao: "06-03-2023 14:35",
      },
      {
        ID_MOVIMENTO: 3,
        COD_COLABORADOR_SOLICITADO: "ATIMILSON",
        status: "ENVIADO PARA PROGRAMACAO",
        dtMovimentacao: "06-03-2023 14:38",
      },
      {
        ID_MOVIMENTO: 4,
        COD_COLABORADOR_SOLICITADO: "ATIMILSON",
        status: "EM ANALISE PELA PROGRAMACAO",
        dtMovimentacao: "06-03-2023 14:44",
      },
    ];

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };
  const columns = [
    {
      title: "Chamado",
      dataIndex: "chamado",
      key: "chamado",
    },
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
    },
    {
      title: "Projeto",
      dataIndex: "projeto",
      key: "projeto",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Tipo",
      dataIndex: "tipoChamado",
      key: "tipoChamado",
    },
    {
      title: "Date Abertura",
      dataIndex: "dataAbertura",
      key: "dataAbertura",
    },
    {
      title: "",
      key: "",
      render: () => (
        <Button type="primary" size="small">
          Movimentar
        </Button>
      ),
    },
  ];
  const data = [
    {
      chamado: 1520,
      cliente: "526",
      projeto: "ERP MCN",
      status: "8-EM DESENVOLMENTO",
      tipoChamado: "2-FALHAS NORMAIS",
      dataAbertura: "06-03-2023 10:12:00",
    },
  ];

  return (
    <>
      <Container>
        <Row
          style={{ width: "100%", justifyContent: "center" }}
          gutter={[0, 10]}
        >
          <Col xs={24} sm={24} md={24} lg={10}>
            <label for="cliente">Cliente:</label>
            <Search id="cliente" placeholder="cliente" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={10}>
            <label for="colaborador">Colaborador:</label>
            <Search id="colaborador" placeholder="Colaborador" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={10}>
            <label for="nrchamado">Numero Chamado:</label>
            <Search id="nrchamado" placeholder="chamado" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={10}>
            <label for="Setor">Setor:</label>
            <Search id="Setor" placeholder="Setor" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={10}>
            <label for="Status">Status:</label>
            <Search id="Status" placeholder="Status" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={4}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              style={{ marginTop: "18px" }}
              onClick={() => console.log("")}
            >
              Pesquisar
            </Button>
          </Col>
        </Row>
      </Container>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
        }}
        dataSource={data}
      />
    </>
  );
}
