
import { createContext, useState, ReactNode, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { toast, ToastOptions } from 'react-toastify';
import { api } from '../Services/api';

const init = {
    USUARIO: '',       
        PERFIL_DESC:'',
        TOKEN: '',
        LOGIN: false,
    PERFIL: '',
}


const UsuarioContext = createContext();

export function UsuarioProvider({ children }) {
    const [user, setUser] = useState(init);
    const [iniciarMenu, setInitMenu] = useState([]);
    const basic = 'TUNOOk1DTjE1OTE1OQ==';


    function loadStorage() {
        const storageUser = localStorage.getItem('SistemaUser');
        if (storageUser) {
            setUser({
                ...JSON.parse(storageUser)
            });
        }
    }

    useEffect(() => {        
        loadStorage();
    }, [ ]);


    async function createLogin(usuarioInput,senhaInput) {
        await api.post('/Login', {
            usuario: usuarioInput,
            senha: senhaInput
        }, {}).then(res => {
            console.log(res)
            if (res.status === 200) {
                const loginRetorno = ({                    
                    USUARIO: usuarioInput,
                    PERFIL: res.data.PERFIL,
                    PERFIL_DESC:res.data.DESC_USER,
                    TOKEN: res.data.TOKEN,
                    LOGIN: true,
                    DATALOGIN: new Date().getTime() + (60000 * 360),                    
                });
                setUser(loginRetorno);
                console.log(user)
                storageUser('SistemaUser', JSON.stringify(loginRetorno));

                toast.success('Login com Sucesso 🚀', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error('Falha no Login' + res.data,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
            }
        }).catch((e) => {
            const configError = {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
            switch (e.response.status) {               
                case 230: toast.error('Usuarios ou Senha Incorretos!!',
                    {
                        ...configError,
                        position: 'top-center',
                        theme: 'colored',
                    });
                    break;
                default:
                    toast.error('Falha no Login :' + e,
                        {
                            ...configError,
                            position: 'top-center',
                            theme: 'colored',
                        });
            }
        });
    }

    function storageUser(chave, data) {
        localStorage.setItem(chave, data);
    }

    function signOut() {
        localStorage.removeItem('SistemaUser');
        setUser(init);
        return <Navigate to="/" replace={true} />;
    }

    return (
        <UsuarioContext.Provider value={{ createLogin, user, signOut, iniciarMenu, loadStorage }} >
            {children}
        </UsuarioContext.Provider>
    )
}

export function useUsuario() {
    const context = useContext(UsuarioContext);
    return context;
}