import { createGlobalStyle } from 'styled-components';


export const GlobalStyle = createGlobalStyle`
  :root {
      --background: #f9f9f9;
      --background-header : rgba(145,195,250,1);
      --background-Menulateral : #FFF;
      --red: #e52e4d;
      --blue: #5429cc; 
      --azul: rgba(30,144,255,255);
      --green: #33cc95;
      
      --blue-light : #6933ff;
      --text-tittle: #363f5f;
      --text-body: #969cb3;
      --shape: #fff;
   } 

  *{
       padding: 0;
       margin: 0;
       box-sizing: border-box;
   }  
   html{
       @media (max-width: 1080px) {
           font-size: 93.75%;
       }
       @media (max-width: 720px) {
           font-size: 87.5%;
       }
   }

   body {
       background: var(--background);
       -webkit-font-smoothing: antialiased; 
   } 
   input:-webkit-autofill,
   input:-internal-autofill-selected{
             -webkit-text-fill-color: black !important;
             transition: background-color 5000s ease-in-out 0s;
   }
`;


