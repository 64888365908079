import { useState } from 'react';
import { 
  SearchOutlined, 
  MessageOutlined, 
  UserOutlined,
  PaperClipOutlined,
  SmileOutlined,
  SendOutlined,
  FilterOutlined,
  BellOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  HistoryOutlined,
  TeamOutlined,
  TagOutlined
} from '@ant-design/icons';
import { Input, Badge, Avatar, Button, Dropdown, Menu, Tooltip, Drawer, Tag, Select,Divider, Popover } from 'antd';
import { 
  Container, 
  Sidebar, 
  ChatList, 
  ChatItem,
  MainChat, 
  Header, 
  Messages,
  MessageInput,
  NoChat,
  ChatHeader,
  Message,
  InternalChatButton,
  TagsContainer,
  GlobalStyles,
  NotificationContainer
} from './styles';
import { ChatInterno } from '../../Components/WhatsApp/ChatInterno';

export function WhatsApp() {
  const [selectedChat, setSelectedChat] = useState(null);
  const [filter, setFilter] = useState('all');
  const [historyDrawerVisible, setHistoryDrawerVisible] = useState(false);
  const [internalChatVisible, setInternalChatVisible] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  // Tags disponíveis
  const availableTags = [
    { color: 'red', label: 'Urgente' },
    { color: 'green', label: 'Resolvido' },
    { color: 'blue', label: 'Em andamento' },
    { color: 'orange', label: 'Aguardando' },
    { color: 'purple', label: 'VIP' },
  ];

  const chats = [
    {
      id: 1,
      name: 'João Silva',
      lastMessage: 'Preciso de ajuda com o sistema',
      time: '10:30',
      unread: 3,
      status: 'pending',
      tags: ['Urgente', 'VIP'],
      messages: [
        {
          id: 1,
          text: 'Olá, bom dia!',
          time: '10:25',
          sender: 'client'
        },
        {
          id: 2,
          text: 'Preciso de ajuda com o sistema',
          time: '10:30',
          sender: 'client'
        }
      ]
    },
    {
        id: 1,
        name: 'Maria Oliveira',
        lastMessage: 'Pode me ajudar ?',
        time: '10:30',
        unread: 2,
        status: 'pending',
        tags: ['Aguardando'],
        messages: [
          {
            id: 1,
            text: 'Olá, bom dia!',
            time: '10:25',
            sender: 'client'
          },
          {
            id: 2,
            text: 'Pode me ajudar ?',
            time: '10:30',
            sender: 'client'
          }
        ]
      },
  ];

  // Histórico de atendimentos
  const history = [
    {
      id: 1,
      customer: 'João Silva',
      date: '2024-01-20',
      status: 'Resolvido',
      protocol: '2024012001',
      description: 'Problema com acesso ao sistema',
      attendant: 'Maria Atendente'
    },
    // ... mais históricos
  ];

  // Chat interno entre atendentes
  const internalChats = [
    {
      id: 1,
      name: 'Equipe Suporte',
      messages: [
        {
          id: 1,
          sender: 'Maria',
          text: 'Alguém pode me ajudar com o caso do cliente João?',
          time: '10:45'
        }
      ]
    }
  ];

  const filterMenu = (
    <Menu
      items={[
        {
          key: 'all',
          label: 'Todos os chats',
          icon: <MessageOutlined />
        },
        {
          key: 'pending',
          label: 'Pendentes',
          icon: <ClockCircleOutlined />
        },
        {
          key: 'resolved',
          label: 'Resolvidos',
          icon: <CheckCircleOutlined />
        }
      ]}
      onClick={({ key }) => setFilter(key)}
    />
  );

  const notifications = [
    {
      id: 1,
      type: 'message',
      title: 'Nova mensagem',
      description: 'João Silva enviou uma nova mensagem',
      time: '2 min atrás',
      read: false
    },
    {
      id: 2,
      type: 'transfer',
      title: 'Atendimento transferido',
      description: 'Maria transferiu um atendimento para você',
      time: '5 min atrás',
      read: false
    },
    {
      id: 3,
      type: 'alert',
      title: 'Cliente aguardando',
      description: 'Cliente aguardando há mais de 5 minutos',
      time: '10 min atrás',
      read: true
    },
    {
      id: 4,
      type: 'system',
      title: 'Atualização do sistema',
      description: 'Nova versão disponível do sistema',
      time: '1 hora atrás',
      read: true
    }
  ];

  const NotificationList = () => (
    <NotificationContainer>
      <div className="notification-header">
        <h4>Notificações</h4>
        <Button type="link" size="small">
          Marcar todas como lidas
        </Button>
      </div>
      <div className="notification-list">
        {notifications.map(notification => (
          <div 
            key={notification.id} 
            className={`notification-item ${!notification.read ? 'unread' : ''}`}
          >
            <div className="notification-icon">
              {notification.type === 'message' && <MessageOutlined style={{ color: '#1890ff' }} />}
              {notification.type === 'transfer' && <UserOutlined style={{ color: '#52c41a' }} />}
              {notification.type === 'alert' && <ClockCircleOutlined style={{ color: '#faad14' }} />}
              {notification.type === 'system' && <BellOutlined style={{ color: '#722ed1' }} />}
            </div>
            <div className="notification-content">
              <div className="notification-title">
                <span>{notification.title}</span>
                <small>{notification.time}</small>
              </div>
              <p>{notification.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="notification-footer">
        <Button type="link">
          Ver todas as notificações
        </Button>
      </div>
    </NotificationContainer>
  );

  return (
    <Container>
      <GlobalStyles />
      <Sidebar>
        <Header>
          <h2>Atendimentos</h2>
          <div className="header-actions">
            <Tooltip title="Histórico">
              <Button 
                type="text" 
                icon={<HistoryOutlined />} 
                onClick={() => setHistoryDrawerVisible(true)}
              />
            </Tooltip>
            <Tooltip title="Chat Interno">
              <Badge count={3}>
                <Button 
                  type="text" 
                  icon={<TeamOutlined />} 
                  onClick={() => setInternalChatVisible(true)}
                />
              </Badge>
            </Tooltip>
            <Popover 
              content={<NotificationList />}
              trigger="click"
              placement="bottomRight"
              overlayClassName="notifications-popover"
            >
              <Badge count={notifications.filter(n => !n.read).length}>
                <Button type="text" icon={<BellOutlined />} />
              </Badge>
            </Popover>
            <Dropdown overlay={filterMenu}>
              <Button type="text" icon={<FilterOutlined />} />
            </Dropdown>
          </div>
        </Header>

        <div className="search-container">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Buscar conversa..."
          />
        </div>

        <ChatList>
          {chats.map(chat => (
            <ChatItem
              key={chat.id}
              active={selectedChat?.id === chat.id}
              onClick={() => setSelectedChat(chat)}
            >
              <Avatar size={40} icon={<UserOutlined />} />
              <div className="chat-info">
                <div className="chat-header">
                  <h4>{chat.name}</h4>
                  <span className="time">{chat.time}</span>
                </div>
                <div className="chat-preview">
                  <p>{chat.lastMessage}</p>
                  {chat.unread > 0 && (
                    <Badge count={chat.unread} />
                  )}
                </div>
                {chat.tags && (
                  <TagsContainer>
                    {chat.tags.map(tag => {
                      const tagConfig = availableTags.find(t => t.label === tag);
                      return (
                        <Tag key={tag} color={tagConfig?.color}>
                          {tag}
                        </Tag>
                      );
                    })}
                  </TagsContainer>
                )}
              </div>
            </ChatItem>
          ))}
        </ChatList>
      </Sidebar>

      <MainChat>
        {selectedChat ? (
          <>
            <ChatHeader>
              <div className="user-info">
                <Avatar size={40} icon={<UserOutlined />} />
                <div>
                  <h3>{selectedChat.name}</h3>
                  <span>Online</span>
                  <Select
                    mode="multiple"
                    placeholder="Adicionar tags"
                    value={selectedTags}
                    onChange={setSelectedTags}
                    style={{ width: '200px', marginTop: '4px' }}
                    options={availableTags.map(tag => ({
                      label: <Tag color={tag.color}>{tag.label}</Tag>,
                      value: tag.label
                    }))}
                  />
                </div>
              </div>
              <div className="chat-actions">
                <Tooltip title="Transferir atendimento">
                  <Button type="text" icon={<UserOutlined />} />
                </Tooltip>
                <Tooltip title="Gerenciar tags">
                  <Button type="text" icon={<TagOutlined />} />
                </Tooltip>
                <Tooltip title="Finalizar atendimento">
                  <Button type="text" icon={<CheckCircleOutlined />} />
                </Tooltip>
              </div>
            </ChatHeader>

            <Messages>
              {selectedChat.messages.map(message => (
                <Message
                  key={message.id}
                  className={message.sender}
                >
                  <div className="message-content">
                    <p>{message.text}</p>
                    <span className="time">{message.time}</span>
                  </div>
                </Message>
              ))}
            </Messages>

            <MessageInput>
              <Button type="text" icon={<SmileOutlined />} />
              <Button type="text" icon={<PaperClipOutlined />} />
              <Input
                placeholder="Digite uma mensagem..."
              />
              <Button type="primary" icon={<SendOutlined />} />
            </MessageInput>
          </>
        ) : (
          <NoChat>
            <MessageOutlined style={{ fontSize: 48 }} />
            <h3>Selecione uma conversa</h3>
            <p>Escolha um chat para iniciar o atendimento</p>
          </NoChat>
        )}
      </MainChat>

      {/* Drawer do Histórico */}
      <Drawer
        title="Histórico de Atendimentos"
        placement="right"
        width={600}
        onClose={() => setHistoryDrawerVisible(false)}
        open={historyDrawerVisible}
      >
        {history.map(item => (
          <div key={item.id} className="history-item">
            <h4>Protocolo: {item.protocol}</h4>
            <p><strong>Cliente:</strong> {item.customer}</p>
            <p><strong>Data:</strong> {item.date}</p>
            <p><strong>Status:</strong> {item.status}</p>
            <p><strong>Atendente:</strong> {item.attendant}</p>
            <p><strong>Descrição:</strong> {item.description}</p>
            <Divider />
          </div>
        ))}
      </Drawer>

      {/* Drawer do Chat Interno */}
      <Drawer
        title="Chat Interno"
        placement="right"
        width={400}
        onClose={() => setInternalChatVisible(false)}
        open={internalChatVisible}
        bodyStyle={{ padding: 0 }}
      >
        <ChatInterno onClose={() => setInternalChatVisible(false)} />
      </Drawer>
    </Container>
  );
}