import {
  TextoLogo,
  AreaLogo,
  ColunaLogin,
  ColunaLogo,
  Container,
  ContainerLogin,
  Conteudo,
  FormLoginBody,
  Password,
  FormLogin,
} from "./style";
import imgLogo from "./../../Assets/logo.png";
import imgAnimation from "./../../Assets/contact.json";
import imgAnimationShe from "./../../Assets/support_she.json";
import { Navigate,Link } from 'react-router-dom';
import Lottie from "lottie-react";
import { useState } from "react";
import { useUsuario } from "../../Contexts/auth";
import { LoadSpin } from "../../Components/Loading";

export function Login() {
  const { createLogin, user } = useUsuario();
  const [contrato, setContrato] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loadingAuth, setLoading] = useState(false);
  const [animacao, setAnimacao]= useState(Math.floor(Math.random() * 10));
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    await createLogin(
      login.toUpperCase(),
      password
    );
    setLoading(false);
  }
  return (
    <Container>     
      <ColunaLogin>
      
        <ContainerLogin>
           <img src={imgLogo} width={'65%'} style={{zIndex:99999, marginTop: '-150px'}} />
           <h1 style={{color : 'white ' , fontSize : '3em'}}>Suporte</h1>
          <Conteudo >
         
            <FormLogin>
           
              <FormLoginBody>
              {
                        user.LOGIN && (
                           <Navigate to="/Dashboard" replace={true} />
                        )}
                
                <form onSubmit={handleSubmit}>                 

                  <label htmlFor="Login" style={{ color :"white", fontSize: '1.6rem'}}><b>Login</b></label>
                  <input
                    required
                    id="Login"
                    type="text"
                    placeholder="Usuario"
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                    style={{marginBottom: '40px'}} 
                  />

                  <label htmlFor="Senha" style={{ color :"white", fontSize: '1.6rem'}}><b>Senha</b></label>
                  <Password
                    required
                    id="Senha"
                    type="password"
                    placeholder="*******"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{marginBottom: '40px'}} 
                  />
                   {/* <LoadSpin  />  */}
                  <button type="submit" style={{ backgroundColor: '#0c9f17'}} onClick={e => handleSubmit(e)}>Acessar</button>
                </form>
              </FormLoginBody>
            </FormLogin>
          </Conteudo>
        </ContainerLogin>
      </ColunaLogin>
    </Container>
  );
}
