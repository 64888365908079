import { Menu } from "antd";
import styled from "styled-components";

export const ContainerMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 98%;
  height: 100%;
`;

export const EspacoLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  margin-bottom: 10px;
`;

export const EspacoUsuario = styled.div`
  width: 100%;
  height: 70px;
  margin-top: -50px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  box-shadow: 1px 4px 9px 5px rgba(0, 0, 0, 0.14);
  margin-bottom: 10px;
`;

export const EspacoMenu = styled.div`
  width: 100%;
  height: 75%;
  display: block;
  padding-bottom: 40px;
`;

export const MenuStyled = styled(Menu)`
  width: 98%;
  /* margin: 0 auto;
  padding: 0; */
  /* margin-top: 10px;
  padding: 8px; */
  background-color:rgba(255, 255, 255, 0.8);
  box-shadow: 1px 4px 9px 5px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  

  .ant-menu-title-content{
    font-size: 12px;
  }

  &.ant-menu .ant-menu-inline {
    padding-inline: 1px;
    margin-left: -7px; 
    &.ant-menu-inline .ant-menu-item{
      padding-inline: 1px; 
    }
  }

  &.ant-menu-item{
    padding-left: 0;
  } 

  &.ant-menu-submenu .ant-menu-submenu-inline {
    /* margin-left: -7px;  */
  }
`;

export const ContainerUsuario = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const LogoUsuario = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 3rem;
  min-height: 3rem;
  width: 3rem;
  margin-left: 3px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid #a8a8a8;
  margin-right: 3px;
`;

export const InfoUsuario = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AreaBotoes = styled.div`
  display: flex;
  margin-top: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const Btn = styled.a`
  text-decoration: none;
  font-size: 0.85rem;
  text-align: center;
  padding: 2px 4px;
  width: 50px;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #fff;
  transition: filter 0.1s;
  &:hover {
    filter: brightness(0.7);
    color: #fff;
  }
`;

export const BtnPerfil = styled(Btn)`
  background: #4f55e8;
`;

export const BtnSair = styled(Btn)`
  background: var(--text-tittle);
`;
