import { api } from "../api"

export const useRemoto = async() => ({ 
    ConsultaRemoto :  async (filtro , token)=>{
        const response = await api.post('/remoto/consulta', 
        {        
                filtro : filtro
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     Salvar :  async (dados , token)=>{
        const response = await api.post('/remoto/Salvar', 
        dados,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     delete :  async (id , token)=>{
        const response = await api.post('/remoto/delete', 
        {id},
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     ConsultaClientesSelec :  async (token)=>{
        const response = await api.post('/clientes/listarSelect', 
        {  },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },


  })