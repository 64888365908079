import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Divider,
  Card,
  Tooltip
} from "antd";
import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { mask, mensagem } from "../../Helpers/UsaGeral";
import { BtContato, BtDetalhe, BtExcluir, Container } from "./style";
import { PlusOutlined, SearchOutlined, DeleteOutlined, TeamOutlined, DesktopOutlined, CloudServerOutlined, CloseOutlined } from '@ant-design/icons';
import { useClientes } from "../../Services/Clientes/useClientes";
import { useUsuario } from "../../Contexts/auth";
import adicionarBtn from "./../../../src/Assets/add-button.png";
import TextArea from "antd/es/input/TextArea";
import { useRemoto } from "../../Services/remoto";

const data = [
  {
    ID_CLIENTE: "526",
    cod: 526,
    FANTASIA: "John Brown",
    NOME_COMERCIAL: "New York No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
  {
    ID_CLIENTE: "2",
    cod: 2,
    FANTASIA: "Jim Green",
    NOME_COMERCIAL: "London No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
  {
    ID_CLIENTE: "3",
    cod: 3,
    FANTASIA: "Joe Black",
    NOME_COMERCIAL: "Sidney No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
];

const dataInicialCliente = [
  {
    nomeComercial: "",
    fantasia: "",
    cnpj: "",
    cpf: "",
    inscricaoEstadual: "",
    statusCliente: "S",
    tipoCliente: "C",
    tipoEmpresa: "F",
  },
];

export function Remoto() {
  const [open, setOpen] = useState(false);
  const { user } = useUsuario();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCadastrar, setIsModalOpenCadastrar] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [dadosSelecionados, setDadosSelecionados] = useState();
  const [dataContatos, setDataContatos]= useState([]);
  const [dataAcesso, setDataAcesso]= useState([]);
  const [load, setLoad] = useState(false);
  const [loadItens, setLoadItens] = useState(false);
  

  const [filtro, setFiltro] = useState("");

  const [selectedAccessType, setSelectedAccessType] = useState(null);
  const [accessList, setAccessList] = useState([]);

  const accessTypes = [
    { key: 'teamviewer', label: 'TeamViewer', icon: <TeamOutlined /> },
    { key: 'anydesk', label: 'AnyDesk', icon: <DesktopOutlined /> },
    { key: 'ts', label: 'Terminal Server', icon: <CloudServerOutlined /> }
  ];

  const renderAccessFields = (type) => {
    switch(type) {
      case 'teamviewer':
        return (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="ID TeamViewer" 
                name={`teamviewer_${accessList.length}`}
                rules={[{ required: true, message: 'ID TeamViewer é obrigatório' }]}
              >
                <Input placeholder="Digite o ID" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Senha" 
                name={`senha_teamviewer_${accessList.length}`}
                rules={[{ required: true, message: 'Senha é obrigatória' }]}
              >
                <Input.Password placeholder="Digite a senha" />
              </Form.Item>
            </Col>
          </Row>
        );
      case 'anydesk':
        return (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="ID AnyDesk" 
                name={`anydesk_${accessList.length}`}
                rules={[{ required: true, message: 'ID AnyDesk é obrigatório' }]}
              >
                <Input placeholder="Digite o ID" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Senha" 
                name={`senha_anydesk_${accessList.length}`}
                rules={[{ required: true, message: 'Senha é obrigatória' }]}
              >
                <Input.Password placeholder="Digite a senha" />
              </Form.Item>
            </Col>
          </Row>
        );
      case 'ts':
        return (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item 
                  label="IP" 
                  name={`ip_ts_${accessList.length}`}
                  rules={[{ required: true, message: 'IP é obrigatório' }]}
                >
                  <Input placeholder="Digite o IP" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  label="Porta" 
                  name={`porta_ts_${accessList.length}`}
                  rules={[{ required: true, message: 'Porta é obrigatória' }]}
                >
                  <Input placeholder="Digite a porta" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item 
                  label="Usuário" 
                  name={`usuario_ts_${accessList.length}`}
                  rules={[{ required: true, message: 'Usuário é obrigatório' }]}
                >
                  <Input placeholder="Digite o usuário" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  label="Senha" 
                  name={`senha_ts_${accessList.length}`}
                  rules={[{ required: true, message: 'Senha é obrigatória' }]}
                >
                  <Input.Password placeholder="Digite a senha" />
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };

  const columns = [    
    {
      title: "ID",
      dataIndex: "ID_ACESSO_REMOTO",
      key: "ID_ACESSO_REMOTO",
      width: 80,
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Contrato",
      dataIndex: "COD_INTERNO_CLIENTE",
      key: "COD_INTERNO_CLIENTE",
      width: 100,
      render: (text) => <Tag color="cyan">{text}</Tag>,
    },
    {
      title: "Descrição",
      dataIndex: "DESCRICAO_ACESSO",
      key: "DESCRICAO_ACESSO",
      render: (text) => (
        <div style={{ 
          fontWeight: 500,
          color: '#1f1f1f'
        }}>
          {text}
        </div>
      )
    },
    {
      title: "Status",
      dataIndex: "STATUS_ACESSO",
      key: "STATUS_ACESSO",
      width: 120,
      render: (status) => (
        <Tag color={status === 'Ativo' ? 'success' : 'error'}>
          {status}
        </Tag>
      )
    },
    {
      title: "Acessos",
      key: "acessos",
      width: 200,
      render: (_, record) => (
        <Space>
          {record.TEAMVIEWER_ACESSO && (
            <Tooltip title="TeamViewer">
              <Tag icon={<TeamOutlined />} color="blue">TV</Tag>
            </Tooltip>
          )}
          {record.ANYDESK_ACESSO && (
            <Tooltip title="AnyDesk">
              <Tag icon={<DesktopOutlined />} color="volcano">AD</Tag>
            </Tooltip>
          )}
          {record.IP_TERMINAL_SERVER && (
            <Tooltip title="Terminal Server">
              <Tag icon={<CloudServerOutlined />} color="green">TS</Tag>
            </Tooltip>
          )}
        </Space>
      )
    },
    {
      title: "Ações",
      key: "action",
      width: 150,
      render: (_, record) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            icon={<SearchOutlined />}
            onClick={() => showModal(record)}
          >
            Detalhes
          </Button>
          <Button
            type="primary"
            danger
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => ExcluirRemoto(record)}
          >
            Excluir
          </Button>
        </Space>
      ),
    },
  ];

  const columnsContatos = [
    {
      title: "TeamView",
      dataIndex: "TEAMVIEWER_ACESSO",
      key: "TEAMVIEWER_ACESSO",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Senha",
      dataIndex: "SENHA_TEAMVIEWER",
      key: "SENHA_TEAMVIEWER",
    },
    {
      title: "AnyDesk",
      dataIndex: "ANYDESK_ACESSO",
      key: "ANYDESK_ACESSO",
    },
    {
      title: "Senha",
      dataIndex: "SENHA_ANYDESK",
      key: "SENHA_ANYDESK",
    },
    {
      title: "IP TS",
      dataIndex: "IP_TERMINAL_SERVER",
      key: "IP_TERMINAL_SERVER",
    },
    {
      title: "Usuario Ts",
      dataIndex: "USUARIO_TERMINAL_SERVER",
      key: "USUARIO_TERMINAL_SERVER",
    },
    {
      title: "Senha Ts",
      dataIndex: "SENHA_USUARIO_TERMINAL",
      key: "SENHA_USUARIO_TERMINAL",
    },
  ];

  async function CarregaDados() {
    setLoad(true)
    const api = await useRemoto();
    await api.ConsultaRemoto(filtro.toUpperCase(), user.TOKEN).then((res) => {
      console.log(res);
      setDataAcesso(res.data)
    });
    setLoad(false)
  }

  useEffect(() => {
     CarregaDados();
  }, []);

  const ExcluirRemoto = async(dados) => {   
    const api = await useRemoto();
    await api.delete(dados.ID_ACESSO_REMOTO, user.TOKEN)
    handleCancel()
    mensagem('sucess', 'Salvo com sucesso')
    CarregaDados();
  };


  const showModal = (dados) => {
    setDadosSelecionados(dados);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleOkAcesso = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpenCadastrar(false);
  };

  const onClose = () => {
    setOpen(!open);
  };

  const SalvarRemoto = async(dados) => {
    const api = await useRemoto();;
    await api.Salvar(dados, user.TOKEN)
    handleCancel()
    mensagem('sucess', 'Salvo com sucesso')
    CarregaDados();
  };

  const [isAddingAccess, setIsAddingAccess] = useState(false);

  // Colunas da tabela de acessos
  const accessColumns = [
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (tipo) => {
        const accessType = accessTypes.find(t => t.key === tipo.toLowerCase());
        return (
          <Tag icon={accessType?.icon} color={
            tipo === 'TEAMVIEWER' ? 'blue' :
            tipo === 'ANYDESK' ? 'volcano' :
            'green'
          }>
            {tipo}
          </Tag>
        );
      }
    },
    {
      title: 'ID/IP',
      dataIndex: 'identificador',
      key: 'identificador',
    },
    {
      title: 'Usuário',
      dataIndex: 'usuario',
      key: 'usuario',
    },
    {
      title: 'Última Atualização',
      dataIndex: 'data_atualizacao',
      key: 'data_atualizacao',
    },
    {
      title: 'Ações',
      key: 'actions',
      width: 100,
      render: (_, record) => (
        <Button 
          type="text" 
          danger 
          icon={<DeleteOutlined />}
          onClick={() => console.log(record.id)}
        />
      )
    }
  ];

  return (
    <Container>
      <div style={{ 
        background: '#fff',
        padding: '24px',
        borderRadius: '8px',
        marginBottom: '24px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        width: '100%',
        maxWidth: '100%'
      }}>
        {/* Título */}
        <Row style={{ width: '100%', marginBottom: '24px' }}>
          <Col span={24}>
            <div style={{ 
              borderBottom: '2px solid #1890ff',
              paddingBottom: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}>
              <Space>
                <span style={{ fontSize: 18, fontWeight: 500 }}>Acessos Remotos</span>
              </Space>
            </div>
          </Col>
        </Row>

        {/* Filtros */}
        <Row gutter={[16, 16]} style={{ width: '100%' }}>
          {/* Campo de Busca */}
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item 
              label="Buscar" 
              style={{ marginBottom: 8 }}
            >
              <Input
                prefix={<SearchOutlined style={{ color: '#1890ff' }} />}
                placeholder="Descrição, contrato ou cliente..."
                value={filtro}
                onChange={(e) => setFiltro(e.target.value)}
                allowClear
              />
            </Form.Item>
          </Col>

          {/* Status */}
          <Col xs={24} sm={12} md={5} lg={5} xl={5}>
            <Form.Item 
              label="Status" 
              style={{ marginBottom: 8 }}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Status"
                options={[
                  { value: 'todos', label: 'Todos' },
                  { value: 'ativo', label: 'Ativo' },
                  { value: 'inativo', label: 'Inativo' }
                ]}
                defaultValue="todos"
              />
            </Form.Item>
          </Col>

          {/* Tipo de Acesso */}
          <Col xs={24} sm={12} md={5} lg={5} xl={5}>
            <Form.Item 
              label="Tipo de Acesso" 
              style={{ marginBottom: 8 }}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Tipo"
                mode="multiple"
                maxTagCount="responsive"
                options={[
                  { value: 'teamviewer', label: 'TeamViewer' },
                  { value: 'anydesk', label: 'AnyDesk' },
                  { value: 'ts', label: 'Terminal Server' }
                ]}
              />
            </Form.Item>
          </Col>

          {/* Botões */}
          <Col 
            xs={24} 
            sm={24} 
            md={12} 
            lg={12} 
            xl={4}
          >
            <Space 
              size="small"
              style={{ 
                width: '100%',
                justifyContent: { xs: 'space-between', sm: 'flex-end' }
              }}
            >
              <Button 
                type="primary" 
                icon={<SearchOutlined />}
                onClick={() => CarregaDados()}
              >
                Pesquisar
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsModalOpenCadastrar(true)}
                style={{ backgroundColor: "#05b305" }}
              >
                Novo
              </Button>
            </Space>
          </Col>
        </Row>
      </div>

      {/* Tabela */}
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <Table
          columns={columns}
          dataSource={dataAcesso}
          loading={load}
          size="middle"
          pagination={{
            pageSize: 10,
            showTotal: (total) => `Total ${total} registros`,
            showSizeChanger: true,
            showQuickJumper: true
          }}
          rowKey="ID_ACESSO_REMOTO"
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>{record.OBS}</p>
            ),
            rowExpandable: (record) => record.OBS,
          }}
          style={{ width: '100%' }}
          scroll={{ x: 'max-content' }}
        />
      </div>

      <Modal
        title={dadosSelecionados?.DESCRICAO_ACESSO}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}

        width={'80%'}
      >
        <p>Acesso : </p>
        <Table columns={columnsContatos} dataSource={[dadosSelecionados]} 
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.OBS}
            </p>
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }} />
      
      </Modal>
      <Modal
        title="Cadastro de Acesso Remoto"
        open={isModalOpenCadastrar}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Form
          onFinish={SalvarRemoto}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="Contrato" 
                name="contrato" 
                rules={[{ required: false, type: 'number' }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Descrição" 
                name="descricao" 
                rules={[{ required: true, message: "Descrição é obrigatória", min: 3 }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Divider>Tipos de Acesso</Divider>

          <div style={{ marginBottom: 16 }}>
            <Space>
              {accessTypes.map(type => (
                <Button
                  key={type.key}
                  icon={type.icon}
                  onClick={() => {
                    setSelectedAccessType(type.key);
                    setAccessList([...accessList, { type: type.key, id: Date.now() }]);
                  }}
                >
                  {type.label}
                </Button>
              ))}
            </Space>
          </div>

          {accessList.map((access, index) => (
            <div key={access.id} style={{ 
              border: '1px solid #f0f0f0', 
              padding: 16, 
              marginBottom: 16,
              borderRadius: 8,
              position: 'relative'
            }}>
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                style={{ position: 'absolute', right: 8, top: 8 }}
                onClick={() => {
                  const newList = accessList.filter(item => item.id !== access.id);
                  setAccessList(newList);
                }}
              />
              <h4>{accessTypes.find(t => t.key === access.type).label} #{index + 1}</h4>
              {renderAccessFields(access.type)}
            </div>
          ))}

          <Form.Item label="Observaçes" name="obs">
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item style={{ marginTop: 16, textAlign: 'right' }}>
            <Space>
              <Button danger onClick={handleCancel}>
                Cancelar
              </Button>
              <Button type="primary" htmlType="submit">
                Salvar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={`Detalhes do Acesso - ${dadosSelecionados?.DESCRICAO_ACESSO}`}
        open={isModalOpen}
        onCancel={handleOk}
        width="80%"
        footer={null}
      >
        <div style={{ marginBottom: 24 }}>
          <Row gutter={16} align="middle">
            <Col>
              <Button 
                type="primary" 
                icon={<PlusOutlined />}
                onClick={() => setIsAddingAccess(true)}
              >
                Adicionar Novo Acesso
              </Button>
            </Col>
            <Col>
              <Tag color="blue">Contrato: {dadosSelecionados?.CONTRATO}</Tag>
              <Tag color="cyan">Acessos: {dadosSelecionados?.total_acessos || 0}</Tag>
            </Col>
          </Row>
        </div>

        {/* Seção de adição de novo acesso */}
        {isAddingAccess && (
          <div style={{ 
            marginBottom: 24, 
            padding: 24, 
            background: '#f8f9fa', 
            borderRadius: 8,
            border: '1px solid #e9ecef'
          }}>
            <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
              <Col>
                <h4 style={{ margin: 0 }}>Novo Acesso</h4>
              </Col>
              <Col>
                <Button 
                  type="text" 
                  icon={<CloseOutlined />} 
                  onClick={() => setIsAddingAccess(false)}
                />
              </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col>
                <Space>
                  {accessTypes.map(type => (
                    <Button
                      key={type.key}
                      icon={type.icon}
                      onClick={() => {
                        setSelectedAccessType(type.key);
                        setAccessList([...accessList, { type: type.key, id: Date.now() }]);
                      }}
                    >
                      {type.label}
                    </Button>
                  ))}
                </Space>
              </Col>
            </Row>

            {accessList.map((access, index) => (
              <div key={access.id} style={{ 
                background: '#fff',
                padding: 16, 
                marginBottom: 16,
                borderRadius: 8,
                border: '1px solid #e9ecef'
              }}>
                <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
                  <Col>
                    <Tag icon={accessTypes.find(t => t.key === access.type)?.icon}>
                      {accessTypes.find(t => t.key === access.type)?.label} #{index + 1}
                    </Tag>
                  </Col>
                  <Col>
                    <Button
                      type="text"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        const newList = accessList.filter(item => item.id !== access.id);
                        setAccessList(newList);
                      }}
                    />
                  </Col>
                </Row>
                {renderAccessFields(access.type)}
              </div>
            ))}

            {accessList.length > 0 && (
              <Row justify="end">
                <Col>
                  <Space>
                    <Button onClick={() => {
                      setAccessList([]);
                      setIsAddingAccess(false);
                    }}>
                      Cancelar
                    </Button>
                    <Button type="primary" onClick={console.log('')}>
                      Salvar Acessos
                    </Button>
                  </Space>
                </Col>
              </Row>
            )}
          </div>
        )}

        {/* Tabela de acessos existentes */}
        <Table 
          columns={accessColumns}
          dataSource={dadosSelecionados?.acessos || []}
          expandable={{
            expandedRowRender: (record) => (
              <div style={{ padding: '16px' }}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Card size="small" title="Informações de Acesso">
                      <p><strong>Senha:</strong> ******</p>
                      {record.porta && <p><strong>Porta:</strong> {record.porta}</p>}
                      <p><strong>Última Utilização:</strong> {record.ultimo_acesso || 'Não registrado'}</p>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card size="small" title="Observações">
                      <p>{record.observacoes || 'Nenhuma observação cadastrada'}</p>
                    </Card>
                  </Col>
                </Row>
              </div>
            ),
          }}
          pagination={false}
          size="middle"
          bordered
          rowKey="id"
          style={{ 
            background: '#fff',
            borderRadius: 8,
            overflow: 'hidden'
          }}
        />
      </Modal>
    </Container>
  );
}
