import { 
  Modal, 
  Table, 
  Button, 
  Space, 
  Select, 
  message, 
  Card, 
  Typography, 
  Row, 
  Col, 
  Divider,
  Tabs,
  Badge,
  Empty,
  Alert
} from 'antd';
import { 
  UserAddOutlined, 
  IdcardOutlined, 
  TeamOutlined, 
  PlusCircleOutlined,
  LinkOutlined,
  DisconnectOutlined
} from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { useContadores } from '../../../Services/Contadores';
import { useUsuario } from '../../../Contexts/auth';
import { mask } from '../../../Helpers/UsaGeral';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

// Hook para responsividade
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowSize;
};

export function ModalClientes({ 
  isOpen, 
  onClose, 
  detalhesContador, 
  dadosClientesContador, 
  loading,
  recarregarDados 
}) {
  const [selectedClients, setSelectedClients] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [clientesDisponiveis, setClientesDisponiveis] = useState([]);
  const [loadingClientes, setLoadingClientes] = useState(false);
  const { user } = useUsuario();
  const apiContadores = useContadores();
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const isTablet = width <= 1024;

  const statusFilters = [
    { text: 'Ativo', value: 'N' },
    { text: 'Inativo', value: 'I' }
  ];

  // Carregar clientes disponíveis
  const carregarClientesDisponiveis = async () => {
    setLoadingClientes(true);
    try {
      const response = await (await apiContadores).ConsultaClientesSelec(user.TOKEN);
      // Filtra os clientes que já estão vinculados
      const clientesNaoVinculados = response.data.filter(
        cliente => !dadosClientesContador.some(
          vinculado => vinculado.COD_CLIENTE === cliente.VALOR
        )
      );
      setClientesDisponiveis(clientesNaoVinculados);
    } catch (error) {
      message.error('Erro ao carregar clientes disponíveis');
      console.error(error);
    } finally {
      setLoadingClientes(false);
    }
  };

  useEffect(() => {
    if (activeTab === '2') {
      carregarClientesDisponiveis();
    }
  }, [activeTab]);

  const getResponsiveColumns = () => {
    const baseColumns = [
      {
        title: "Código",
        dataIndex: "ID_CLIENTE",
        key: "ID_CLIENTE",
        width: 80,
        align: 'center',
        fixed: 'left',
      },
      {
        title: "Razão Social",
        dataIndex: "NOME_COMERCIAL",
        key: "NOME_COMERCIAL",
        ellipsis: true,
        width: isMobile ? 200 : 300,
      },
      !isMobile && {
        title: "Nome Fantasia",
        dataIndex: "FANTASIA",
        key: "FANTASIA",
        ellipsis: true,
        width: 200,
        render: (text) => text || '-',
      },
      !isMobile && {
        title: "CNPJ",
        dataIndex: "CNPJ",
        key: "CNPJ",
        width: 150,
        render: (text) => text ? mask(text) : '-',
      },
      {
        title: "Status",
        dataIndex: "STATUS_CLIENTE",
        key: "STATUS_CLIENTE",
        width: 100,
        align: 'center',
        filters: statusFilters,
        filterMode: 'tree',
        filtered: true,
        onFilter: (value, record) => record.STATUS_CLIENTE?.trim() === value,
        render: (status) => {
          const statusTrim = status?.trim();
          return (
            <Badge
              status={statusTrim === 'N' ? 'success' : 'error'}
              text={statusTrim === 'N' ? 'Ativo' : 'Inativo'}
            />
          );
        },
      },
      {
        title: "Ações",
        key: "action",
        width: 120,
        align: 'center',
        fixed: 'right',
        render: (_, record) => (
          <Button
            type="primary"
            danger
            size="small"
            icon={<DisconnectOutlined />}
            onClick={() => handleDesvincular(record.ID_CLIENTE)}
          >
            {!isMobile && 'Desvincular'}
          </Button>
        ),
      },
    ].filter(Boolean);

    return baseColumns;
  };

  const handleDesvincular = async (codCliente) => {
    try {
      await (await apiContadores).deleteClientesContador(detalhesContador.ID_CONTADOR, codCliente, user.TOKEN);
      message.success('Cliente desvinculado com sucesso!');
      recarregarDados();
    } catch (error) {
      message.error('Erro ao desvincular cliente');
      console.error(error);
    }
  };

  const handleAddClients = async () => {
    if (!selectedClients.length) {
      message.warning('Selecione pelo menos um cliente');
      return;
    }

    setLoadingAdd(true);
    try {
      await Promise.all(
        selectedClients.map(async clientId => 
          await (await apiContadores).addCliente(detalhesContador.ID_CONTADOR, clientId, user.TOKEN)
        )
      );
      message.success('Clientes vinculados com sucesso!');
      setSelectedClients([]);
      setActiveTab('1');
      recarregarDados();
    } catch (error) {
      message.error('Erro ao vincular clientes');
      console.error(error);
    } finally {
      setLoadingAdd(false);
    }
  };

  return (
    <Modal
      title="Gerenciamento de Clientes"
      open={isOpen}
      onCancel={onClose}
      onOk={onClose}
      width={isMobile ? '100%' : isTablet ? '90%' : 1050}
      style={{ 
        top: isMobile ? 0 : 20,
        maxWidth: isMobile ? '100vw' : isTablet ? '90vw' : 1050
      }}
      className={isMobile ? 'full-screen-modal' : ''}
    >
      {/* Cabeçalho com Informações do Contador */}
      <Card className="info-card">
        <Title level={5}>
          <IdcardOutlined /> Informações Gerais
        </Title>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text strong>Nome: </Text>
            <Text>{detalhesContador?.NOME}</Text>
          </Col>
          <Col span={12}>
            <Text strong>CPF/CNPJ: </Text>
            <Text>{detalhesContador?.CGC ? mask(detalhesContador.CGC) : '-'}</Text>
          </Col>
          <Col span={12}>
            <Text strong>CRC: </Text>
            <Text>{detalhesContador?.CRC_CONTADOR || '-'}</Text>
          </Col>
        </Row>
      </Card>

      <Divider />

      <Tabs 
        activeKey={activeTab} 
        onChange={setActiveTab}
        type="card"
        className="custom-tabs"
      >
        <TabPane 
          tab={
            <span>
              <TeamOutlined />
              Clientes Vinculados
            </span>
          } 
          key="1"
        >
          <div className="table-container">
            <Table
              columns={getResponsiveColumns()}
              dataSource={dadosClientesContador}
              loading={loading}
              size={isMobile ? 'small' : 'middle'}
              pagination={{
                pageSize: isMobile ? 5 : 7,
                showTotal: (total) => `Total: ${total} clientes`,
                showSizeChanger: !isMobile,
                showQuickJumper: !isMobile,
                size: isMobile ? 'small' : 'default'
              }}
              rowKey="ID_CLIENTE"
              scroll={{ 
                x: isMobile ? 400 : 950,
                y: isMobile ? 300 : 400 
              }}
              locale={{
                filterConfirm: 'Filtrar',
                filterReset: 'Limpar',
                emptyText: (
                  <Empty 
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Nenhum cliente vinculado" 
                  />
                )
              }}
            />
          </div>
        </TabPane>

        <TabPane 
          tab={
            <span>
              <PlusCircleOutlined />
              Adicionar Novos Clientes
            </span>
          } 
          key="2"
        >
          <Card className="select-card">
            <Space direction="vertical" style={{ width: '100%' }}>
              <Alert
                message="Selecione os clientes que deseja vincular ao contador"
                description="Você pode buscar por código ou nome do cliente"
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />
              
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Busque por código ou nome do cliente"
                onChange={setSelectedClients}
                value={selectedClients}
                optionFilterProp="children"
                showSearch
                loading={loadingClientes}
                filterOption={(input, option) => {
                  const label = option.children?.toLowerCase() || '';
                  return label.includes(input.toLowerCase());
                }}
                maxTagCount="responsive"
              >
                {clientesDisponiveis.map(cliente => (
                  <Select.Option 
                    key={cliente.VALOR} 
                    value={cliente.VALOR}
                  >
                    {cliente.LABEL}
                  </Select.Option>
                ))}
              </Select>

              <div className="action-buttons">
                <Space>
                  <Button 
                    type="default"
                    onClick={() => {
                      setSelectedClients([]);
                      setActiveTab('1');
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button 
                    type="primary"
                    icon={<LinkOutlined />}
                    onClick={handleAddClients}
                    loading={loadingAdd}
                    disabled={!selectedClients.length}
                  >
                    Vincular {selectedClients.length} 
                    {selectedClients.length === 1 ? ' Cliente' : ' Clientes'}
                  </Button>
                </Space>
              </div>
            </Space>
          </Card>
        </TabPane>
      </Tabs>

      <style jsx>{`
        .info-card {
          background: #fafafa;
          margin-bottom: 24px;
          border-radius: 8px;
        }

        .select-card {
          background: #fff;
          padding: 24px;
          border-radius: 8px;
          min-height: 300px;
        }

        .action-buttons {
          margin-top: 24px;
          display: flex;
          justify-content: flex-end;
        }

        :global(.custom-tabs .ant-tabs-nav) {
          margin-bottom: 16px;
        }

        :global(.custom-tabs .ant-tabs-tab) {
          padding: 8px 16px;
          border-radius: 6px 6px 0 0;
        }

        :global(.custom-tabs .ant-tabs-tab-active) {
          background: #1890ff;
        }

        :global(.custom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn) {
          color: #fff !important;
        }

        :global(.custom-tabs .ant-tabs-tab-btn .anticon) {
          margin-right: 8px;
        }

        :global(.ant-select-multiple .ant-select-selection-item) {
          border-radius: 4px;
          background: #e6f7ff;
          border-color: #91d5ff;
        }

        :global(.ant-select-item-option-selected) {
          background-color: #e6f7ff !important;
        }

        :global(.ant-select-item-option) {
          padding: 8px 12px;
        }

        :global(.ant-select-selection-search) {
          margin-inline-start: 0;
        }

        :global(.ant-table-thead > tr > th) {
          background: #fafafa;
          font-weight: 600;
        }

        :global(.ant-table-tbody > tr > td) {
          vertical-align: middle;
        }

        :global(.ant-badge-status-dot) {
          width: 8px;
          height: 8px;
        }

        :global(.ant-badge-status-text) {
          font-size: 13px;
        }

        :global(.ant-table-row:hover) {
          cursor: pointer;
        }

        :global(.ant-table-cell button) {
          opacity: 0.9;
          transition: opacity 0.3s;
        }

        :global(.ant-table-cell button:hover) {
          opacity: 1;
        }

        .table-container {
          margin: -24px;
          margin-top: 0;
          overflow-x: auto;
        }

        .client-info {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        .main-text {
          font-weight: 500;
        }

        .secondary-text {
          color: #666;
          display: block;
        }

        :global(.full-screen-modal) {
          max-width: 100vw !important;
          margin: 0;
          padding: 0;
          top: 0;
        }

        :global(.full-screen-modal .ant-modal-content) {
          min-height: 100vh;
          border-radius: 0;
        }

        :global(.ant-table-wrapper) {
          ${isMobile ? `
            .ant-table {
              width: 100%;
              overflow-x: auto;
            }
          ` : `
            .ant-table {
              width: 100%;
              overflow-x: auto;
            }
          `}
        }

        :global(.ant-table-filter-trigger) {
          margin: -4px -8px -4px 4px;
          padding: 0 4px;
        }

        :global(.ant-table-filter-trigger.active) {
          color: #1890ff;
        }

        :global(.ant-table-filter-dropdown) {
          min-width: 120px;
        }

        :global(.ant-table-filter-dropdown-btns) {
          border-top: 1px solid #f0f0f0;
        }

        :global(.ant-table-filter-dropdown-tree) {
          padding: 8px;
        }

        :global(.ant-badge-status-dot) {
          width: 8px;
          height: 8px;
        }

        :global(.ant-badge-status-success) {
          background: #52c41a;
        }

        :global(.ant-badge-status-error) {
          background: #ff4d4f;
        }
      `}</style>
    </Modal>
  );
} 