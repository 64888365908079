import { useState } from 'react';
import { 
  Card, 
  Row, 
  Col, 
  Table, 
  Button, 
  Input, 
  DatePicker, 
  Select,
  Typography,
  Tag,
  Space,
  Image,
  Modal,
  Statistic,
  Tooltip,
  Badge
} from 'antd';
import {
  BugOutlined,
  SearchOutlined,
  EyeOutlined,
  WarningOutlined,
  DesktopOutlined,
  UserOutlined,
  GlobalOutlined,
  BuildOutlined,
  FileImageOutlined,
  ClockCircleOutlined,
  NumberOutlined
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { Area } from '@ant-design/plots';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

export function ErrosClientes() {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedError, setSelectedError] = useState(null);
  const [filtros, setFiltros] = useState({
    periodo: null,
    busca: '',
    empresa: undefined
  });

  // Dados fictícios
  const [erros] = useState([
    {
      id: 1,
      terminal: 'PC-VENDAS-01',
      usuarioWin: 'joao.silva',
      ipCliente: '192.168.1.100',
      codUsu: 'JOAO',
      codEmp: 1,
      ultimoAcesso: '2024-03-18 14:30:00',
      versaoWin: 'Windows 10 Pro',
      contrato: 'CONT-001',
      screenshot: 'url_da_imagem',
      descricaoErro: 'Erro ao acessar banco de dados',
      status: 'Pendente'
    },
    // ... mais dados
  ]);

  // Dados para o gráfico
  const dadosGrafico = [
    { data: '2024-03-01', quantidade: 3 },
    { data: '2024-03-02', quantidade: 5 },
    { data: '2024-03-03', quantidade: 2 },
    // ... mais dados
  ];

  const columns = [
    {
      title: 'Data/Hora',
      dataIndex: 'ultimoAcesso',
      key: 'ultimoAcesso',
      render: (text) => moment(text).format('DD/MM/YYYY HH:mm'),
      sorter: (a, b) => moment(a.ultimoAcesso).unix() - moment(b.ultimoAcesso).unix()
    },
    {
      title: 'Terminal',
      dataIndex: 'terminal',
      key: 'terminal',
      render: (text) => (
        <Tooltip title={text}>
          <Space>
            <DesktopOutlined />
            {text}
          </Space>
        </Tooltip>
      )
    },
    {
      title: 'Usuário',
      dataIndex: 'usuarioWin',
      key: 'usuarioWin',
      render: (text, record) => (
        <Space>
          <UserOutlined />
          {text}
          <Tag color="blue">{record.codUsu}</Tag>
        </Space>
      )
    },
    {
      title: 'Empresa',
      dataIndex: 'codEmp',
      key: 'codEmp',
      render: (text, record) => (
        <Tag color="cyan">
          {text} - {record.contrato}
        </Tag>
      )
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={status === 'Pendente' ? 'warning' : 'success'}>
          {status}
        </Tag>
      )
    },
    {
      title: 'Ações',
      key: 'acoes',
      render: (_, record) => (
        <Button 
          type="primary" 
          icon={<EyeOutlined />}
          onClick={() => {
            setSelectedError(record);
            setModalVisible(true);
          }}
        >
          Detalhes
        </Button>
      )
    }
  ];

  const config = {
    data: dadosGrafico,
    xField: 'data',
    yField: 'quantidade',
    smooth: true,
    areaStyle: {
      fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
    }
  };

  return (
    <div style={{ background: '#f0f2f5', minHeight: '100vh', padding: '24px' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
        {/* Cards de Estatísticas */}
        <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
          <Col xs={24} sm={8}>
            <Card>
              <Statistic
                title="Total de Erros"
                value={erros.length}
                prefix={<BugOutlined style={{ color: '#ff4d4f' }} />}
                valueStyle={{ color: '#ff4d4f' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card>
              <Statistic
                title="Erros Pendentes"
                value={erros.filter(e => e.status === 'Pendente').length}
                prefix={<ClockCircleOutlined style={{ color: '#faad14' }} />}
                valueStyle={{ color: '#faad14' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card>
              <Statistic
                title="Empresas Afetadas"
                value={new Set(erros.map(e => e.codEmp)).size}
                prefix={<BuildOutlined style={{ color: '#1890ff' }} />}
                valueStyle={{ color: '#1890ff' }}
              />
            </Card>
          </Col>
        </Row>

        {/* Filtros */}
        <Card style={{ marginBottom: '24px' }}>
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={8}>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Buscar por terminal, usuário..."
                onChange={(e) => setFiltros({ ...filtros, busca: e.target.value })}
                allowClear
              />
            </Col>
            <Col xs={24} sm={8}>
              <RangePicker
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                locale={locale}
                onChange={(dates) => setFiltros({ ...filtros, periodo: dates })}
                placeholder={['Data início', 'Data fim']}
              />
            </Col>
            <Col xs={24} sm={8}>
              <Select
                style={{ width: '100%' }}
                placeholder="Selecione a empresa"
                onChange={(value) => setFiltros({ ...filtros, empresa: value })}
                allowClear
                options={[
                  { value: 1, label: 'Empresa 1' },
                  { value: 2, label: 'Empresa 2' }
                ]}
              />
            </Col>
          </Row>
        </Card>

        {/* Gráfico */}
        <Card title="Evolução de Erros" style={{ marginBottom: '24px' }}>
          <Area {...config} />
        </Card>

        {/* Tabela */}
        <Card>
          <Table
            columns={columns}
            dataSource={erros}
            loading={loading}
            rowKey="id"
            pagination={{
              pageSize: 10,
              showTotal: (total) => `Total: ${total} registros`
            }}
          />
        </Card>

        {/* Modal de Detalhes */}
        <Modal
          title={
            <Space>
              <WarningOutlined style={{ color: '#ff4d4f' }} />
              <span>Detalhes do Erro</span>
            </Space>
          }
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          width={800}
          footer={null}
        >
          {selectedError && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card size="small" title="Informações do Sistema">
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Text strong>Terminal: </Text>
                      <Text>{selectedError.terminal}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>Versão Windows: </Text>
                      <Text>{selectedError.versaoWin}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>IP: </Text>
                      <Text>{selectedError.ipCliente}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>Último Acesso: </Text>
                      <Text>{moment(selectedError.ultimoAcesso).format('DD/MM/YYYY HH:mm')}</Text>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card size="small" title="Informações do Usuário">
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Text strong>Usuário Windows: </Text>
                      <Text>{selectedError.usuarioWin}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>Código Usuário: </Text>
                      <Text>{selectedError.codUsu}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>Empresa: </Text>
                      <Text>{selectedError.codEmp}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>Contrato: </Text>
                      <Text>{selectedError.contrato}</Text>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card size="small" title="Screenshot do Erro">
                  <Image
                    src={selectedError.screenshot}
                    alt="Screenshot do erro"
                  />
                </Card>
              </Col>
            </Row>
          )}
        </Modal>
      </div>
    </div>
  );
}