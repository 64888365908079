import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Space } from "antd";
import { SearchContainer } from "./styles";

export function SearchBar({ filtro, setFiltro, CarregaDados, load }) {
  return (
    <SearchContainer>
      <Row gutter={[5, 5]} align="bottom">
        <Row style={{ width: "100%", marginBottom: "15px" }}>
          <Col span={24}>
            <div
              style={{
                borderBottom: "2px solid #1890ff",
                paddingBottom: "8px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Space>
                <span style={{ fontSize: 18, fontWeight: 500 }}>
                  Clientes
                </span>
              </Space>
            </div>
          </Col>
        </Row>
        <Col xs={24} sm={24} md={14} lg={14}>
          <div className="input-group">
            <label htmlFor="cliente">Cliente:</label>
            <Input
              id="cliente"
              placeholder="Digite o nome do cliente"
              value={filtro}
              onChange={(e) => setFiltro(e.target.value)}
              size="large"
              allowClear
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6}>
          <div className="input-group">
            <label>Status:</label>
            <Select
              size="large"
              style={{ width: "100%" }}
              options={[
                { value: "Ativo", label: "Ativo" },
                { value: "Inativo", label: "Inativo" },
              ]}
              defaultValue={"Ativo"}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={CarregaDados}
            loading={load}
            size="large"
            block
          >
            Pesquisar
          </Button>
        </Col>
      </Row>
    </SearchContainer>
  );
}
