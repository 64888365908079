import { api } from "../api"

export const useUsuarioServices = async() => ({     
     ConsultaUsuarioBackup :  async (logado, token)=>{
        const response = await api.post('/usuario/id', 
        { logado },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },


  })