import Sider from "antd/es/layout/Sider";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex : 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    
`; 

export const Sidebar = styled(Sider)`   
    overflow-y:  auto; 
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.29), rgba(255, 255, 255, 0.29)), linear-gradient(203.96deg, #005CE6 5.91%, #000B6F 59.56%);
    .ant-layout .ant-layout-sider{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.29), rgba(255, 255, 255, 0.29)), linear-gradient(203.96deg, #005CE6 5.91%, #000B6F 59.56%);
    }

`;

export const ContainerConteudo =styled.div`
 width:  100%;
 height: 100%;
 overflow-y:  auto;
`



