import styled from 'styled-components';

export const ChatInternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .search-container {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;

    .ant-input {
      border-radius: 20px;
      background: #f5f5f5;
    }
  }
`;

export const ChatHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  background: #fff;

  h3 {
    margin: 0;
    font-size: 16px;
  }

  .contact-info {
    h3 {
      margin: 0;
      font-size: 16px;
    }

    span {
      font-size: 12px;
      color: #52c41a;
    }
  }
`;

export const ContactList = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const ContactItem = styled.div`
  display: flex;
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: #f5f5f5;
  }

  .contact-info {
    margin-left: 12px;
    flex: 1;
    min-width: 0;
  }

  .contact-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    h4 {
      margin: 0;
      font-size: 14px;
    }

    .time {
      font-size: 12px;
      color: #8c8c8c;
    }
  }

  .contact-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
      color: #8c8c8c;
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .group-members {
    font-size: 12px;
    color: #8c8c8c;
    margin-top: 4px;
  }
`;

export const Messages = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Message = styled.div`
  display: flex;
  justify-content: ${props => props.className === 'sent' ? 'flex-end' : 'flex-start'};

  .message-content {
    max-width: 70%;
    background: ${props => props.className === 'sent' ? '#dcf8c6' : '#fff'};
    padding: 8px 12px;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

    .sender {
      font-size: 12px;
      color: #1890ff;
      display: block;
      margin-bottom: 4px;
    }

    p {
      margin: 0;
      font-size: 14px;
    }

    .time {
      font-size: 11px;
      color: #8c8c8c;
      display: block;
      text-align: right;
      margin-top: 4px;
    }
  }
`;

export const MessageInput = styled.div`
  padding: 16px;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  display: flex;
  gap: 8px;

  .ant-input {
    border-radius: 20px;
    background: #f5f5f5;
  }

  .ant-btn {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`; 