import { useState } from 'react';
import { 
  Card, 
  Row, 
  Col, 
  Button, 
  Modal, 
  Form, 
  Input, 
  Select, 
  Typography,
  Tag,
  Space,
  Table,
  Tooltip,
  Badge,
  Divider,
  message,
  Popover,
  Drawer
} from 'antd';
import {
  KeyOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  PlusOutlined,
  LockOutlined,
  UserOutlined,
  SearchOutlined,
  CopyOutlined,
  EditOutlined,
  DeleteOutlined,
  GlobalOutlined,
  TeamOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/pt-br';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export function SenhasAcessos() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [senhaVisivel, setSenhaVisivel] = useState({});
  const [filtros, setFiltros] = useState({
    busca: '',
    categoria: undefined,
    departamento: undefined
  });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedSenha, setSelectedSenha] = useState(null);

  // Dados fictícios
  const [senhas, setSenhas] = useState([
    {
      id: 1,
      descricao: 'Acesso Servidor Principal',
      usuario: 'admin.server',
      senha: '123456',
      observacao: 'Servidor de produção',
      categoria: 'privada',
      departamento: 'suporte',
      dataCriacao: '2024-03-18',
      ultimaAtualizacao: '2024-03-18'
    }
  ]);

  const categorias = [
    { value: 'privada', label: 'Privada', color: 'red' },
    { value: 'publica', label: 'Pública', color: 'green' }
  ];

  const departamentos = [
    { value: 'suporte', label: 'Suporte', color: 'blue' },
    { value: 'desenvolvimento', label: 'Desenvolvimento', color: 'purple' }
  ];

  const columns = [
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
      render: (text) => (
        <Space>
          <KeyOutlined />
          <Text strong>{text}</Text>
        </Space>
      )
    },
    {
      title: 'Usuário',
      dataIndex: 'usuario',
      key: 'usuario',
      render: (text) => (
        <Space>
          <UserOutlined />
          <Text copyable>{text}</Text>
        </Space>
      )
    },
    {
      title: 'Senha',
      dataIndex: 'senha',
      key: 'senha',
      render: (text, record) => (
        <Space>
          <Button
            type="text"
            icon={senhaVisivel[record.id] ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={() => setSenhaVisivel({ ...senhaVisivel, [record.id]: !senhaVisivel[record.id] })}
          />
          {senhaVisivel[record.id] ? (
            <Text copyable>{text}</Text>
          ) : (
            '••••••••'
          )}
        </Space>
      )
    },
    {
      title: 'Categoria',
      dataIndex: 'categoria',
      key: 'categoria',
      render: (text) => {
        const categoria = categorias.find(c => c.value === text);
        return (
          <Tag color={categoria?.color}>
            {categoria?.label}
          </Tag>
        );
      }
    },
    {
      title: 'Departamento',
      dataIndex: 'departamento',
      key: 'departamento',
      render: (text) => {
        const departamento = departamentos.find(d => d.value === text);
        return (
          <Tag color={departamento?.color}>
            {departamento?.label}
          </Tag>
        );
      }
    },
    {
      title: 'Ações',
      key: 'acoes',
      render: (_, record) => (
        <Space>
          <Tooltip title="Detalhes">
            <Button
              type="text"
              icon={<InfoCircleOutlined />}
              onClick={() => {
                setSelectedSenha(record);
                setDrawerVisible(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Editar">
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            />
          </Tooltip>
          <Tooltip title="Excluir">
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record.id)}
            />
          </Tooltip>
        </Space>
      )
    }
  ];

  // Funções de manipulação
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleEdit = (record) => {
    form.setFieldsValue(record);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      // Aqui vai a lógica de deleção
      message.success('Senha excluída com sucesso!');
    } catch (error) {
      message.error('Erro ao excluir senha');
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // Aqui vai a lógica de salvamento
      const novaSenha = {
        ...values,
        id: values.id || Date.now(),
        dataCriacao: values.dataCriacao || moment().format('YYYY-MM-DD'),
        ultimaAtualizacao: moment().format('YYYY-MM-DD')
      };

      if (values.id) {
        // Atualização
        setSenhas(senhas.map(s => s.id === values.id ? novaSenha : s));
        message.success('Senha atualizada com sucesso!');
      } else {
        // Criação
        setSenhas([...senhas, novaSenha]);
        message.success('Senha cadastrada com sucesso!');
      }

      handleCancel();
    } catch (error) {
      message.error('Erro ao salvar senha');
    } finally {
      setLoading(false);
    }
  };

  // Função para filtrar os dados
  const getDadosFiltrados = () => {
    return senhas.filter(senha => {
      const matchBusca = !filtros.busca || 
        senha.descricao.toLowerCase().includes(filtros.busca.toLowerCase()) ||
        senha.usuario.toLowerCase().includes(filtros.busca.toLowerCase());

      const matchCategoria = !filtros.categoria || 
        senha.categoria === filtros.categoria;

      const matchDepartamento = !filtros.departamento || 
        senha.departamento === filtros.departamento;

      return matchBusca && matchCategoria && matchDepartamento;
    });
  };

  return (
    <div style={{ background: '#f0f2f5', minHeight: '100vh', padding: '24px' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
        {/* Cabeçalho */}
        <Row justify="space-between" align="middle" style={{ marginBottom: '24px' }}>
          <Col>
            <Title level={3}>
              <Space>
                <KeyOutlined />
                Gerenciador de Senhas
              </Space>
            </Title>
          </Col>
          <Col>
            <Button 
              type="primary" 
              icon={<PlusOutlined />}
              onClick={() => setIsModalOpen(true)}
            >
              Nova Senha
            </Button>
          </Col>
        </Row>

        {/* Filtros */}
        <Card style={{ marginBottom: '24px' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={8}>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Buscar por descrição ou usuário..."
                onChange={(e) => setFiltros({ ...filtros, busca: e.target.value })}
                allowClear
              />
            </Col>
            <Col xs={24} md={8}>
              <Select
                style={{ width: '100%' }}
                placeholder="Categoria"
                onChange={(value) => setFiltros({ ...filtros, categoria: value })}
                allowClear
              >
                {categorias.map(cat => (
                  <Option key={cat.value} value={cat.value}>
                    <Tag color={cat.color}>{cat.label}</Tag>
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} md={8}>
              <Select
                style={{ width: '100%' }}
                placeholder="Departamento"
                onChange={(value) => setFiltros({ ...filtros, departamento: value })}
                allowClear
              >
                {departamentos.map(dep => (
                  <Option key={dep.value} value={dep.value}>
                    <Tag color={dep.color}>{dep.label}</Tag>
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Card>

        {/* Tabela */}
        <Card>
          <Table
            columns={columns}
            dataSource={getDadosFiltrados()}
            loading={loading}
            rowKey="id"
            pagination={{
              pageSize: 10,
              showTotal: (total) => `Total: ${total} senhas`
            }}
          />
        </Card>

        {/* Modal de Cadastro/Edição */}
        <Modal
          title={
            <Space>
              <KeyOutlined />
              <span>Cadastro de Senha</span>
            </Space>
          }
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null}
          width={700}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="descricao"
              label="Descrição"
              rules={[{ required: true, message: 'Informe a descrição' }]}
            >
              <Input 
                prefix={<FileTextOutlined />}
                placeholder="Ex: Acesso Servidor Principal"
              />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="usuario"
                  label="Usuário"
                  rules={[{ required: true, message: 'Informe o usuário' }]}
                >
                  <Input 
                    prefix={<UserOutlined />}
                    placeholder="Nome do usuário"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="senha"
                  label="Senha"
                  rules={[{ required: true, message: 'Informe a senha' }]}
                >
                  <Input.Password 
                    prefix={<LockOutlined />}
                    placeholder="Digite a senha"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="categoria"
                  label="Categoria"
                  rules={[{ required: true, message: 'Selecione a categoria' }]}
                >
                  <Select placeholder="Selecione a categoria">
                    {categorias.map(cat => (
                      <Option key={cat.value} value={cat.value}>
                        <Tag color={cat.color}>{cat.label}</Tag>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="departamento"
                  label="Departamento"
                  rules={[{ required: true, message: 'Selecione o departamento' }]}
                >
                  <Select placeholder="Selecione o departamento">
                    {departamentos.map(dep => (
                      <Option key={dep.value} value={dep.value}>
                        <Tag color={dep.color}>{dep.label}</Tag>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="observacao"
              label="Observação"
            >
              <TextArea 
                rows={4}
                placeholder="Informações adicionais..."
                showCount
                maxLength={500}
              />
            </Form.Item>

            <Form.Item style={{ marginTop: '24px', textAlign: 'right' }}>
              <Space>
                <Button onClick={() => setIsModalOpen(false)}>
                  Cancelar
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Salvar
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>

        {/* Drawer de Detalhes */}
        <Drawer
          title={
            <Space>
              <KeyOutlined />
              <span>Detalhes da Senha</span>
            </Space>
          }
          placement="right"
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          width={500}
        >
          {selectedSenha && (
            <div>
              <Card size="small" style={{ marginBottom: '16px' }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Text strong>Descrição: </Text>
                    <Paragraph>{selectedSenha.descricao}</Paragraph>
                  </Col>
                  <Col span={12}>
                    <Text strong>Usuário: </Text>
                    <Text copyable>{selectedSenha.usuario}</Text>
                  </Col>
                  <Col span={12}>
                    <Text strong>Senha: </Text>
                    <Text copyable={{ text: selectedSenha.senha }}>••••••••</Text>
                  </Col>
                </Row>
              </Card>

              <Card size="small" style={{ marginBottom: '16px' }}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Text strong>Categoria: </Text>
                    <Tag color={categorias.find(c => c.value === selectedSenha.categoria)?.color}>
                      {categorias.find(c => c.value === selectedSenha.categoria)?.label}
                    </Tag>
                  </Col>
                  <Col span={12}>
                    <Text strong>Departamento: </Text>
                    <Tag color={departamentos.find(d => d.value === selectedSenha.departamento)?.color}>
                      {departamentos.find(d => d.value === selectedSenha.departamento)?.label}
                    </Tag>
                  </Col>
                </Row>
              </Card>

              <Card 
                size="small" 
                title={
                  <Text strong>
                    <InfoCircleOutlined /> Observações
                  </Text>
                }
              >
                <Paragraph
                  ellipsis={{ rows: 4, expandable: true, symbol: 'mais' }}
                  style={{ marginBottom: 0 }}
                >
                  {selectedSenha.observacao || 'Nenhuma observação cadastrada.'}
                </Paragraph>
              </Card>

              <Divider />

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Text type="secondary">
                    <ClockCircleOutlined /> Criado em: {' '}
                    {moment(selectedSenha.dataCriacao).format('DD/MM/YYYY')}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text type="secondary">
                    <ClockCircleOutlined /> Última atualização: {' '}
                    {moment(selectedSenha.ultimaAtualizacao).format('DD/MM/YYYY')}
                  </Text>
                </Col>
              </Row>
            </div>
          )}
        </Drawer>
      </div>
    </div>
  );
}