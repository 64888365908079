import { Modal, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useClientes } from "../../Services/Clientes/useClientes";
import { useUsuario } from "../../Contexts/auth";
import { Container, PageContainer } from "./style";
import { SearchBar } from "../../Components/Clientes/SearchBar";

export function Clientes() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataContatos, setDataContatos] = useState([]);
  const [loadContato, setLoadContato] = useState(false);
  const [load, setLoad] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [dadosCliente, setClientes] = useState();
  const { user } = useUsuario();
  const api = useClientes();

  const columns = [
    {
      title: "Contrato",
      dataIndex: "ID_CLIENTE",
      key: "ID_CLIENTE",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Fantasia",
      dataIndex: "FANTASIA",
      key: "FANTASIA",
    },
    {
      title: "Razão Social",
      dataIndex: "NOME_COMERCIAL",
      key: "NOME_COMERCIAL",
    },
    {
      title: "Status",
      dataIndex: "STATUS_CLIENTE",
      key: "STATUS_CLIENTE",
      render: (status) => {
        return (
        <span className={`status ${status.trim() === 'S' ? 'active' : 'inactive'}`}>
          {status.trim() === 'S' ? 'Ativo' : 'Inativo'}
        </span>
        )
      },
    },
    {
      title: "Ações",
      key: "action",
      render: (_, record) => (
        <button className="btn-details" onClick={() => showModal(record)}>
          Detalhes
        </button>
      ),
    },
  ];

  const columnsContatos = [
    {
      title: "Tipo",
      dataIndex: "TIPO_FONE",
      key: "TIPO_FONE",
    },
    {
      title: "Número",
      dataIndex: "FONE",
      key: "FONE",
    },
    {
      title: "Setor",
      dataIndex: "SETOR_FONE",
      key: "SETOR_FONE",
    },
  ];

  async function CarregaDados() {
    setLoad(true)   
    await (await api).ConsultaClientes(filtro.toUpperCase(),user.TOKEN)
    .then((res)=>{      
        setClientes(V => res.data);      
    })
    setLoad(false)
  }

  useEffect(() => {
   CarregaDados();
  },[]);

  const carregaContato = async(value) => {
    setLoadContato(true)
    await (await api).ConsultaContatosClientes(value.ID_CLIENTE,user.TOKEN)
    .then((res)=>{  
        console.log(res);    
        setDataContatos(res.data);      
    })
    setLoadContato(false)
  };

  const showModal = async(dados) => {
    carregaContato(dados)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <PageContainer>
      <Container>
        <SearchBar 
          filtro={filtro}
          setFiltro={setFiltro}
          CarregaDados={CarregaDados}
          load={load}
        />
        
        <div className="table-container">
          <Table 
            columns={columns} 
            dataSource={dadosCliente} 
            size="middle"
            loading={load}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              showTotal: (total) => `Total: ${total} registros`
            }}
          />
        </div>

        <Modal
          title="Detalhes do Cliente"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={700}
          className="details-modal"
        >
          <div className="contact-section">
            <h3>Contatos</h3>
            <Table 
              columns={columnsContatos} 
              dataSource={dataContatos} 
              loading={loadContato}
              size="small"
              pagination={false}
            />
          </div>
          <div className="email-section">
            <h3>E-mail</h3>
            <p>teste@gmail.com</p>
          </div>
        </Modal>
      </Container>
    </PageContainer>
  );
}
