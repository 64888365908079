import { useState } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useUsuario } from "../Contexts/auth";


export function RotaValida({children}){
  const [isPrivate, setIsPrivate] = useState(true);
  const { user } = useUsuario(); 

  if (user.LOGIN) {
    return children;
  }

  return (
    <Navigate to="/" replace/> 
  )
}

