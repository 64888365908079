import { useState, useEffect } from "react";
import { 
  Card, 
  Row, 
  Col, 
  Table, 
  Button, 
  Space, 
  Tag, 
  Typography, 
  Modal,
  Drawer,
  Timeline,
  Badge,
  Tooltip,
  Image,
  Divider,
  Form,
  Input,
  Select,
  Upload,
  message,
  Tabs,
  List,
  Alert,
  Popconfirm,
  Statistic,
  Radio,
  DatePicker
} from "antd";
import {
  FileSearchOutlined,
  MessageOutlined,
  EyeOutlined,
  HistoryOutlined,
  UserOutlined,
  ClockCircleOutlined,
  PaperClipOutlined,
  FolderOpenOutlined,
  TagOutlined,
  BuildOutlined,
  SendOutlined,
  InboxOutlined,
  FileOutlined,
  DownloadOutlined,
  EditOutlined,
  SaveOutlined,
  SearchOutlined,
  UpOutlined,
  DownOutlined,
  NumberOutlined,
  CustomerServiceOutlined,
  CodeOutlined,
  BankOutlined,
  CalculatorOutlined,
  FileTextOutlined,
  FilterOutlined,
  ClearOutlined,
  CheckCircleOutlined,
  TeamOutlined,
  CalendarOutlined,
  FlagOutlined,
  AppstoreOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";
import moment from "moment";
import { useUsuario } from "../../../Contexts/auth";
import { useChamados } from "../../../Services/Chamados/useChamados";
import { SearchBar } from "../../../Components/Chamados/SearchBar";
import styled, { createGlobalStyle } from "styled-components";

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

// Constantes
const STATUS_OPTIONS = [
  { value: 1, label: 'Em Aberto', color: 'blue', bgColor: '#e6f7ff' },
  { value: 2, label: 'Em Andamento', color: 'orange', bgColor: '#fff7e6' },
  { value: 3, label: 'Aguardando Cliente', color: 'red', bgColor: '#fff1f0' },
  { value: 4, label: 'Finalizado', color: 'green', bgColor: '#f6ffed' }
];

const PRIORIDADE_OPTIONS = [
  { value: 1, label: 'Baixa', color: 'blue' },
  { value: 2, label: 'Média', color: 'orange' },
  { value: 3, label: 'Alta', color: 'red' },
  { value: 4, label: 'Crítica', color: 'purple' }
];

// Funções auxiliares
const getStatusColor = (status) => {
  const statusObj = STATUS_OPTIONS.find(s => s.value === status);
  return statusObj?.color || 'default';
};

const getStatusText = (status) => {
  const statusObj = STATUS_OPTIONS.find(s => s.value === status);
  return statusObj?.label || 'Desconhecido';
};

const getStatusBgColor = (status) => {
  const statusObj = STATUS_OPTIONS.find(s => s.value === status);
  return statusObj?.bgColor || '#f5f5f5';
};

const getPrioridadeColor = (prioridade) => {
  const prioridadeObj = PRIORIDADE_OPTIONS.find(p => p.value === prioridade);
  return prioridadeObj?.color || 'default';
};

const isImageFile = (contentType) => {
  const imageFormats = ['image/jpeg', 'image/png', 'image/gif'];
  return imageFormats.includes(contentType);
};

const formatFileSize = (size) => {
  if (size < 1024) {
    return `${size} bytes`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else if (size < 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  } else {
    return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }
};

const handleDownload = (anexo) => {
  // Implementar lógica de download
  console.log('Download:', anexo);
};

// Componente Modal de Movimentação
const ModalMovimentacao = ({ isModalOpen, handleCancel, chamado, movimentacaoEdit = null }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const isEditing = !!movimentacaoEdit;

  // Resetar form quando modal abre/fecha ou muda modo
  useEffect(() => {
    if (isModalOpen) {
      if (movimentacaoEdit) {
        form.setFieldsValue({
          STATUS_CHAMADO: movimentacaoEdit.STATUS_CHAMADO,
          COD_ANALISTA_SOLICITADO: movimentacaoEdit.COD_COLABORADOR_SOLICITADO,
          DETALHES: movimentacaoEdit.DETALHES,
          // Outros campos...
        });
      } else {
        form.resetFields();
      }
    }
  }, [isModalOpen, movimentacaoEdit, form]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      
      if (isEditing) {
        // Lógica para atualizar movimentação existente
        console.log('Atualizando movimentação:', {
          id: movimentacaoEdit.ID_MOVIMENTO,
          ...values
        });
      } else {
        // Lógica para criar nova movimentação
        console.log('Nova movimentação:', values);
      }

      handleCancel();
      form.resetFields();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <Space>
          <HistoryOutlined />
          <span>
            {isEditing ? 'Editar' : 'Nova'} Movimentação - Chamado #{chamado?.ID_CHAMADOS}
          </span>
        </Space>
      }
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={800}
    >
      <Alert
        message={
          isEditing 
            ? "Editando movimentação criada em " + 
              moment(movimentacaoEdit.DATA_HRS_MOVIMENTACAO).format('DD/MM/YYYY HH:mm')
            : "Criando nova movimentação"
        }
        type={isEditing ? "warning" : "info"}
        showIcon
        style={{ marginBottom: 24 }}
      />

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        {/* Status e Analista */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="STATUS_CHAMADO"
              label="Novo Status"
              rules={[{ required: true, message: 'Selecione o status' }]}
            >
              <Select>
                {STATUS_OPTIONS.map(status => (
                  <Select.Option key={status.value} value={status.value}>
                    <Badge 
                      status={status.color} 
                      text={status.label}
                    />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="COD_ANALISTA_SOLICITADO"
              label="Analista Responsável"
              rules={[{ required: true, message: 'Selecione o analista' }]}
            >
              <Select
                showSearch
                placeholder="Selecione o analista"
                optionFilterProp="children"
              >
                <Select.Option value="PEDRO.ANALISTA">Pedro (Analista)</Select.Option>
                <Select.Option value="MARIA.ANALISTA">Maria (Analista)</Select.Option>
                <Select.Option value="JOAO.ANALISTA">João (Analista)</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Detalhes da Movimentação */}
        <Form.Item
          name="DETALHES"
          label="Detalhes da Movimentação"
          rules={[{ required: true, message: 'Descreva a movimentação' }]}
        >
          <Input.TextArea 
            rows={6}
            placeholder="Descreva detalhadamente a movimentação..."
            showCount
            maxLength={2000}
            style={{ resize: 'none' }}
          />
        </Form.Item>

        {/* Anexos */}
        <Form.Item
          name="anexos"
          label={
            <Space>
              <PaperClipOutlined />
              Anexos
            </Space>
          }
        >
          <Upload.Dragger
            multiple
            listType="picture"
            action="/api/upload"
            beforeUpload={(file) => {
              const isLt10M = file.size / 1024 / 1024 < 10;
              if (!isLt10M) {
                message.error('Arquivo deve ser menor que 10MB!');
              }
              return isLt10M;
            }}
            onChange={({ file, fileList }) => {
              if (file.status === 'done') {
                message.success(`${file.name} upload realizado com sucesso`);
              } else if (file.status === 'error') {
                message.error(`${file.name} falha no upload`);
              }
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ fontSize: 48, color: '#40a9ff' }} />
            </p>
            <p className="ant-upload-text">
              Clique ou arraste arquivos para anexar
            </p>
            <p className="ant-upload-hint" style={{ color: '#666' }}>
              Suporta múltiplos arquivos. Tamanho máximo: 10MB
            </p>
          </Upload.Dragger>
        </Form.Item>

        {/* Botões de Ação */}
        <Form.Item style={{ marginBottom: 0, marginTop: 24 }}>
          <Row justify="end" gutter={16}>
            <Col>
              <Button onClick={handleCancel}>
                Cancelar
              </Button>
            </Col>
            <Col>
              <Popconfirm
                title={isEditing ? "Confirmar edição?" : "Confirmar movimentação?"}
                description={
                  isEditing 
                    ? "Isso irá alterar os dados da movimentação existente"
                    : "Isso irá criar uma nova movimentação no chamado"
                }
                onConfirm={() => form.submit()}
                okText="Sim"
                cancelText="Não"
              >
                <Button 
                  type="primary" 
                  loading={loading}
                  icon={isEditing ? <SaveOutlined /> : <SendOutlined />}
                >
                  {isEditing ? 'Salvar Alterações' : 'Salvar Movimentação'}
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const MovimentacaoCard = ({ mov, onEdit }) => {
  return (
    <Card 
      style={{ 
        marginBottom: 16,
        backgroundColor: getStatusBgColor(mov.STATUS_CHAMADO),
        border: '1px solid #d9d9d9'
      }}
      extra={
        <Space>
          <Tag color={getStatusColor(mov.STATUS_CHAMADO)}>
            {getStatusText(mov.STATUS_CHAMADO)}
          </Tag>
          <Tooltip title="Editar Movimentação">
            <Button 
              type="text" 
              icon={<EditOutlined />} 
              onClick={() => onEdit(mov)}
            />
          </Tooltip>
        </Space>
      }
    >
      {/* Cabeçalho da Movimentação */}
      <div style={{ 
        borderBottom: '1px solid #f0f0f0',
        marginBottom: 16,
        paddingBottom: 16
      }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Space size="large">
              <Badge 
                status={getStatusColor(mov.STATUS_CHAMADO)}
                text={
                  <Text strong style={{ fontSize: 16 }}>
                    {getStatusText(mov.STATUS_CHAMADO)}
                  </Text>
                }
              />
              <Tag icon={<UserOutlined />} color="blue">
                {mov.CODIGO_ATENDENTE}
              </Tag>
            </Space>
          </Col>
          <Col>
            <Space>
              <ClockCircleOutlined />
              <Text type="secondary">
                {moment(mov.DATA_HRS_MOVIMENTACAO).format('DD/MM/YYYY HH:mm')}
              </Text>
            </Space>
          </Col>
        </Row>
      </div>

      {/* Conteúdo da Movimentação */}
      <div style={{ padding: '0 8px' }}>
        <Paragraph 
          style={{ 
            fontSize: 15,
            whiteSpace: 'pre-line',
            marginBottom: 16 
          }}
        >
          {mov.DETALHES}
        </Paragraph>

        {/* Informações Adicionais */}
        <Row gutter={16}>
          <Col span={12}>
            <Card size="small" bordered={false} style={{ backgroundColor: 'transparent' }}>
              <Space direction="vertical" size="small">
                <Text type="secondary">
                  <UserOutlined /> Informante
                </Text>
                <Text strong>{mov.COD_COLABORADOR_INFORMANTE}</Text>
              </Space>
            </Card>
          </Col>
          {mov.COD_COLABORADOR_SOLICITADO && (
            <Col span={12}>
              <Card size="small" bordered={false} style={{ backgroundColor: 'transparent' }}>
                <Space direction="vertical" size="small">
                  <Text type="secondary">
                    <UserOutlined /> Solicitado
                  </Text>
                  <Text strong>{mov.COD_COLABORADOR_SOLICITADO}</Text>
                </Space>
              </Card>
            </Col>
          )}
        </Row>

        {/* Anexos da Movimentação (se houver) */}
        {mov.anexos && mov.anexos.length > 0 && (
          <div style={{ marginTop: 16 }}>
            <Divider orientation="left">
              <Space>
                <PaperClipOutlined />
                Anexos
              </Space>
            </Divider>
            <Row gutter={[8, 8]}>
              {mov.anexos.map((anexo, index) => (
                <Col span={8} key={index}>
                  <Card
                    size="small"
                    hoverable
                    actions={[
                      <Tooltip title="Download">
                        <DownloadOutlined onClick={() => handleDownload(anexo)} />
                      </Tooltip>,
                      <Tooltip title="Visualizar">
                        <EyeOutlined onClick={() => window.open(anexo.URL_DOCTO)} />
                      </Tooltip>
                    ]}
                  >
                    <Card.Meta
                      avatar={<FileOutlined />}
                      title={anexo.ORIGINALNAME}
                      description={formatFileSize(anexo.size)}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    </Card>
  );
};

// Dados de exemplo para movimentações
const movimentacoesExemplo = [
  {
    ID_MOVIMENTO: 1,
    STATUS_CHAMADO: 1,
    CODIGO_ATENDENTE: "MARIA.SILVA",
    DATA_HRS_MOVIMENTACAO: "2024-03-19T09:30:00",
    DETALHES: "Abertura do chamado:\n\n- Cliente relata erro ao emitir nota fiscal\n- Erro ocorre apenas em notas com ST\n- Necessário verificar cálculos tributários\n\nReproduzi o erro em ambiente de testes e encaminhei para análise.",
    COD_COLABORADOR_INFORMANTE: "JOAO.SANTOS",
    COD_COLABORADOR_SOLICITADO: "PEDRO.ANALISTA",
    anexos: [
      {
        ID_SEQUENCIAL: 1,
        ORIGINALNAME: "erro_calculo.png",
        URL_DOCTO: "https://exemplo.com/erro_calculo.png",
        CONTENTTYPE: "image/png",
        size: 245000
      },
      {
        ID_SEQUENCIAL: 2,
        ORIGINALNAME: "log_erro.txt",
        URL_DOCTO: "https://exemplo.com/log_erro.txt",
        CONTENTTYPE: "text/plain",
        size: 1500
      }
    ]
  },
  {
    ID_MOVIMENTO: 2,
    STATUS_CHAMADO: 2,
    CODIGO_ATENDENTE: "PEDRO.ANALISTA",
    DATA_HRS_MOVIMENTACAO: "2024-03-19T10:45:00",
    DETALHES: "Análise inicial:\n\n1. Identificado problema na rotina de cálculo do ST\n2. Tabela de alíquotas desatualizada\n3. Necessário atualizar patch do sistema\n\nAções tomadas:\n- Iniciada correção do cálculo\n- Solicitado base atualizada para testes",
    COD_COLABORADOR_INFORMANTE: "PEDRO.ANALISTA",
    COD_COLABORADOR_SOLICITADO: null,
    anexos: [
      {
        ID_SEQUENCIAL: 3,
        ORIGINALNAME: "analise_tecnica.pdf",
        URL_DOCTO: "https://exemplo.com/analise.pdf",
        CONTENTTYPE: "application/pdf",
        size: 580000
      }
    ]
  },
  {
    ID_MOVIMENTO: 3,
    STATUS_CHAMADO: 3,
    CODIGO_ATENDENTE: "PEDRO.ANALISTA",
    DATA_HRS_MOVIMENTACAO: "2024-03-19T14:20:00",
    DETALHES: "Desenvolvimento da correção finalizado.\n\nNecessário que o cliente:\n1. Faça backup do banco de dados\n2. Aplique o patch enviado em anexo\n3. Realize testes com os cenários descritos\n\nAguardando retorno do cliente para validação.",
    COD_COLABORADOR_INFORMANTE: "PEDRO.ANALISTA",
    COD_COLABORADOR_SOLICITADO: "MARIA.SILVA",
    anexos: [
      {
        ID_SEQUENCIAL: 4,
        ORIGINALNAME: "patch_v1.2.3.zip",
        URL_DOCTO: "https://exemplo.com/patch.zip",
        CONTENTTYPE: "application/zip",
        size: 1500000
      },
      {
        ID_SEQUENCIAL: 5,
        ORIGINALNAME: "instrucoes_atualizacao.pdf",
        URL_DOCTO: "https://exemplo.com/instrucoes.pdf",
        CONTENTTYPE: "application/pdf",
        size: 350000
      },
      {
        ID_SEQUENCIAL: 6,
        ORIGINALNAME: "cenarios_teste.xlsx",
        URL_DOCTO: "https://exemplo.com/testes.xlsx",
        CONTENTTYPE: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        size: 125000
      }
    ]
  },
  {
    ID_MOVIMENTO: 4,
    STATUS_CHAMADO: 4,
    CODIGO_ATENDENTE: "MARIA.SILVA",
    DATA_HRS_MOVIMENTACAO: "2024-03-19T16:45:00",
    DETALHES: "Cliente aplicou as correções e realizou os testes:\n\n✅ Cálculo do ST corrigido\n✅ Notas fiscais emitidas com sucesso\n✅ Valores conferidos e validados\n\nChamado finalizado com sucesso.\n\nObservações:\n- Realizado treinamento com a equipe do cliente\n- Documentação atualizada\n- Incluído nos casos de teste para validação contínua",
    COD_COLABORADOR_INFORMANTE: "MARIA.SILVA",
    COD_COLABORADOR_SOLICITADO: null,
    anexos: [
      {
        ID_SEQUENCIAL: 7,
        ORIGINALNAME: "evidencia_correcao.png",
        URL_DOCTO: "https://exemplo.com/evidencia.png",
        CONTENTTYPE: "image/png",
        size: 280000
      },
      {
        ID_SEQUENCIAL: 8,
        ORIGINALNAME: "termo_aceite.pdf",
        URL_DOCTO: "https://exemplo.com/aceite.pdf",
        CONTENTTYPE: "application/pdf",
        size: 420000
      }
    ]
  }
];

// Estilo personalizado para o botão de detalhes
const StyledDetailButton = styled(Button)`
  background-color: #1890ff;
  color: white;
  border-radius: 6px;
  border: none;
  padding: 4px 15px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    background-color: #40a9ff;
    color: white;
  }

  &:active {
    background-color: #096dd9;
  }

  .anticon {
    font-size: 16px;
  }
`;

// Estilos globais para as linhas da tabela
const GlobalStyle = createGlobalStyle`
  .row-status-aberto {
    background-color: #e6f7ff;
    &:hover {
      background-color: #bae7ff !important;
    }
  }
  
  .row-status-andamento {
    background-color: #fff7e6;
    &:hover {
      background-color: #ffe7ba !important;
    }
  }
  
  .row-status-aguardando {
    background-color: #fff1f0;
    &:hover {
      background-color: #ffa39e !important;
    }
  }
  
  .row-status-finalizado {
    background-color: #f6ffed;
    &:hover {
      background-color: #b7eb8f !important;
    }
  }

  .ant-table-row {
    transition: all 0.3s ease;
  }
`;

export function ConsultaChamados() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedChamado, setSelectedChamado] = useState(null);
  const [selectedMovimentacao, setSelectedMovimentacao] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dadosChamados, setDadosChamados] = useState([]);
  const { user } = useUsuario();
  const api = useChamados();

  // Função para carregar dados
  const CarregaDados = async (filtros = {}) => {
    try {
      setLoading(true);
      const response = await (await api).ConsultaChamados(filtros, user.TOKEN);
      const data = response.data.map((item, index) => ({
        key: index.toString(),
        ...item
      }));
      setDadosChamados(data);
    } catch (error) {
      message.error('Erro ao carregar chamados');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Carregar dados iniciais
  useEffect(() => {
    CarregaDados();
  }, []);

  // Função para editar movimentação
  const handleEditMovimentacao = (movimentacao) => {
    setSelectedMovimentacao(movimentacao);
    setIsModalOpen(true);
  };

  // Função de busca
  const handleSearch = async (values) => {
    try {
      setLoading(true);
      
      const filtros = {
        ...values,
        dataInicio: values.dataInicio?.format('YYYY-MM-DD'),
        dataFim: values.dataFim?.format('YYYY-MM-DD')
      };

      await CarregaDados(filtros);
    } catch (error) {
      message.error('Erro ao buscar chamados');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Definição das colunas da tabela
  const columns = [
    {
      title: 'Nº Chamado',
      dataIndex: 'ID_CHAMADOS',
      key: 'ID_CHAMADOS',
      width: 120,
      fixed: 'left',
      render: (text) => <Tag color="blue">#{text}</Tag>
    },
    {
      title: 'Cliente',
      dataIndex: 'COD_CLIENTE',
      key: 'COD_CLIENTE',
      width: 200,
      render: (text, record) => (
        <Space>
          <UserOutlined />
          <span>{text}</span>
        </Space>
      )
    },
    {
      title: 'Status',
      dataIndex: 'STATUS_CHAMADO',
      key: 'STATUS_CHAMADO',
      width: 150,
      render: (status) => (
        <Badge 
          status={getStatusColor(status)} 
          text={getStatusText(status)}
        />
      )
    },
    {
      title: 'Departamento',
      dataIndex: 'TIPO_CHAMADO',
      key: 'TIPO_CHAMADO',
      width: 150,
      render: (tipo) => (
        <Tag color={tipo === 1 ? 'green' : 'purple'}>
          {tipo === 1 ? 'SUPORTE' : 'PROGRAMAÇÃO'}
        </Tag>
      )
    },
    {
      title: 'Módulo',
      dataIndex: 'MODULO_SISTEMA',
      key: 'MODULO_SISTEMA',
      width: 150,
    },
    {
      title: 'Data Abertura',
      dataIndex: 'DATA_HRS_MOVIMENTACAO',
      key: 'DATA_HRS_MOVIMENTACAO',
      width: 180,
      render: (date) => (
        <Space>
          <ClockCircleOutlined />
          {moment(date).format('DD/MM/YYYY HH:mm')}
        </Space>
      )
    },
    {
      title: 'Prioridade',
      dataIndex: 'FILA_PRIORIDADE',
      key: 'FILA_PRIORIDADE',
      width: 120,
      render: (prioridade) => (
        <Badge 
          status={getPrioridadeColor(prioridade)}
          text={`P${prioridade}`}
        />
      )
    },
    {
      title: 'Analista',
      dataIndex: 'COD_ANALISTA_SOLICITADO',
      key: 'COD_ANALISTA_SOLICITADO',
      width: 180,
      render: (analista) => (
        analista ? (
          <Space>
            <UserOutlined />
            {analista}
          </Space>
        ) : '-'
      )
    },
    {
      title: 'Ações',
      key: 'acoes',
      fixed: 'right',
      width: 200,
      align: 'center',
      render: (_, record) => (
        <Space size="middle">
          <StyledDetailButton
            onClick={() => {
              setSelectedChamado(record);
              setDrawerVisible(true);
            }}
          >
            <EyeOutlined />
            Detalhes
          </StyledDetailButton>

          <Tooltip title="Movimentar Chamado">
            <Button
              type="primary"
              ghost
              icon={<HistoryOutlined />}
              onClick={() => {
                setSelectedChamado(record);
                setIsModalOpen(true);
              }}
            />
          </Tooltip>
        </Space>
      )
    }
  ];

  // Componente da Tabela
  const TableChamados = ({ dadosChamados, loading }) => {
    return (
      <Table
        columns={columns}
        dataSource={dadosChamados}
        loading={loading}
        rowKey="ID_CHAMADOS"
        scroll={{ x: 1500 }}
        pagination={{
          pageSize: 10,
          showTotal: (total) => `Total: ${total} chamados`,
          showSizeChanger: true,
          showQuickJumper: true
        }}
        rowClassName={(record) => {
          switch (record.STATUS_CHAMADO) {
            case 1: return 'row-status-aberto';
            case 2: return 'row-status-andamento';
            case 3: return 'row-status-aguardando';
            case 4: return 'row-status-finalizado';
            default: return '';
          }
        }}
      />
    );
  };

  // Drawer de Detalhes do Chamado
  const DetalhesDrawer = () => {
    const [activeTab, setActiveTab] = useState('2');

    // Dados de exemplo para um chamado completo
    const dadosExemploChamado = {
      ID_EMPRESA: 1,
      ID_CHAMADOS: 12345,
      COD_CLIENTE: 1001,
      MODULO_SISTEMA: "FISCAL",
      CODIGO_TELA: "NFE001",
      CODIGO_ATENDENTE: "MARIA.SILVA",
      DATA_HRS_MOVIMENTACAO: "2024-03-19T09:30:00",
      COD_COLABORADOR_INFORMANTE: "JOAO.SANTOS",
      TIPO_SOLICITACAO: "CORRECAO",
      COD_FRANQUEADO: 1,
      COD_ANALISTA_SOLICITADO: "PEDRO.ANALISTA",
      NOME_BANCO_DADOS: "PROD_CLIENTE_1001",
      PROJETO_MCN: "PROJETO FISCAL",
      FILA_PRIORIDADE: 2,
      COD_COLABORADOR_SOLICITADO: "PEDRO.ANALISTA",
      TIPO_CHAMADO: 1,
      movimentacoes: movimentacoesExemplo // Array de movimentações definido anteriormente
    };

    // Combinar dados do chamado selecionado com dados de exemplo
    const chamadoCompleto = selectedChamado ? {
      ...dadosExemploChamado,
      ...selectedChamado
    } : null;

    if (!chamadoCompleto) return null;

    return (
      <Drawer
        title={
          <Space>
            <FileSearchOutlined />
            <span>Detalhes do Chamado #{chamadoCompleto.ID_CHAMADOS}</span>
          </Space>
        }
        width={900}
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
      >
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          type="card"
          size="large"
          items={[
            {
              key: '1',
              label: (
                <span>
                  <FileSearchOutlined /> Informações Gerais
                </span>
              ),
              children: (
                <Card bordered={false}>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Text strong>Cliente: </Text>
                      <Text>{chamadoCompleto.COD_CLIENTE}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>Módulo: </Text>
                      <Text>{chamadoCompleto.MODULO_SISTEMA}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>Tela: </Text>
                      <Text>{chamadoCompleto.CODIGO_TELA}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>Tipo Solicitação: </Text>
                      <Tag color="blue">{chamadoCompleto.TIPO_SOLICITACAO}</Tag>
                    </Col>
                    <Col span={12}>
                      <Text strong>Banco de Dados: </Text>
                      <Text>{chamadoCompleto.NOME_BANCO_DADOS}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>Projeto: </Text>
                      <Text>{chamadoCompleto.PROJETO_MCN}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>Prioridade: </Text>
                      <Badge 
                        status={getPrioridadeColor(chamadoCompleto.FILA_PRIORIDADE)} 
                        text={`Prioridade ${chamadoCompleto.FILA_PRIORIDADE}`} 
                      />
                    </Col>
                    <Col span={24}>
                      <Divider orientation="left">Responsáveis</Divider>
                    </Col>
                    <Col span={12}>
                      <Text strong>Atendente: </Text>
                      <Text>{chamadoCompleto.CODIGO_ATENDENTE}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>Analista: </Text>
                      <Text>{chamadoCompleto.COD_ANALISTA_SOLICITADO}</Text>
                    </Col>
                  </Row>
                </Card>
              )
            },
            {
              key: '2',
              label: (
                <span>
                  <HistoryOutlined /> 
                  Movimentações ({chamadoCompleto.movimentacoes?.length || 0})
                </span>
              ),
              children: (
                <div style={{ padding: '16px 0' }}>
                  {chamadoCompleto.movimentacoes?.map((mov, index) => (
                    <MovimentacaoCard key={index} mov={mov} onEdit={handleEditMovimentacao} />
                  ))}
                </div>
              )
            }
          ]}
        />
      </Drawer>
    );
  };

  return (
    <>
      <GlobalStyle />
      <div style={{ padding: '24px', background: '#f0f2f5', minHeight: '100vh' }}>
        <Card>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Title level={4}>
                <Space>
                  <FolderOpenOutlined />
                  Consulta de Chamados
                </Space>
              </Title>
            </Col>
            
            <Col span={24}>
              <SearchBar onSearch={handleSearch} />
            </Col>

            <Col span={24}>
              <TableChamados 
                dadosChamados={dadosChamados}
                loading={loading}
              />
            </Col>
          </Row>
        </Card>

        <DetalhesDrawer 
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          chamado={selectedChamado}
          onEditMovimentacao={handleEditMovimentacao}
        />

        <ModalMovimentacao 
          isModalOpen={isModalOpen}
          handleCancel={() => {
            setIsModalOpen(false);
            setSelectedMovimentacao(null);
          }}
          chamado={selectedChamado}
          movimentacaoEdit={selectedMovimentacao}
        />
      </div>
    </>
  );
}
