import styled from "styled-components";

export const PageContainer = styled.div`
  padding: 24px;
  background: #f0f2f5;
  min-height: 100vh;
`;

export const Container = styled.div`
  .table-container {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .status {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    
    &.active {
      background: #ccffd2;
      color: #43a800;
    }
    
    &.inactive {
      background: #fff1f0;
      color: #ff4d4f;
    }
  }

  .btn-details {
    background: #1890ff;
    color: white;
    border: none;
    padding: 4px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: #40a9ff;
    }
  }

  .details-modal {
    .contact-section, .email-section {
      margin-bottom: 24px;

      h3 {
        margin-bottom: 16px;
        color: #1f1f1f;
        font-size: 16px;
      }
    }
  }

  .ant-table-thead > tr > th {
    background: #fafafa;
    font-weight: 500;
  }

  .ant-table-tbody > tr:hover > td {
    background: #f5f5f5;
  }
`;
