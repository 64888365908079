import { Button } from 'antd';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 95vh;
  background: #f0f2f5;
`;

export const Sidebar = styled.div`
  width: 350px;
  background: #fff;
  border-right: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;

  .search-container {
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;

    .ant-input {
      border-radius: 20px;
      background: #f0f2f5;
    }
  }
`;

export const Header = styled.div`
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 20px;
  }

  .header-actions {
    display: flex;
    gap: 8px;
  }
`;

export const ChatList = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const ChatItem = styled.div`
  display: flex;
  padding: 12px 16px;
  cursor: pointer;
  background: ${props => props.active ? '#f0f2f5' : 'transparent'};

  &:hover {
    background: #f5f5f5;
  }

  .chat-info {
    margin-left: 12px;
    flex: 1;
    min-width: 0;
  }

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    h4 {
      margin: 0;
      font-size: 16px;
    }

    .time {
      font-size: 12px;
      color: #666;
    }
  }

  .chat-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
      color: #666;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const MainChat = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const ChatHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .user-info {
    display: flex;
    align-items: center;
    gap: 12px;

    h3 {
      margin: 0;
      font-size: 16px;
    }

    span {
      font-size: 12px;
      color: #52c41a;
    }

    .ant-select {
      margin-top: 4px;
    }
  }

  .chat-actions {
    display: flex;
    gap: 8px;
  }
`;

export const Messages = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #f0f2f5;

  &.internal-chat {
    height: calc(100vh - 200px);
    
    .message-content {
      strong {
        display: block;
        margin-bottom: 4px;
        color: #1890ff;
      }
    }
  }
`;

export const Message = styled.div`
  display: flex;
  justify-content: ${props => props.className === 'client' ? 'flex-start' : 'flex-end'};

  .message-content {
    max-width: 60%;
    background: ${props => props.className === 'client' ? '#fff' : '#dcf8c6'};
    padding: 8px 12px;
    border-radius: 8px;
    position: relative;

    p {
      margin: 0;
      font-size: 14px;
    }

    .time {
      font-size: 11px;
      color: #666;
      text-align: right;
      margin-top: 4px;
    }
  }
`;

export const MessageInput = styled.div`
  padding: 16px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-input {
    border-radius: 20px;
    background: #f0f2f5;
  }

  .ant-btn-primary {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const NoChat = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #666;
  gap: 16px;

  h3 {
    margin: 0;
    font-size: 20px;
  }

  p {
    margin: 0;
    font-size: 14px;
  }
`;

export const TagsContainer = styled.div`
  margin-top: 4px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;

  .ant-tag {
    margin: 0;
    font-size: 11px;
  }
`;

export const InternalChatButton = styled(Button)`
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  
  &:hover {
    transform: scale(1.05);
  }
`;

export const HistoryItem = styled.div`
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;

  h4 {
    margin: 0 0 8px;
    color: #1890ff;
  }

  p {
    margin: 4px 0;
    
    strong {
      color: #1f1f1f;
    }
  }
`;

export const NotificationStyles = createGlobalStyle`
  .notifications-popover {
    .ant-popover-inner {
      padding: 0;
    }

    .ant-popover-inner-content {
      padding: 0;
    }
  }

  .notification-container {
    width: 360px;
    max-height: 480px;
    display: flex;
    flex-direction: column;
  }

  .notification-header {
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .notification-list {
    max-height: 360px;
    overflow-y: auto;
  }

  .notification-item {
    padding: 12px 16px;
    display: flex;
    gap: 12px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f5f5f5;
    }

    &.unread {
      background-color: #e6f7ff;

      &:hover {
        background-color: #bae7ff;
      }

      .notification-title span {
        font-weight: 500;
      }
    }
  }

  .notification-icon {
    padding-top: 2px;
    
    .anticon {
      font-size: 16px;
    }
  }

  .notification-content {
    flex: 1;
    min-width: 0;

    .notification-title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 4px;

      span {
        font-size: 14px;
        color: #1f1f1f;
      }

      small {
        font-size: 12px;
        color: #8c8c8c;
        white-space: nowrap;
        margin-left: 8px;
      }
    }

    p {
      margin: 0;
      font-size: 13px;
      color: #666;
      line-height: 1.4;
    }
  }

  .notification-footer {
    padding: 8px;
    border-top: 1px solid #f0f0f0;
    
    .ant-btn {
      height: 32px;
      
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
`;

export const GlobalStyles = createGlobalStyle`
  .notifications-popover.ant-popover {
    .ant-popover-inner {
      padding: 0;
      border-radius: 8px;
    }

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner-content {
      padding: 0;
      width: 360px;
    }
  }
`;

export const NotificationContainer = styled.div`
  .notification-header {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;

    h4 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      color: #1f1f1f;
    }
  }

  .notification-list {
    max-height: 360px;
    overflow-y: auto;
    background: #fff;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }

  .notification-item {
    padding: 12px 16px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
    transition: all 0.3s ease;

    &:hover {
      background-color: #f5f5f5;
    }

    &.unread {
      background-color: #e6f7ff;

      &:hover {
        background-color: #bae7ff;
      }
    }

    .notification-icon {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      border-radius: 50%;

      .anticon {
        font-size: 16px;
      }
    }

    .notification-content {
      flex: 1;
      min-width: 0;

      .notification-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;

        span {
          font-size: 14px;
          color: #1f1f1f;
          font-weight: 500;
        }

        small {
          font-size: 12px;
          color: #8c8c8c;
          flex-shrink: 0;
          margin-left: 8px;
        }
      }

      p {
        margin: 0;
        font-size: 13px;
        color: #666;
        line-height: 1.4;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .notification-footer {
    padding: 12px;
    border-top: 1px solid #f0f0f0;
    background: #fff;
    
    .ant-btn {
      height: 32px;
      width: 100%;
      text-align: center;
      color: #1890ff;
      
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
`; 