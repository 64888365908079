import { useState } from 'react';
import { 
  Card, 
  Row, 
  Col, 
  Button, 
  Modal, 
  Form, 
  Select, 
  DatePicker,
  Avatar,
  Tag,
  Divider,
  Typography,
  Empty
} from 'antd';
import { 
  GiftOutlined, 
  UserOutlined, 
  CalendarOutlined,
  PlusOutlined,
  CrownOutlined
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

const { Title, Text } = Typography;

export function Aniversariantes() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // Dados fictícios
  const [aniversariantes, setAniversariantes] = useState([
    { id: 1, nome: 'João Silva', data: '1990-03-15', departamento: 'Suporte', foto: null },
    { id: 2, nome: 'Maria Santos', data: '1988-03-22', departamento: 'Desenvolvimento', foto: null },
    { id: 3, nome: 'Pedro Costa', data: '1995-04-10', departamento: 'Suporte', foto: null },
    { id: 4, nome: 'Ana Oliveira', data: '1992-05-05', departamento: 'Desenvolvimento', foto: null },
    { id: 5, nome: 'Carlos Souza', data: '1987-06-18', departamento: 'Desenvolvimento', foto: null }
  ]);

  const [funcionarios] = useState([
    { id: 1, nome: 'João Silva', departamento: 'Suporte' },
    { id: 2, nome: 'Maria Santos', departamento: 'Desenvolvimento' },
    { id: 3, nome: 'Pedro Costa', departamento: 'Suporte' },
    { id: 4, nome: 'Ana Oliveira', departamento: 'Desenvolvimento' },
    { id: 5, nome: 'Carlos Souza', departamento: 'Desenvolvimento' }
  ]);

  // Agrupa aniversariantes por mês
  const aniversariantesPorMes = aniversariantes.reduce((acc, curr) => {
    const mes = moment(curr.data).format('MMMM');
    if (!acc[mes]) {
      acc[mes] = [];
    }
    acc[mes].push(curr);
    return acc;
  }, {});

  // Ordena os meses começando pelo atual
  const mesesOrdenados = Object.keys(aniversariantesPorMes).sort((a, b) => {
    const mesAtual = moment().format('MMMM');
    const mesAIndex = moment().month(a).format('M');
    const mesBIndex = moment().month(b).format('M');
    return mesAIndex - mesBIndex;
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      console.log('Valores do form:', values);
      // Aqui vai a lógica de salvamento
      handleCancel();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ 
      background: '#f0f2f5',
      minHeight: '100vh',
      padding: '24px'
    }}>
      <div style={{ 
        maxWidth: '1200px',
        margin: '0 auto'
      }}>
        {/* Cabeçalho */}
        <Row justify="space-between" align="middle" style={{ marginBottom: '24px' }}>
          <Col>
            <Title level={2}>
              <GiftOutlined style={{ color: '#ff4d4f', marginRight: '8px' }} />
              Aniversariantes
            </Title>
          </Col>
          <Col>
            <Button 
              type="primary" 
              icon={<PlusOutlined />}
              onClick={showModal}
              size="large"
              style={{
                background: '#ff4d4f',
                borderColor: '#ff4d4f'
              }}
            >
              Cadastrar Aniversário
            </Button>
          </Col>
        </Row>

        {/* Cards dos Meses */}
        <Row gutter={[16, 16]}>
          {mesesOrdenados.map(mes => (
            <Col xs={24} sm={12} lg={8} key={mes}>
              <Card
                title={
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <CalendarOutlined style={{ color: '#ff4d4f' }} />
                    <Text strong style={{ textTransform: 'capitalize' }}>{mes}</Text>
                  </div>
                }
                bordered={false}
                style={{ 
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
              >
                {aniversariantesPorMes[mes].map(aniversariante => (
                  <div 
                    key={aniversariante.id}
                    style={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: '12px',
                      padding: '8px',
                      borderRadius: '6px',
                      background: moment(aniversariante.data).format('DD/MM') === moment().format('DD/MM') ? '#fff1f0' : 'transparent',
                      marginBottom: '8px'
                    }}
                  >
                    <Avatar 
                      size={40}
                      icon={<UserOutlined />}
                      src={aniversariante.foto}
                      style={{ 
                        backgroundColor: aniversariante.foto ? 'transparent' : '#ff4d4f'
                      }}
                    />
                    <div>
                      <Text strong>{aniversariante.nome}</Text>
                      <div>
                        <Tag color={aniversariante.departamento === 'Suporte' ? 'blue' : 'green'}>
                          {aniversariante.departamento}
                        </Tag>
                        <Tag color="red">
                          {moment(aniversariante.data).format('DD/MM')}
                          {moment(aniversariante.data).format('DD/MM') === moment().format('DD/MM') && 
                            <CrownOutlined style={{ marginLeft: '4px', color: '#ffd700' }} />
                          }
                        </Tag>
                      </div>
                    </div>
                  </div>
                ))}
                {aniversariantesPorMes[mes].length === 0 && (
                  <Empty 
                    image={Empty.PRESENTED_IMAGE_SIMPLE} 
                    description="Nenhum aniversariante"
                  />
                )}
              </Card>
            </Col>
          ))}
        </Row>

        {/* Modal de Cadastro */}
        <Modal
          title={
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <GiftOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
              <span>Cadastrar Aniversário</span>
            </div>
          }
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          width={500}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="funcionario"
              label="Funcionário"
              rules={[{ required: true, message: 'Selecione o funcionário' }]}
            >
              <Select
                placeholder="Selecione o funcionário"
                options={funcionarios.map(f => ({
                  label: f.nome,
                  value: f.id,
                  departamento: f.departamento
                }))}
                optionRender={(option) => (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <UserOutlined />
                    {option.label}
                    <Tag color={option.data.departamento === 'Suporte' ? 'blue' : 'green'}>
                      {option.data.departamento}
                    </Tag>
                  </div>
                )}
              />
            </Form.Item>

            <Form.Item
              name="data"
              label="Data de Nascimento"
              rules={[{ required: true, message: 'Selecione a data' }]}
            >
              <DatePicker 
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                locale={locale}
                placeholder="Selecione a data"
              />
            </Form.Item>

            <Form.Item style={{ marginTop: '24px', textAlign: 'right' }}>
              <Button onClick={handleCancel} style={{ marginRight: '8px' }}>
                Cancelar
              </Button>
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={loading}
                style={{
                  background: '#ff4d4f',
                  borderColor: '#ff4d4f'
                }}
              >
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}