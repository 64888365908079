import { Button, Row } from "antd";
import styled from "styled-components";

export const Container = styled(Row)`   
   background-color:  #F0F2F2;
   
   padding: 15px;
   
`;

export const BtDetalhe = styled(Button)`
   background-color: #42afd9;
   color: #fff;
`;

export const BtExcluir = styled(Button)`
   background-color: #ff373d;
   color: #fff;
`;

export const BtContato = styled(Button)`
   background-color: #737373;
   color: #fff;
`;
