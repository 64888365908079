import { api } from "../api"

export const useChamados = async() => ({ 
    ConsultaClientes :  async ( token)=>{
        const response = await api.post('/chamados/listarClientes', 
        {        
               
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )
        return response;      
     },
     ConsultaModulo :  async (token)=>{
        const response = await api.post('/chamados/listarModulos', 
        {  },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },
     ConsultaTelas :  async (token)=>{
        const response = await api.post('/chamados/listarTelas', 
        {  },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },
     StatusChamados :  async (token)=>{
        const response = await api.post('/chamados/listarStatusChamados', 
        {  },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },
     TiposChamados :  async (token)=>{
        const response = await api.post('/chamados/listarTiposChamados', 
        {  },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )
        return response;      
     },
     AbrirChamado :  async (token, dados)=>{
        return await api.post('/chamados/cadastrarChamado', 
        { 
            ...dados
         },
        {
            headers: {
                Authorization: `Bearer ${token}`               
            }
        }         
        )     
     },
     ConsultaChamados :  async (id , token)=>{
        return await api.post('/chamados/consultarChamado',  
        {   
            id     
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                
            }
        }         
        )     
     },


  })