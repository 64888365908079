import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  width: 100%;
`;

export const SearchContainer = styled.div`
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    color: #1f1f1f;
  }

  .ant-input {
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    
    &:hover, &:focus {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
    }
  }

  .ant-btn {
    height: 40px;
    border-radius: 6px;
    font-weight: 500;
    
    &:hover {
      opacity: 0.9;
      transform: translateY(-1px);
      transition: all 0.2s;
    }
  }

  .ant-row {
    margin-bottom: 0;
  }
`; 