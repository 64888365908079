import { Button, Divider, Radio, Table } from "antd";
import { useState } from "react";
import { Container } from "./style";
const columns = [
  {
    title: "Chamado",
    dataIndex: "chamado",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Programador",
    dataIndex: "programador",
  },
  {
    title: "Data Teste",
    dataIndex: "dtTeste",
  },
  {
    title: "Data Finalizado",
    dataIndex: "dtFinalizado",
  },
];
const data = [
  {
    key: "1",
    chamado: "2349",
    programador: "ATIMILSON",
    dtTeste : "04-03-2023 14:52",
    dtFinalizado: "05-03-2023 14:52",
  },
  {
    key: "2",
    chamado: "2348",
    programador: "Manoel",
    dtTeste : "04-03-2023 14:52",
    dtFinalizado: "05-03-2023 14:35",
  },
  {
    key: "3",
    chamado: "2347",
    programador: "Ueltion",
    dtTeste : "04-03-2023 14:52",
    dtFinalizado: "05-03-2023 13:35",
  }, 
];

// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};
export function Producao() {
  return (
    <Container>
      <div style={{ margin: "10px", textAlign: "right", width: "100%" }}>
        <Button type="primary">Liberar </Button>
      </div>
      <Table
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        style={{width: "100%" }}
        columns={columns}
        dataSource={data}
      />
    </Container>
  );
}
