import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { mask, mensagem } from "../../Helpers/UsaGeral";
import { BtContato, BtDetalhe, Container } from "./style";
import { PlusOutlined, SearchOutlined, UserOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { useClientes } from "../../Services/Clientes/useClientes";
import { useUsuario } from "../../Contexts/auth";
import adicionarBtn from "./../../../src/Assets/add-button.png";
import { useContatos } from "../../Services/Contatos";

const data = [
  {
    ID_CLIENTE: "526",
    cod: 526,
    FANTASIA: "John Brown",
    NOME_COMERCIAL: "New York No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
  {
    ID_CLIENTE: "2",
    cod: 2,
    FANTASIA: "Jim Green",
    NOME_COMERCIAL: "London No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
  {
    ID_CLIENTE: "3",
    cod: 3,
    FANTASIA: "Joe Black",
    NOME_COMERCIAL: "Sidney No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
];

const dataInicialCliente = [
  {
    nomeComercial: "",
    fantasia: "",
    cnpj: "",
    cpf: "",
    inscricaoEstadual: "",
    statusCliente: "S",
    tipoCliente: "C",
    tipoEmpresa: "F",
  },
];

export function Contato() {
  const [open, setOpen] = useState(false);

  // const [cliente, setCliente] = useState(dataInicialCliente);
  const [dadosCliente, setClientes] = useState();
  const [dadosSelecionados, setDadosSelecionados] = useState();
  const { user } = useUsuario();
  const [statusCliente, setStatusCliente] = useState("S");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadItens, setLoadItens] = useState(false);
  const [dadosContato, setDadosContato] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [isModalOpenCadastrar, setIsModalOpenCadastrar] = useState(false);
  const api = useContatos();
  const columns = [
    {
      title: "Contrato",
      dataIndex: "ID_CONTATO",
      key: "ID_CONTATO",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Contato",
      dataIndex: "NOME_CONTATO",
      key: "NOME_CONTATO",
    },
    {
      title: "Status",
      dataIndex: "STATUS_CONTATO",
      key: "STATUS_CONTATO",
    },
    {
      title: "Tipo",
      dataIndex: "TIPO_PESSOA",
      key: "TIPO_PESSOA",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            size="small"
            icon={<SearchOutlined />}
            onClick={() => showModal(record)}
          >
            Detalhes
          </Button>
        </Space>
      ),
    },
  ];

  const columnsContatos = [
    {
      title: "Tipo Telefone",
      dataIndex: "TIPO",
      key: "TIPO",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "DDD",
      dataIndex: "DDD",
      key: "DDD",
    },
    {
      title: "Telefone",
      dataIndex: "FONE",
      key: "FONE",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button 
            type="primary"
            danger
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => excluiContato(record)}
          >
            Excluir
          </Button>
        </Space>
      ),
    },
  ];

  const excluiContato = async(dados) => {
    setLoad(true);
    await (await api).delete(dados.ID_CONTATO, user.TOKEN).then((res) => {
      CarregaDadosContato(dados.ID_CONTATO)
    });
    setLoad(false);
  };

  async function CarregaDados() {
    setLoad(true);
    const api = await useContatos();
    await api.ConsultaContatos(filtro.toUpperCase(), user.TOKEN).then((res) => {
      setClientes(res.data);
    });
    setLoad(false);
  }

  async function CarregaDadosContato(dados) {
    setLoadItens(true);
    const api = await useContatos();
    await api.Consulta(dados, user.TOKEN).then((res) => {
      console.log(res.data);
      setDadosContato(res.data);
    });
    setLoadItens(false);
  }

  useEffect(() => {
    CarregaDados();
  }, []);

  const onChange = () => {
    if (statusCliente === "S") {
      setStatusCliente("N");
    } else {
      setStatusCliente("S");
    }
  };

  const showModal = (dados) => {
    console.log(dados.ID_CONTATO)
    CarregaDadosContato(dados.ID_CONTATO);
    setDadosSelecionados(dados);
    console.log(dados);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClose = () => {
    setOpen(!open);
  };

  const SalvarContato = async (dados, contato) => {
    // Salvar dados do contato
    console.log(dados, contato?.ID_CONTATO);
    const api = await useContatos();
    await api.Salvar(dados, contato?.ID_CONTATO, user.TOKEN);
    CarregaDadosContato(contato?.ID_CONTATO);
    setIsAdd(false);
  };

  const SalvarContatoPrincipal = async (dados) => {
    // Salvar dados do contato
    console.log(dados);
    const api = await useContatos();
    await api.CadastrarContato(dados, user.TOKEN);
    CarregaDados()
    setIsModalOpenCadastrar(false)
    mensagem("sucess", "Salvo com sucesso !!!");
  };


  return (
    <Container style={{ 
      background: '#f0f2f5',
      minHeight: '90vh',
      padding: '24px'
    }}>
      {/* Container dos Filtros */}
      <div style={{ 
        background: '#fff',
        padding: '24px',
        borderRadius: '8px',
        marginBottom: '24px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        width: '100%',
        maxWidth: '100%'
      }}>
        {/* Título */}
        <Row style={{ width: '100%', marginBottom: '15px' }}>
          <Col span={24}>
            <div style={{ 
              borderBottom: '2px solid #1890ff',
              paddingBottom: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}>
              <Space>
                <span style={{ fontSize: 18, fontWeight: 500 }}>Contatos</span>
              </Space>
            </div>
          </Col>
        </Row>

        {/* Filtros */}
        <Row gutter={[16, 16]} style={{ width: '100%' }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <Form.Item 
              label="Buscar" 
              style={{ marginBottom: 8 }}
            >
              <Input
                prefix={<SearchOutlined style={{ color: '#1890ff' }} />}
                placeholder="Nome do contato..."
                value={filtro}
                onChange={(e) => setFiltro(e.target.value)}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Form.Item 
              label="Status" 
              style={{ marginBottom: 8 }}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Status"
                options={[
                  { value: "Ativo", label: "Ativo" },
                  { value: "Inativo", label: "Inativo" },
                ]}
                defaultValue={"Ativo"}
              />
            </Form.Item>
          </Col>

          {/* Botões */}
          <Col 
            xs={24} 
            sm={24} 
            md={8} 
            lg={8} 
            xl={8}
            style={{ 
              display: 'flex',
              justifyContent: { xs: 'flex-start', sm: 'flex-end' },
              alignItems: 'flex-end'
            }}
          >
            <Space 
              size="small"
              style={{ 
                width: '100%',
                justifyContent: { xs: 'space-between', sm: 'flex-end' }
              }}
            >
              <Button 
                type="primary" 
                icon={<SearchOutlined />}
                onClick={() => CarregaDados()}
              >
                Pesquisar
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsModalOpenCadastrar(true)}
                style={{ backgroundColor: "#05b305" }}
              >
                Novo
              </Button>
            </Space>
          </Col>
        </Row>
      </div>

      {/* Tabela Principal */}
      <Table
        columns={columns}
        dataSource={dadosCliente}
        loading={load}
        size="middle"
        pagination={{
          pageSize: 10,
          showTotal: (total) => `Total ${total} registros`,
          showSizeChanger: true,
          showQuickJumper: true
        }}
        style={{ 
          background: '#fff',
          borderRadius: '8px',
          width: '100%',
          overflow: 'hidden',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
        }}
        scroll={{ x: 'max-content' }}
      />

      {/* Modal de Detalhes - Voltando ao estilo anterior */}
      <Modal
        title={`Detalhes do Contato - ${dadosSelecionados?.NOME_CONTATO}`}
        open={isModalOpen}
        onCancel={handleCancel}
        width="80%"
        footer={null}
      >
        <div style={{ marginBottom: 24 }}>
          <Row gutter={16} align="middle">
            <Col>
              <Button 
                type="primary" 
                icon={<PlusOutlined />}
                onClick={() => setIsAdd(true)}
              >
                Adicionar Novo Telefone
              </Button>
            </Col>
            <Col>
              <Tag color="blue">ID: {dadosSelecionados?.ID_CONTATO}</Tag>
              <Tag color="cyan">Telefones: {dadosContato?.length || 0}</Tag>
            </Col>
          </Row>
        </div>

        {/* Formulário de novo telefone */}
        {isAdd && (
          <div style={{ 
            marginBottom: 24, 
            padding: 24, 
            background: '#f8f9fa', 
            borderRadius: 8,
            border: '1px solid #e9ecef'
          }}>
            <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
              <Col>
                <h4 style={{ margin: 0 }}>Novo Telefone</h4>
              </Col>
              <Col>
                <Button 
                  type="text" 
                  icon={<CloseOutlined />} 
                  onClick={() => setIsAdd(false)}
                />
              </Col>
            </Row>

            <Form
              onFinish={async (e) => await SalvarContato(e, dadosSelecionados)}
              layout="vertical"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Tipo"
                    name="Tipo"
                    rules={[{ required: true, message: "Preencha o campo Tipo" }]}
                  >
                    <Input placeholder="Ex: Celular, Comercial..." />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="DDD"
                    name="DDD"
                    rules={[{ required: true, message: "Preencha o DDD" }]}
                  >
                    <Input maxLength={2} placeholder="Ex: 11" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Telefone"
                    name="telefone"
                    rules={[{ required: true, message: "Preencha o telefone" }]}
                  >
                    <Input placeholder="Ex: 999999999" />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="end">
                <Space>
                  <Button danger onClick={() => setIsAdd(false)}>
                    Cancelar
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Salvar
                  </Button>
                </Space>
              </Row>
            </Form>
          </div>
        )}

        {/* Tabela de telefones */}
        <Table
          columns={columnsContatos}
          dataSource={dadosContato}
          loading={loadItens}
          size="middle"
          pagination={false}
          style={{ 
            background: '#fff',
            borderRadius: 8,
            overflow: 'hidden'
          }}
        />
      </Modal>

      {/* Modal de Cadastro */}
      <Modal
        title="Novo Contato"
        open={isModalOpenCadastrar}
        onCancel={() => setIsModalOpenCadastrar(false)}
        width="800px"
        footer={null}
        style={{ top: 20 }}
        bodyStyle={{ background: '#f0f2f5', padding: '24px' }}
      >
        <div style={{ 
          background: '#fff',
          padding: '24px',
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
        }}>
          <Form
            onFinish={(e) => SalvarContatoPrincipal(e)}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Nome do Contato"
                  name="contato"
                  rules={[{ required: true, message: "Nome é obrigatório" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item 
                  label="Tipo de Pessoa" 
                  name="Pessoa"
                  initialValue={1}
                >
                  <Radio.Group>
                    <Radio value={1}>Física</Radio>
                    <Radio value={2}>Jurídica</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ type: "email", message: "Email inválido" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item 
                  label="Status" 
                  name="status"
                  initialValue={1}
                >
                  <Radio.Group>
                    <Radio value={1}>Ativo</Radio>
                    <Radio value={2}>Inativo</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Observações"
              name="obs"
            >
              <Input.TextArea rows={4} maxLength={300} showCount />
            </Form.Item>

            <Row justify="end">
              <Space>
                <Button onClick={() => setIsModalOpenCadastrar(false)}>
                  Cancelar
                </Button>
                <Button type="primary" htmlType="submit" style={{ backgroundColor: "#05b305" }}>
                  Salvar
                </Button>
              </Space>
            </Row>
          </Form>
        </div>
      </Modal>
    </Container>
  );
}
