import { Button, Row } from "antd";
import styled from "styled-components";

export const Container = styled(Row)`   
   background-color:  #fff;

   margin:  10px;
   padding: 8px;
`;

export const BtDetalhe = styled(Button)`
   background-color: #42afd9;
   color: #fff;
`;

export const BtContato = styled(Button)`
   background-color: #737373;
   color: #fff;
`;

export const FilterContainer = styled.div`
  .ant-row {
    @media (min-width: 768px) {
      .search-button {
        height: 100%;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-input-search,
  .ant-select {
    width: 100%;
  }

  // Ajustes específicos para responsividade
  @media (max-width: 767px) {
    .ant-col {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .search-button {
      margin-top: 16px;
      height: 48px;
    }
  }

  // Ajustes para telas médias
  @media (min-width: 768px) and (max-width: 991px) {
    .ant-col-md-12 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  // Ajustes para telas grandes
  @media (min-width: 992px) {
    .ant-col-lg-12 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
`;
