import logo from "./logo.svg";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { GlobalStyle } from "./Styles/global";
import { Rotas } from "./Routes";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { UsuarioProvider } from "./Contexts/auth";

function App() {
  return (
    <div className="App">
      <UsuarioProvider>
        <GlobalStyle />
        <ToastContainer autoClose={3000} />
        <BrowserRouter>
          <Rotas />
        </BrowserRouter>
      </UsuarioProvider>
    </div>
  );
}

export default App;
