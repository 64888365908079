import styled from "styled-components";


export const Container =  styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
`;

export const Imagem = styled.img`
   background-size: cover;
   width: 100%;   
   text-align: center;
   align-items: center;
`;

export const Titulo = styled.h1`
  margin-top: 1rem;
  border-radius: 40px;
  padding: 5px 25px;
  color: white;
  font-weight: bold;
  text-align: center;
  border: 3px solid white;
`