import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
  DatePicker,
} from "antd";
import AWS from 'aws-sdk';
import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { mask } from "../../Helpers/UsaGeral";
import { BtContato, BtDetalhe, Container } from "./style";
import { DollarOutlined, SearchOutlined } from "@ant-design/icons";
import { useClientes } from "../../Services/Clientes/useClientes";
import { useUsuario } from "../../Contexts/auth";
import adicionarBtn from "./../../../src/Assets/add-button.png";
import { useContatos } from "../../Services/Contatos";
import axios from "axios";
import { useBackup } from "../../Services/backups";
import moment from "moment";
import { toast } from "react-toastify";
import { useUsuarioServices } from "../../Services/Usuarios";
import { 
  DatabaseOutlined, 
  DownloadOutlined,
  FileOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Statistic, Progress } from 'antd';

const S3_BUCKET = 'backup-bd-clientes';  // Substitua pelo seu bucket
const REGION = 'sa-east-1'; // Substitua pela sua região

export function Backup() {
  const [open, setOpen] = useState(false);

  // const [cliente, setCliente] = useState(dataInicialCliente);
  const [dadosCliente, setClientes] = useState([]);
  const [dadosSelecionados, setDadosSelecionados] = useState();
  const { user } = useUsuario();
  const apiUsuarios = useUsuarioServices();
  const [statusCliente, setStatusCliente] = useState("Com backup");
  const [financeiro, setFinanceiro] = useState("Todos (contratados e não contratados)");
  const [opcaoUsuario, setUsuario] = useState("Todos os usuários");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadItens, setLoadItens] = useState(false);
  const [dadosDetalhes, setDadosDetalhes] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [parametros, setParametros] = useState("&ativo=S&controla_backup=S");
  const [, setDownloadLoading] = useState(false);
  const [downloadPercentage, setDownloadPercentage] = useState(0);
  const [dadosSuporte, setDadosSuporte] = useState([
    { key: user.USUARIO, value: 'Usuário logado' },
    { key: '', value: 'Todos os usuários' },
]);

  const dadosTipoBackup = [
    { key: 'sem', value: 'Sem backup' },
    { key: 'com', value: 'Com backup' }
]

const dadosSituacaoFinanceira = [
    { key: 'S', value: 'Serviço contratado' },
    { key: 'N', value: 'Serviço não contratado' },
    { key: '', value: 'Todos (contratados e não contratados)' }
]


  AWS.config.update({
    accessKeyId: 'AKIAYI6J5DMCOTZWOLC6', // Substitua pela sua Access Key
    secretAccessKey: '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', // Substitua pela sua Secret Key
    region: REGION
});

useEffect(()=>{
 async function carregaUsuarios(){
  let dados = Object.assign([], dadosSuporte);
  await (await apiUsuarios).ConsultaUsuarioBackup(user.USUARIO, user.TOKEN).then(e =>{    
    console.log(e.data)
     e.data.map(u => dados.push({ key: u.key, value: u.key }))
   })
   setDadosSuporte(dados)
 }
 carregaUsuarios()
},[])

const s3 = new AWS.S3();

  const notify = () =>
    toast("iniciando download... ", { autoClose: false, toastId: 1 });
  const update = (tipo, auto, texto) =>
    toast.update(1, { type: tipo, autoClose: false, render: texto });

  const columns = [
    {
      title: "Contrato",
      dataIndex: "contrato",
      key: "contrato",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Fantasia",
      dataIndex: "fantasia",
      key: "fantasia",
    },
    {
      title: "Razão Social",
      dataIndex: "razaoSocial",
      key: "razaoSocial",
    },
    
    {
      title: "Ultimo Backup",
      dataIndex: "ultimoBackup",
      key: "ultimoBackup",
      render: (_, record) =>
        moment(record.ultimoBackup).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Responsavel",
      dataIndex: "suporteResp",
      key: "suporteResp",
    },    
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <BtDetalhe value={record.key} onClick={() => showModal(record)}>
            Detalhes
          </BtDetalhe>
        </Space>
      ),
    },
  ];

  const columnsContatos = [
    {
      title: "ID",
      dataIndex: "idbackup",
      key: "idbackup",
      defaultSortOrder: "descending",
      sorter: (a, b) => a.idbackup - b.idbackup,
    },
    {
      title: "Nome",
      dataIndex: "backupNome",
      key: "backupNome",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
      render: (_, record) => moment(record.data).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "TAMANHO",
      dataIndex: "tamanho",
      key: "tamanho",
    },
    {
      title: "Download",
      dataIndex: "tamanho",
      key: "tamanho",
      render: (_, record) => {
        console.log(record);
        return (
          <Button
            disabled={record.destinoC === null}
            onClick={() =>
              handleDownload(
                record.backupNome ? record.backupNome : "",
                record.backupNome ? record.backupNome : ""
              )
            }
          >
            Download
          </Button>
        );
      },
    },
  ];

  async function CarregaDados() {     
    let tipo = dadosTipoBackup.find(item => item.value === statusCliente)
    let tpFinanceiro = dadosSituacaoFinanceira.find(item => item.value === financeiro)
    let usuario = dadosSuporte.find(item => item.value === opcaoUsuario)
    console.log(usuario)
    setLoad(true);
    const api = await useBackup();
    let dados = {
      ativo: 'S',
      controlaBackup: "S",
      filtroTipoBackup: tipo.key ,
      filtroFinanceiro: tpFinanceiro.key,
      filtroSuporte: usuario.key,
      descPesquisa: filtro,
    };
    await api.Consulta(dados, user.TOKEN).then((res) => {
      setClientes(JSON.parse(res.data));
    }).catch(()=>{
      setClientes([])
      setLoad(false); 
    });
    setLoad(false);
  }
  async function CarregaDadosBackup(dados) {
    setLoadItens(true);
    const api = await useBackup();

    await api.DetalhesConsulta(dados, user.TOKEN).then((res) => {
      console.log(res.data);
      setDadosDetalhes(res.data);
    });
    setLoadItens(false);
  }
  useEffect(() => {
    CarregaDados();
  }, []);

  const showModal = (dados) => {
    console.log(dados);
    setDadosSelecionados(dados);
    CarregaDadosBackup(dados.contrato);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (downloadPercentage > 0) {
      update(
        "info",
        false,
        "Aguarde Processando Arquivo..."
      );
    }
    if (downloadPercentage > 98) {
      update("success", 3000, "Download finalizado!");
    }
  }, [downloadPercentage]);

  const handleDownload = async (key, filename) => {
    notify(); // Notificação para o usuário
    setDownloadLoading(true);

    try {
      const params = {
        Bucket: S3_BUCKET,
        Key: key,
      };
      // Atualizar o progresso para 20%
      setDownloadPercentage(20); 

      s3.getObject(params, (err, data) => {
        if (err) {
            console.error('Erro ao fazer download do arquivo:', err);
            return;
        }

        // Criar um blob do arquivo
        const blob = new Blob([data.Body], { type: data.ContentType });
        const url = window.URL.createObjectURL(blob);

        // Criar um link e iniciar o download
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url); // Liberar o URL
        setDownloadPercentage(100); // Resetar progresso para 0%
    });
    } catch (error) {
      console.error("Erro ao fazer download:", error);
      // Você pode adicionar uma notificação de erro aqui
    }
    
    setDownloadLoading(false);
  };

  const SalvarContato = async (dados, cliente) => {
    // Salvar dados do contato
    console.log(dados, cliente?.ID_CLIENTE);
    const api = await useContatos();
    await api.Salvar(dados, cliente?.ID_CLIENTE, user.TOKEN);
    CarregaDadosBackup(cliente?.ID_CLIENTE);
    setIsAdd(false);
  };
  return (
    <div style={{ 
      background: '#f0f2f5',
      minHeight: '100vh',
      padding: '24px'
    }}>
      <div style={{ 
        background: '#fff',
        padding: '24px',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        margin: '0 auto',
        maxWidth: '1400px'
      }}>
        {/* Cabeçalho */}
        <div style={{ 
          borderBottom: '2px solid #1890ff',
          marginBottom: '24px',
          paddingBottom: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <h1 style={{ 
            margin: 0,
            fontSize: '20px',
            fontWeight: 500,
            color: '#1890ff'
          }}>
            Gerenciamento de Backups
          </h1>
          <Tag color="blue">
            Total: {dadosCliente.length} registros
          </Tag>
        </div>

        {/* Seção de Filtros */}
        <div style={{ 
          background: '#fff',
          padding: '20px',
          borderRadius: '8px',
          marginBottom: '24px',
          boxShadow: '0 1px 4px rgba(0,0,0,0.1)'
        }}>
          <Row gutter={[16, 16]}>
            {/* Coluna da Esquerda - Campos em duas linhas */}
            <Col xs={24} md={20} lg={20}>
              <Row gutter={[16, 16]}>
                {/* Primeira Linha - Pesquisar e Tipo */}
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item 
                    label="Pesquisar" 
                    style={{ marginBottom: 8 }}
                  >
                    <Input.Search
                      placeholder="Buscar por nome, contrato ou razão social"
                      value={filtro}
                      onChange={(e) => setFiltro(e.target.value)}
                      allowClear
                      size="middle"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item 
                    label="Tipo" 
                    style={{ marginBottom: 8 }}
                  >
                    <Select
                      value={statusCliente}
                      onChange={(e) => setStatusCliente(e)}
                      options={dadosTipoBackup}
                      size="middle"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                {/* Segunda Linha - Usuários e Financeiro */}
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item 
                    label="Usuários" 
                    style={{ marginBottom: 8 }}
                  >
                    <Select
                      value={opcaoUsuario}
                      onChange={(e) => setUsuario(e)}
                      options={dadosSuporte}
                      size="middle"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item 
                    label="Financeiro" 
                    style={{ marginBottom: 8 }}
                  >
                    <Select
                      value={financeiro}
                      onChange={(e) => setFinanceiro(e)}
                      options={dadosSituacaoFinanceira}
                      size="middle"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Coluna da Direita - Botão de Busca */}
            <Col xs={24} md={4} lg={4}>
              <div style={{ 
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Button
                  type="primary"
                  onClick={CarregaDados}
                  size="large"
                  style={{ 
                    width: '100%',
                    height: '100%',
                    minHeight: '82px', // Altura aproximada de duas linhas de form
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px'
                  }}
                  loading={load}
                >
                  <SearchOutlined style={{ fontSize: '24px' }} />
                  <span>Buscar</span>
                </Button>
              </div>
            </Col>
          </Row>

          {/* Tags de Filtros Ativos */}
          {(filtro || statusCliente !== 'com' || 
            financeiro !== 'Todos (contratados e não contratados)' || 
            opcaoUsuario !== 'Todos os usuários') && (
            <Row style={{ marginTop: '16px' }}>
              <Col span={24}>
                <Space wrap size={[0, 8]}>
                  {filtro && (
                    <Tag 
                      closable 
                      onClose={() => setFiltro('')} 
                      color="blue"
                      style={{ padding: '4px 8px', margin: '4px' }}
                    >
                      <SearchOutlined /> Busca: {filtro}
                    </Tag>
                  )}
                  {statusCliente !== 'com' && (
                    <Tag 
                      closable 
                      onClose={() => setStatusCliente('com')} 
                      color="green"
                      style={{ padding: '4px 8px', margin: '4px' }}
                    >
                      <DatabaseOutlined /> Tipo: {statusCliente}
                    </Tag>
                  )}
                  {financeiro !== 'Todos (contratados e não contratados)' && (
                    <Tag 
                      closable 
                      onClose={() => setFinanceiro('Todos (contratados e não contratados)')} 
                      color="orange"
                      style={{ padding: '4px 8px', margin: '4px' }}
                    >
                      <DollarOutlined /> Financeiro: {financeiro}
                    </Tag>
                  )}
                  {opcaoUsuario !== 'Todos os usuários' && (
                    <Tag 
                      closable 
                      onClose={() => setUsuario('Todos os usuários')} 
                      color="purple"
                      style={{ padding: '4px 8px', margin: '4px' }}
                    >
                      <UserOutlined /> Usuário: {opcaoUsuario}
                    </Tag>
                  )}
                </Space>
              </Col>
            </Row>
          )}
        </div>

        {/* Tabela Principal */}
        <Table
          columns={columns}
          dataSource={dadosCliente}
          loading={load}
          size="middle"
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => `Total: ${total} registros`
          }}
          style={{ 
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
          }}
        />

        {/* Modal de Detalhes */}
        <Modal
          title={
            <div style={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: '12px'
            }}>
              <DatabaseOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
              <div>
                <div style={{ fontSize: '18px', fontWeight: 500 }}>
                  {dadosSelecionados?.razaoSocial}
                </div>
                <div style={{ fontSize: '14px', color: '#666' }}>
                  Contrato: {dadosSelecionados?.contrato}
                </div>
              </div>
            </div>
          }
          open={isModalOpen}
          onCancel={handleCancel}
          width="80%"
          footer={null}
          style={{ top: 20 }}
        >
          <div style={{ 
            background: '#f5f5f5',
            padding: '12px',
            borderRadius: '6px',
            marginBottom: '16px'
          }}>
            <Row gutter={16}>
              <Col span={8}>
                <Statistic 
                  title="Último Backup" 
                  value={moment(dadosSelecionados?.ultimoBackup).format("DD/MM/YYYY HH:mm")}
                  prefix={<ClockCircleOutlined />}
                />
              </Col>
              <Col span={8}>
                <Statistic 
                  title="Responsável" 
                  value={dadosSelecionados?.suporteResp}
                  prefix={<UserOutlined />}
                />
              </Col>
              <Col span={8}>
                <Statistic 
                  title="Total de Backups" 
                  value={dadosDetalhes?.length || 0}
                  prefix={<DatabaseOutlined />}
                />
              </Col>
            </Row>
          </div>

          <Table
            columns={[
              {
                title: "ID",
                dataIndex: "idbackup",
                width: '10%',
              },
              {
                title: "Nome",
                dataIndex: "backupNome",
                width: '30%',
              },
              {
                title: "Data",
                dataIndex: "data",
                width: '20%',
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.data).unix() - moment(b.data).unix(),
                render: (date) => (
                  <span>
                    <CalendarOutlined style={{ marginRight: 8 }} />
                    {moment(date).format("DD/MM/YYYY HH:mm")}
                  </span>
                )
              },
              {
                title: "Tamanho",
                dataIndex: "tamanho",
                width: '20%',
                render: (size) => (
                  <Tag color="blue">
                    <FileOutlined style={{ marginRight: 8 }} />
                    {size}
                  </Tag>
                )
              },
              {
                title: "Ações",
                width: '20%',
                render: (_, record) => (
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    disabled={!record.destinoC}
                    onClick={() => handleDownload(record.backupNome, record.backupNome)}
                    loading={downloadPercentage > 0 && downloadPercentage < 100}
                  >
                    Download
                  </Button>
                )
              }
            ]}
            dataSource={dadosDetalhes}
            loading={loadItens}
            size="middle"
            pagination={{
              pageSize: 5,
              total: dadosDetalhes?.length,
              showTotal: (total) => `Total: ${total} registros`,
              showSizeChanger: false,
              showQuickJumper: true,
              size: "small",
              style: { 
                marginTop: 16,
                textAlign: 'right'
              }
            }}
            style={{ 
              backgroundColor: '#fff',
              borderRadius: '8px'
            }}
          />

          {/* Progresso do Download */}
          {downloadPercentage > 0 && downloadPercentage < 100 && (
            <div style={{ marginTop: '16px' }}>
              <Progress 
                percent={downloadPercentage} 
                status="active"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
              />
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
}
