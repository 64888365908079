import { useState, useEffect } from "react";
import {
  Table,
  Space,
  Modal,
  Button,
  Row,
  Col,
  Switch,
  message,
  Popconfirm,
  Badge,
} from "antd";
import { SearchBar } from "../../Components/Contadores/SearchBar";
import { ModalDetalhes } from "../../Components/Contadores/ModalDetalhes";
import { ModalClientes } from "../../Components/Contadores/ModalClientes";
import { ModalCadastro } from "../../Components/Contadores/ModalCadastro";
import { Container, PageContainer } from "./style";
import { useContadores } from "../../Services/Contadores";
import { useUsuario } from "../../Contexts/auth";
import { mask } from "../../Helpers/UsaGeral";
import {
  EditOutlined,
  UserAddOutlined,
  CheckCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";

export function Contadores() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCliente, setIsModalOpenCliente] = useState(false);
  const [isModalOpenCadastrar, setIsModalOpenCadastrar] = useState(false);
  const [dadosContador, setContador] = useState([]);
  const [detalhesContador, setDetalhesContador] = useState({});
  const [dadosClientesContador, setClientesContador] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [loadingClientesContador, setLoadingClientesContador] = useState(false);
  const [filtro, setFiltro] = useState("");
  const { user } = useUsuario();
  const apiContadores = useContadores();
  const [modoEdicao, setModoEdicao] = useState(false);
  const [statusFiltro, setStatusFiltro] = useState(null);
  const [clienteFiltro, setClienteFiltro] = useState(null);
  const [clientes, setClientes] = useState([]);

  const handleStatusChange = async (record, checked) => {
    console.log(checked)
    try {
      await (
        await apiContadores
      ).AtualizaStatusContador(
        {
          ID_CONTADOR: record.ID_CONTADOR,
          ATIVO: checked ? 'A' : 'I',
        },
        user.TOKEN
      );

      message.success(
        `Contador ${checked ? "ativado" : "desativado"} com sucesso!`
      );
      CarregaDados();
    } catch (error) {
      message.error("Erro ao alterar status do contador");
      console.error(error);
    }
  };

  const columns = [
    {
      title: "Código",
      dataIndex: "ID_CONTADOR",
      key: "ID_CONTADOR",
      width: 100,
    },
    {
      title: "Nome",
      dataIndex: "NOME",
      key: "NOME",
    },
    {
      title: "CPF/CNPJ",
      dataIndex: "CGC",
      key: "CGC",
      render: (text) => mask(text),
      width: 150,
    },
    {
      title: "CRC",
      dataIndex: "CRC_CONTADOR",
      key: "CRC_CONTADOR",
      width: 120,
    },
    {
      title: "Status",
      dataIndex: "STATUS",
      key: "STATUS",
      width: 100,
      render: (text, record) => (
        <Badge
          status={record.STATUS.trim() === "A" ? "success" : "error"}
          text={record.STATUS.trim() === "A" ? "Ativo" : "Inativo"}
        />
      ),
    },
    {
      title: "Ações",
      key: "action",
      width: 320,
      render: (_, record) => (
        <Space size="small">
          <Button type="primary" size="small" onClick={() => showModal(record)}>
            Detalhes
          </Button>
          <Button
            type="primary"
            size="small"
            style={{ backgroundColor: "#5429cc" }}
            onClick={() => showModalCliente(record)}
          >
            Clientes
          </Button>
          <Button
            type="primary"
            size="small"
            icon={<EditOutlined />}
            style={{ backgroundColor: "#faad14" }}
            onClick={() => {
              setModoEdicao(true);
              setDetalhesContador(record);
              setIsModalOpenCadastrar(true);
            }}
          >
            Editar
          </Button>
          <Popconfirm
            title={`Deseja ${
              record.STATUS.trim() === "A" ? "desativar" : "ativar"
            } este contador?`}
            onConfirm={() => handleStatusChange(record, record.STATUS.trim() === "A" ? false : true)}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              type={record.STATUS.trim() === "A" ? "default" : "primary"}
              size="small"
              danger={record.ATIVO}
              icon={record.STATUS.trim()=== "A" ? <StopOutlined /> : <CheckCircleOutlined />}
            >
              {record.STATUS.trim() === "A" ? "Desativar" : "Ativar"}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  async function CarregaDados() {
    setIsLoading(true);
    try {
      const response = await (
        await apiContadores
      ).ConsultaContadores(
        {
          filtro: filtro.toUpperCase(),
          status: statusFiltro,
          idCliente: clienteFiltro,
        },
        user.TOKEN
      );
      setContador(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    CarregaDados();
  }, [statusFiltro, clienteFiltro]);

  useEffect(() => {
    async function carregaClientes() {
      try {
        const response = await (
          await apiContadores
        ).ConsultaClientesSelec(user.TOKEN);
        setClientes(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    carregaClientes();
  }, []);

  const showModal = (detalhes) => {
    setDetalhesContador(detalhes);
    setIsModalOpen(true);
  };

  const showModalCliente = async (detalhes) => {
    setDetalhesContador(detalhes);
    setIsModalOpenCliente(true);
    await CarregaClientesContador(detalhes.ID_CONTADOR);
  };

  const CarregaClientesContador = async (idContador) => {
    setLoadingClientesContador(true);
    try {
      const response = await (
        await apiContadores
      ).consultaClientesContador(idContador, user.TOKEN);
      setClientesContador(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClientesContador(false);
    }
  };

  return (
    <PageContainer>
      <Container>
        <SearchBar
          filtro={filtro}
          setFiltro={setFiltro}
          CarregaDados={CarregaDados}
          isloading={isloading}
          setIsModalOpenCadastrar={setIsModalOpenCadastrar}
          statusFiltro={statusFiltro}
          setStatusFiltro={setStatusFiltro}
          clienteFiltro={clienteFiltro}
          setClienteFiltro={setClienteFiltro}
          clientes={clientes}
        />

        <div className="table-container">
          <Table
            columns={columns}
            dataSource={dadosContador}
            size="middle"
            loading={isloading}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              showTotal: (total) => `Total: ${total} registros`,
            }}
          />
        </div>

        <ModalDetalhes
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detalhesContador={detalhesContador}
        />

        <ModalClientes
          isOpen={isModalOpenCliente}
          onClose={() => setIsModalOpenCliente(false)}
          detalhesContador={detalhesContador}
          dadosClientesContador={dadosClientesContador}
          loading={loadingClientesContador}
          recarregarDados={() =>
            CarregaClientesContador(detalhesContador.ID_CONTADOR)
          }
        />

        <ModalCadastro
          isOpen={isModalOpenCadastrar}
          onClose={() => {
            setIsModalOpenCadastrar(false);
            setModoEdicao(false);
          }}
          recarregarDados={CarregaDados}
          modoEdicao={modoEdicao}
          dadosContador={modoEdicao ? detalhesContador : null}
        />
      </Container>
    </PageContainer>
  );
}
