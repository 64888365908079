import { useState } from 'react';
import { 
  Table, 
  Button, 
  Modal, 
  Form, 
  DatePicker, 
  Row, 
  Col, 
  Tag, 
  Card,
  Statistic,
  Select,
  Space
} from 'antd';
import { 
  CalendarOutlined,
  UserOutlined,
  PlusOutlined,
  SearchOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

const { RangePicker } = DatePicker;

export function Ferias() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // Dados fictícios
  const [ferias, setFerias] = useState([
    {
      id: 1,
      funcionario: 'João Silva',
      dataInicio: '2024-04-01',
      dataFim: '2024-04-30',
      status: 'Aprovado',
      departamento: 'Suporte'
    },
    {
      id: 2,
      funcionario: 'Maria Santos',
      dataInicio: '2024-05-15',
      dataFim: '2024-06-13',
      status: 'Pendente',
      departamento: 'Desenvolvimento'
    },
    {
      id: 3,
      funcionario: 'Pedro Costa',
      dataInicio: '2024-07-01',
      dataFim: '2024-07-30',
      status: 'Aprovado',
      departamento: 'Suporte'
    }
  ]);

  const [funcionarios] = useState([
    { id: 1, nome: 'João Silva', departamento: 'Suporte' },
    { id: 2, nome: 'Maria Santos', departamento: 'Desenvolvimento' },
    { id: 3, nome: 'Pedro Costa', departamento: 'Suporte' },
    { id: 4, nome: 'Ana Oliveira', departamento: 'Desenvolvimento' },
    { id: 5, nome: 'Carlos Souza', departamento: 'Desenvolvimento' }
  ]);

  // Estados para filtros
  const [filtros, setFiltros] = useState({
    funcionario: undefined,
    departamento: undefined,
    mes: undefined,
    status: undefined
  });

  // Opções para filtros
  const departamentos = ['Suporte', 'Desenvolvimento'];
  const statusOptions = ['Aprovado', 'Pendente', 'Reprovado'];
  const meses = moment.months().map((mes, index) => ({
    label: mes,
    value: index + 1
  }));

  // Função para filtrar os dados
  const getDadosFiltrados = () => {
    return ferias.filter(ferias => {
      const matchFuncionario = !filtros.funcionario || ferias.funcionario === filtros.funcionario;
      const matchDepartamento = !filtros.departamento || ferias.departamento === filtros.departamento;
      const matchStatus = !filtros.status || ferias.status === filtros.status;
      const matchMes = !filtros.mes || moment(ferias.dataInicio).month() + 1 === filtros.mes;
      
      return matchFuncionario && matchDepartamento && matchStatus && matchMes;
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      console.log('Valores do form:', values);
      // Aqui vai a lógica de salvamento
      handleCancel();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Funcionário",
      dataIndex: "funcionario",
      width: '25%',
      render: (text, record) => (
        <Space>
          <UserOutlined />
          {text}
          <Tag color={record.departamento === 'Suporte' ? 'blue' : 'green'}>
            {record.departamento}
          </Tag>
        </Space>
      )
    },
    {
      title: "Período",
      width: '30%',
      render: (_, record) => (
        <Tag color="blue" style={{ padding: '4px 8px' }}>
          <CalendarOutlined style={{ marginRight: 8 }} />
          {moment(record.dataInicio).format("DD/MM/YYYY")} até {moment(record.dataFim).format("DD/MM/YYYY")}
          <span style={{ marginLeft: 8, fontWeight: 'bold' }}>
            ({moment(record.dataFim).diff(moment(record.dataInicio), 'days')} dias)
          </span>
        </Tag>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      width: '15%',
      render: (status) => (
        <Tag
          color={status === 'Aprovado' ? 'success' : 'warning'}
          icon={status === 'Aprovado' ? <CheckCircleOutlined /> : <ClockCircleOutlined />}
        >
          {status}
        </Tag>
      )
    },
    {
      title: "Ações",
      width: '15%',
      render: (_, record) => (
        <Button 
          type="primary"
          onClick={() => {
            form.setFieldsValue({
              funcionario: record.funcionario,
              periodo: [moment(record.dataInicio), moment(record.dataFim)]
            });
            setIsModalOpen(true);
          }}
        >
          Editar
        </Button>
      )
    }
  ];

  return (
    <div style={{ 
      background: '#f0f2f5',
      minHeight: '100vh',
      padding: '24px'
    }}>
      <div style={{ 
        maxWidth: '1200px',
        margin: '0 auto'
      }}>
        {/* Cards de Estatísticas */}
        <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
          <Col xs={24} sm={8}>
            <Card hoverable>
              <Statistic
                title="Férias Agendadas"
                value={ferias.length}
                prefix={<CalendarOutlined style={{ color: '#1890ff' }} />}
                valueStyle={{ color: '#1890ff' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card hoverable>
              <Statistic
                title="Férias em Andamento"
                value={ferias.filter(f => 
                  moment().isBetween(moment(f.dataInicio), moment(f.dataFim))
                ).length}
                prefix={<CheckCircleOutlined style={{ color: '#52c41a' }} />}
                valueStyle={{ color: '#52c41a' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card hoverable>
              <Statistic
                title="Aguardando Aprovação"
                value={ferias.filter(f => f.status === 'Pendente').length}
                prefix={<ClockCircleOutlined style={{ color: '#faad14' }} />}
                valueStyle={{ color: '#faad14' }}
              />
            </Card>
          </Col>
        </Row>

        {/* Botão Nova Solicitação */}
        <Row justify="end" style={{ marginBottom: '16px' }}>
          <Button 
            type="primary" 
            icon={<PlusOutlined />}
            onClick={showModal}
            size="large"
          >
            Nova Solicitação
          </Button>
        </Row>

        {/* Seção de Filtros */}
        <Card 
          size="small" 
          style={{ 
            marginBottom: '16px',
            background: '#fff',
            borderRadius: '8px'
          }}
        >
          <Form layout="vertical">
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item 
                  label="Funcionário" 
                  style={{ 
                    marginBottom: '12px'
                  }}
                >
                  <Select
                    allowClear
                    placeholder="Selecione o funcionário"
                    value={filtros.funcionario}
                    onChange={(value) => setFiltros({ ...filtros, funcionario: value })}
                    options={funcionarios.map(f => ({
                      label: f.nome,
                      value: f.nome
                    }))}
                    style={{ width: '100%' }}
                    dropdownMatchSelectWidth={false}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} lg={6}>
                <Form.Item 
                  label="Departamento"
                  style={{ 
                    marginBottom: '12px'
                  }}
                >
                  <Select
                    allowClear
                    placeholder="Selecione o departamento"
                    value={filtros.departamento}
                    onChange={(value) => setFiltros({ ...filtros, departamento: value })}
                    options={departamentos.map(d => ({
                      label: d,
                      value: d
                    }))}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} lg={6}>
                <Form.Item 
                  label="Mês"
                  style={{ 
                    marginBottom: '12px'
                  }}
                >
                  <Select
                    allowClear
                    placeholder="Selecione o mês"
                    value={filtros.mes}
                    onChange={(value) => setFiltros({ ...filtros, mes: value })}
                    options={meses}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} lg={6}>
                <Form.Item 
                  label="Status"
                  style={{ 
                    marginBottom: '12px'
                  }}
                >
                  <Select
                    allowClear
                    placeholder="Selecione o status"
                    value={filtros.status}
                    onChange={(value) => setFiltros({ ...filtros, status: value })}
                    options={statusOptions.map(s => ({
                      label: s,
                      value: s
                    }))}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>

        {/* Tabela de Férias */}
        <Table
          columns={columns}
          dataSource={getDadosFiltrados()}
          loading={loading}
          pagination={{
            pageSize: 10,
            showTotal: (total) => `Total: ${total} registros`,
            showSizeChanger: false,
            showQuickJumper: true
          }}
          style={{ 
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
          }}
        />

        {/* Modal de Cadastro/Edição */}
        <Modal
          title={
            <Space>
              <CalendarOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
              <span>Solicitação de Férias</span>
            </Space>
          }
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          width={600}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="funcionario"
              label="Funcionário"
              rules={[{ required: true, message: 'Selecione o funcionário' }]}
            >
              <Select
                placeholder="Selecione o funcionário"
                options={funcionarios.map(f => ({
                  label: f.nome,
                  value: f.id,
                  departamento: f.departamento
                }))}
                optionRender={(option) => (
                  <Space>
                    <UserOutlined />
                    {option.label}
                    <Tag color={option.data.departamento === 'Suporte' ? 'blue' : 'green'}>
                      {option.data.departamento}
                    </Tag>
                  </Space>
                )}
              />
            </Form.Item>

            <Form.Item
              name="periodo"
              label="Período"
              rules={[{ required: true, message: 'Selecione o período' }]}
            >
              <RangePicker
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                locale={locale}
                disabledDate={(current) => {
                  return current && current < moment().startOf('day');
                }}
              />
            </Form.Item>

            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: 'Selecione o status' }]}
            >
              <Select
                placeholder="Selecione o status"
                options={statusOptions.map(s => ({
                  label: s,
                  value: s
                }))}
                optionRender={(option) => (
                  <Space>
                    {option.value === 'Aprovado' ? <CheckCircleOutlined style={{ color: '#52c41a' }} /> : 
                     option.value === 'Pendente' ? <ClockCircleOutlined style={{ color: '#faad14' }} /> :
                     <CloseCircleOutlined style={{ color: '#ff4d4f' }} />}
                    {option.label}
                  </Space>
                )}
              />
            </Form.Item>

            <Form.Item style={{ marginTop: '24px', textAlign: 'right' }}>
              <Button onClick={handleCancel} style={{ marginRight: '8px' }}>
                Cancelar
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}