import { Modal, Card, Descriptions, Badge, Tabs } from "antd";
import { 
  UserOutlined, 
  PhoneOutlined, 
  MailOutlined,
  IdcardOutlined,
  ContactsOutlined
} from '@ant-design/icons';
import { formatPhone, formatCPFCNPJ } from '../../../utils/masks';

export function ModalDetalhes({ isOpen, onClose, detalhesContador }) {
  const items = [
    {
      key: '1',
      label: (
        <span>
          <IdcardOutlined /> Dados Básicos
        </span>
      ),
      children: (
        <Card bordered={false} className="tab-card">
          <Descriptions column={1}>
            <Descriptions.Item label="Código">
              {detalhesContador.ID_CONTADOR}
            </Descriptions.Item>
            <Descriptions.Item label="Nome">
              {detalhesContador.NOME}
            </Descriptions.Item>
            <Descriptions.Item label="CPF/CNPJ">
              {formatCPFCNPJ(detalhesContador.CGC)}
            </Descriptions.Item>
            <Descriptions.Item label="CRC">
              {detalhesContador.CRC_CONTADOR}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      ),
    },
    {
      key: '2',
      label: (
        <span>
          <PhoneOutlined /> Telefones
        </span>
      ),
      children: (
        <Card bordered={false} className="tab-card">
          {detalhesContador.FONE1_CONTADOR && (
            <div className="contact-section">
              <Badge 
                status="processing" 
                text={<strong>Telefone Principal</strong>} 
              />
              <Descriptions column={1}>
                <Descriptions.Item label="Número">
                  {formatPhone(detalhesContador.FONE1_CONTADOR)}
                </Descriptions.Item>
                <Descriptions.Item label="Contato">
                  {detalhesContador.NOME_CONTATO_FONE1 || '-'}
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}

          {detalhesContador.FONE2_CONTADOR && (
            <div className="contact-section">
              <Badge 
                status="default" 
                text={<strong>Telefone Secundário</strong>} 
              />
              <Descriptions column={1}>
                <Descriptions.Item label="Número">
                  {formatPhone(detalhesContador.FONE2_CONTADOR)}
                </Descriptions.Item>
                <Descriptions.Item label="Contato">
                  {detalhesContador.NOME_CONTATO_FONE2 || '-'}
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </Card>
      ),
    },
    {
      key: '3',
      label: (
        <span>
          <MailOutlined /> E-mails
        </span>
      ),
      children: (
        <Card bordered={false} className="tab-card">
          {detalhesContador.EMAIL1_CONTADOR && (
            <div className="contact-section">
              <Badge 
                status="processing" 
                text={<strong>E-mail Principal</strong>} 
              />
              <Descriptions column={1}>
                <Descriptions.Item label="E-mail">
                  <a href={`mailto:${detalhesContador.EMAIL1_CONTADOR}`}>
                    {detalhesContador.EMAIL1_CONTADOR}
                  </a>
                </Descriptions.Item>
                {detalhesContador.SETOR_EMAIL1 && (
                  <Descriptions.Item label="Setor">
                    {detalhesContador.SETOR_EMAIL1}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>
          )}

          {detalhesContador.EMAIL2_CONTADOR && (
            <div className="contact-section">
              <Badge 
                status="default" 
                text={<strong>E-mail Secundário</strong>} 
              />
              <Descriptions column={1}>
                <Descriptions.Item label="E-mail">
                  <a href={`mailto:${detalhesContador.EMAIL2_CONTADOR}`}>
                    {detalhesContador.EMAIL2_CONTADOR}
                  </a>
                </Descriptions.Item>
                {detalhesContador.SETOR_EMAIL2 && (
                  <Descriptions.Item label="Setor">
                    {detalhesContador.SETOR_EMAIL2}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>
          )}
        </Card>
      ),
    },
  ];

  return (
    <Modal
      title={
        <span>
          <ContactsOutlined /> Detalhes do Contador
        </span>
      }
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={700}
    >
      <Tabs 
        items={items}
        defaultActiveKey="1"
        className="details-tabs"
      />

      <style jsx>{`
        .contact-section {
          padding: 16px;
          background: #fafafa;
          border-radius: 8px;
          margin-bottom: 16px;
        }

        .contact-section:last-child {
          margin-bottom: 0;
        }

        :global(.details-tabs .ant-tabs-nav) {
          margin-bottom: 16px;
        }

        :global(.details-tabs .ant-tabs-tab) {
          padding: 8px 16px;
        }

        :global(.details-tabs .ant-tabs-tab-active) {
          background: #e6f7ff;
          border-radius: 8px 8px 0 0;
        }

        :global(.tab-card) {
          background: transparent;
          box-shadow: none;
        }

        :global(.ant-descriptions-item-label) {
          color: #8c8c8c;
          font-weight: 500;
        }

        :global(.ant-descriptions-item-content) {
          color: #262626;
        }

        :global(.ant-badge-status-text strong) {
          color: #262626;
          font-size: 14px;
        }

        :global(.ant-descriptions-item-content a) {
          color: #1890ff;
          text-decoration: none;
        }

        :global(.ant-descriptions-item-content a:hover) {
          text-decoration: underline;
        }

        :global(.anticon) {
          color: #1890ff;
          margin-right: 8px;
        }
      `}</style>
    </Modal>
  );
}