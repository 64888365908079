import { 
  SearchOutlined, 
  FilterOutlined, 
  ClearOutlined,
  CalendarOutlined,
  UserOutlined,
  AppstoreOutlined,
  TagOutlined,
  CustomerServiceOutlined,
  CodeOutlined,
  UpOutlined,
  DownOutlined
} from "@ant-design/icons";
import { 
  Button, 
  Col, 
  Input, 
  Row, 
  Select, 
  DatePicker, 
  Space, 
  Divider,
  Card,
  Badge 
} from "antd";
import { useState } from "react";
import { Container, SearchContainer } from "./styles";

const { RangePicker } = DatePicker;

// Constantes
const STATUS_OPTIONS = [
  { value: 1, label: 'Em Aberto', color: 'blue' },
  { value: 2, label: 'Em Andamento', color: 'orange' },
  { value: 3, label: 'Aguardando Cliente', color: 'red' },
  { value: 4, label: 'Finalizado', color: 'green' }
];

const PRIORIDADE_OPTIONS = [
  { value: 1, label: 'Baixa', color: 'blue' },
  { value: 2, label: 'Média', color: 'orange' },
  { value: 3, label: 'Alta', color: 'red' },
  { value: 4, label: 'Crítica', color: 'purple' }
];

const MODULO_OPTIONS = [
  { value: 'FISCAL', label: 'Fiscal', icon: <TagOutlined /> },
  { value: 'FINANCEIRO', label: 'Financeiro', icon: <AppstoreOutlined /> },
  { value: 'CONTABIL', label: 'Contábil', icon: <CalendarOutlined /> }
];

export function SearchBar({ onSearch }) {
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [filters, setFilters] = useState({});

  return (
    <Container>
      <SearchContainer>
        <Card className="search-card">
          {/* Filtros Básicos */}
          <Row gutter={[16, 16]}>
            {/* Número do Chamado - Prioridade em Mobile */}
            <Col xs={24} sm={24} md={8} lg={6}>
              <div className="input-group">
                <label>Número do Chamado</label>
                <Input
                  placeholder="Digite o número"
                  prefix={<SearchOutlined />}
                  onChange={(e) => setFilters({ ...filters, numeroChamado: e.target.value })}
                  value={filters.numeroChamado}
                  allowClear
                />
              </div>
            </Col>

            {/* Status */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <div className="input-group">
                <label>Status</label>
                <Select
                  mode="multiple"
                  placeholder="Selecione o status"
                  onChange={(value) => setFilters({ ...filters, status: value })}
                  value={filters.status}
                  allowClear
                  maxTagCount="responsive"
                  style={{ width: '100%' }}
                >
                  {STATUS_OPTIONS.map(status => (
                    <Select.Option key={status.value} value={status.value}>
                      <Badge status={status.color} text={status.label} />
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>

            {/* Departamento */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <div className="input-group">
                <label>Departamento</label>
                <Select
                  placeholder="Selecione o departamento"
                  onChange={(value) => setFilters({ ...filters, departamento: value })}
                  value={filters.departamento}
                  allowClear
                  style={{ width: '100%' }}
                >
                  <Select.Option value="SUPORTE">
                    <Space>
                      <CustomerServiceOutlined />
                      Suporte
                    </Space>
                  </Select.Option>
                  <Select.Option value="PROGRAMACAO">
                    <Space>
                      <CodeOutlined />
                      Programação
                    </Space>
                  </Select.Option>
                </Select>
              </div>
            </Col>

            {/* Botões de Ação - Nova estrutura */}
            <Col xs={24} sm={24} md={24} lg={6}>
              <div className="input-group">
                <label>&nbsp;</label> {/* Espaço para alinhar com outros campos */}
                <div className="button-container">
                  <Space.Compact className="button-group">
                    <Button 
                      type="primary" 
                      icon={<SearchOutlined />} 
                      onClick={() => onSearch(filters)}
                    >
                      Pesquisar
                    </Button>
                    <Button 
                      icon={<ClearOutlined />} 
                      onClick={() => {
                        setFilters({});
                        onSearch({});
                      }}
                    >
                      Limpar
                    </Button>
                    <Button 
                      type="link" 
                      icon={expandedFilter ? <UpOutlined /> : <DownOutlined />}
                      onClick={() => setExpandedFilter(!expandedFilter)}
                    >
                      {expandedFilter ? 'Menos' : 'Mais'}
                    </Button>
                  </Space.Compact>
                </div>
              </div>
            </Col>
          </Row>

          {/* Filtros Avançados */}
          {expandedFilter && (
            <div className="advanced-filters">
              <Divider />
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <div className="input-group">
                    <label>Período</label>
                    <RangePicker
                      style={{ width: '100%' }}
                      onChange={(dates) => setFilters({ 
                        ...filters, 
                        dataInicio: dates?.[0], 
                        dataFim: dates?.[1] 
                      })}
                      format="DD/MM/YYYY"
                      placeholder={['Data Inicial', 'Data Final']}
                    />
                  </div>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <div className="input-group">
                    <label>Cliente</label>
                    <Select
                      showSearch
                      placeholder="Selecione o cliente"
                      onChange={(value) => setFilters({ ...filters, cliente: value })}
                      value={filters.cliente}
                      allowClear
                      style={{ width: '100%' }}
                    >
                      <Select.OptGroup label="Principais">
                        <Select.Option value="1">Cliente A</Select.Option>
                        <Select.Option value="2">Cliente B</Select.Option>
                      </Select.OptGroup>
                      <Select.OptGroup label="Outros">
                        <Select.Option value="3">Cliente C</Select.Option>
                        <Select.Option value="4">Cliente D</Select.Option>
                      </Select.OptGroup>
                    </Select>
                  </div>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <div className="input-group">
                    <label>Módulo</label>
                    <Select
                      placeholder="Selecione o módulo"
                      onChange={(value) => setFilters({ ...filters, modulo: value })}
                      value={filters.modulo}
                      allowClear
                      style={{ width: '100%' }}
                    >
                      <Select.Option value="FISCAL">Fiscal</Select.Option>
                      <Select.Option value="FINANCEIRO">Financeiro</Select.Option>
                      <Select.Option value="CONTABIL">Contábil</Select.Option>
                    </Select>
                  </div>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <div className="input-group">
                    <label>Prioridade</label>
                    <Select
                      placeholder="Selecione a prioridade"
                      onChange={(value) => setFilters({ ...filters, prioridade: value })}
                      value={filters.prioridade}
                      allowClear
                      style={{ width: '100%' }}
                    >
                      {PRIORIDADE_OPTIONS.map(p => (
                        <Select.Option key={p.value} value={p.value}>
                          <Badge status={p.color} text={`P${p.value} - ${p.label}`} />
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Card>
      </SearchContainer>

      <style jsx>{`
        .search-card {
          box-shadow: 0 2px 8px rgba(0,0,0,0.08);
          border-radius: 8px;
        }

        .input-group {
          display: flex;
          flex-direction: column;
          gap: 8px;
          height: 100%;
        }

        .input-group label {
          font-weight: 500;
          color: #666;
          margin-bottom: 0;
          min-height: 22px; /* Altura fixa para o label */
        }

        .button-container {
          display: flex;
          align-items: flex-start;
          height: 100%;
        }

        .button-group {
          display: flex;
          width: 100%;
        }

        :global(.ant-space-compact) {
          width: 100%;
          display: flex;
        }

        :global(.ant-space-compact .ant-btn) {
          height: 32px;
        }

        @media (max-width: 768px) {
          .button-container {
            margin-top: 0;
          }

          .button-group {
            flex-direction: row;
            justify-content: flex-start;
          }
        }

        @media (max-width: 576px) {
          .button-group {
            flex-direction: column;
            width: 100%;
          }

          :global(.ant-space-compact) {
            flex-direction: column;
            gap: 8px;
          }

          :global(.ant-space-compact .ant-btn) {
            width: 100%;
            margin: 0 !important;
          }
        }
      `}</style>
    </Container>
  );
} 