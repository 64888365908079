import React, { useState } from "react";
import { Layout } from "antd";
import { Container, ContainerConteudo, Sidebar } from "./style";
import { Outlet } from "react-router-dom";
import { MenuTopo } from "./MenuTopo";
import { MenuLateral } from "./MenuLateral";
const { Header, Content, Footer, Sider } = Layout;


/**
* LayoutMain function is responsible for rendering the main layout of the application.
* It includes a responsive sidebar, a header with a collapsible menu, and a content area where the main content of the application is displayed.
*
* @returns {JSX.Element} The main layout of the application.//+
*/
export function LayoutMain() {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <Layout style={{ display: "flex", flex: 1, height: "100vh" }}>
      <Sidebar
        style={{
          backgroundColor: '#0b3b61'
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={256}
      >
        <Container>
          <MenuLateral collapsed={collapsed} />
        </Container>
      </Sidebar>
      <Layout>
        <Header
          style={{
            backgroundColor: "#FFF",
            height: "44px",
            paddingInline: "0",
            boxShadow :  '-1px 4px 11px 1px rgba(0,0,0,0.20)', 
            zIndex:10
          }}
        >
          <MenuTopo toggleCollapsed={toggleCollapsed} collapsed={collapsed} />
        </Header>
        <Content style={{ backgroundColor: "#d9d9d9"}}>
          <ContainerConteudo>
            <Outlet />
          </ContainerConteudo>
        </Content>
        {/* <Footer style={{backgroundColor: '#acacac', maxHeight: 25, padding: 10}} ><b>Mcn Sistemas</b></Footer> */}
      </Layout>
    </Layout>
  );
}
