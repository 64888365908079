import { useState, useEffect } from 'react';
import { 
  Modal, 
  Form, 
  Input, 
  Button, 
  Space, 
  Row, 
  Col,
  Tabs,
  Card,
  Badge,
  message 
} from 'antd';
import { 
  UserOutlined, 
  PhoneOutlined, 
  MailOutlined,
  SaveOutlined,
  CloseOutlined,
  IdcardOutlined,
  EditOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { useContadores } from '../../../Services/Contadores';
import { useUsuario } from '../../../Contexts/auth';

// Funções de formatação
const unMask = (value) => value?.replace(/\D/g, '');

const formatCPFCNPJ = (value) => {
  if (!value) return '';
  
  const numbers = unMask(value);
  
  if (numbers.length <= 11) {
    return numbers
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }
  
  return numbers
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};

const formatPhone = (value) => {
  if (!value) return '';
  
  const numbers = unMask(value);
  
  if (numbers.length === 11) {
    return numbers
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2');
  }
  
  return numbers
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};

export function ModalCadastro({ 
  isOpen, 
  onClose, 
  recarregarDados, 
  modoEdicao, 
  dadosContador 
}) {
  const [form] = Form.useForm();
  const { user } = useUsuario();
  const apiContadores = useContadores();
  const [activeTab, setActiveTab] = useState('1');
  const [loading, setLoading] = useState(false);

  // Handlers para formatação
  const handleCPFCNPJChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatCPFCNPJ(value);
    form.setFieldsValue({ cgc: formattedValue });
  };

  const handlePhoneChange = (index) => (e) => {
    const { value } = e.target;
    const formattedValue = formatPhone(value);
    form.setFieldsValue({ [`fone${index}`]: formattedValue });
  };

  // Efeito para carregar dados no formulário quando em modo edição
  useEffect(() => {
    if (modoEdicao && dadosContador) {
      const formattedData = {
        nome: dadosContador.NOME,
        cgc: formatCPFCNPJ(dadosContador.CGC),
        crc: dadosContador.CRC_CONTADOR,
        fone1: formatPhone(dadosContador.FONE1_CONTADOR),
        fone2: formatPhone(dadosContador.FONE2_CONTADOR),
        fone3: formatPhone(dadosContador.FONE3_CONTADOR),
        nome_contato1: dadosContador.NOME_CONTATO_FONE1,
        nome_contato2: dadosContador.NOME_CONTATO_FONE2,
        nome_contato3: dadosContador.NOME_CONTATO_FONE3,
        email1: dadosContador.EMAIL1_CONTADOR,
        email2: dadosContador.EMAIL2_CONTADOR,
        email3: dadosContador.EMAIL3_CONTADOR,
        setor_email1: dadosContador.SETOR_EMAIL1,
        setor_email2: dadosContador.SETOR_EMAIL2,
        setor_email3: dadosContador.SETOR_EMAIL3
      };
      form.setFieldsValue(formattedData);
    } else {
      form.resetFields();
    }
  }, [modoEdicao, dadosContador, form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = {
        ...values,
        cgc: unMask(values.cgc),
        fone1: unMask(values.fone1),
        fone2: unMask(values.fone2),
        fone3: unMask(values.fone3),
      };

      if (modoEdicao && dadosContador?.ID_CONTADOR) {
        await (await apiContadores).Salvar({
          ID_CONTADOR: dadosContador.ID_CONTADOR,
          ...formData
        }, user.TOKEN);
        message.success('Contador atualizado com sucesso!');
      } else {
        await (await apiContadores).Salvar(formData, user.TOKEN);
        message.success('Contador cadastrado com sucesso!');
      }
      
      onClose();
      recarregarDados();
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error(
        modoEdicao 
          ? 'Erro ao atualizar contador' 
          : 'Erro ao cadastrar contador'
      );
    } finally {
      setLoading(false);
    }
  };

  const items = [
    {
      key: '1',
      label: (
        <span>
          <IdcardOutlined /> Dados Básicos
        </span>
      ),
      children: (
        <Card bordered={false}>
          <Form.Item
            name="nome"
            label="Nome"
            rules={[{ required: true, message: 'Nome é obrigatório' }]}
          >
            <Input size="large" placeholder="Nome do contador" />
          </Form.Item>
          
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="crc"
                label="CRC"
                rules={[{ required: true, message: 'CRC é obrigatório' }]}
              >
                <Input size="large" placeholder="Número do CRC" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="cgc"
                label="CPF/CNPJ"
                rules={[
                  { required: true,  message: 'CPF/CNPJ é obrigatório'},{
                    validator: (_, value) => {
                      if (!value) return Promise.resolve();
                      const numbers = value.replace(/\D/g, '');
                      if (numbers.length !== 11 && numbers.length !== 14) {
                        return Promise.reject('CPF/CNPJ inválido');
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input
                  size="large"
                  placeholder="000.000.000-00"
                  onChange={handleCPFCNPJChange}
                  maxLength={18}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      ),
    },
    {
      key: '2',
      label: (
        <span>
          <PhoneOutlined /> Contatos
          {form.getFieldValue('fone1') && <Badge status="success" style={{ marginLeft: 8 }} />}
        </span>
      ),
      children: (
        <Card bordered={false}>
          {[1, 2, 3].map((index) => (
            <div key={index} className="contact-section">
              <div className="section-header">
                <Badge 
                  status={form.getFieldValue(`fone${index}`) ? 'success' : 'default'} 
                  text={index === 1 ? 'Principal' : index === 2 ? 'Secundário' : 'Adicional'}
                />
              </div>
              <Row gutter={24}>
                <Col span={10}>
                  <Form.Item
                    name={`fone${index}`}
                    label="Telefone"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value) return Promise.resolve();
                          const numbers = value.replace(/\D/g, '');
                          if (numbers.length < 10 || numbers.length > 11) {
                            return Promise.reject('Telefone inválido');
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <Input 
                      size="large" 
                      placeholder="(00) 00000-0000"
                      onChange={handlePhoneChange(index)}
                      maxLength={15}
                      prefix={<PhoneOutlined className="site-form-item-icon" />} 
                    />
                  </Form.Item>
                </Col>
                <Col span={14}>
                  <Form.Item
                    name={`nome_contato${index}`}
                    label="Nome do Contato"
                  >
                    <Input 
                      size="large" 
                      placeholder="Nome do contato"
                      prefix={<UserOutlined className="site-form-item-icon" />} 
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ))}
        </Card>
      ),
    },
    {
      key: '3',
      label: (
        <span>
          <MailOutlined /> E-mails
          {form.getFieldValue('email1') && <Badge status="success" style={{ marginLeft: 8 }} />}
        </span>
      ),
      children: (
        <Card bordered={false}>
          {[1, 2, 3].map((index) => (
            <div key={index} className="email-section">
              <div className="section-header">
                <Badge 
                  status={form.getFieldValue(`email${index}`) ? 'success' : 'default'} 
                  text={index === 1 ? 'Principal' : index === 2 ? 'Secundário' : 'Adicional'}
                />
              </div>
              <Row gutter={24}>
                <Col span={16}>
                  <Form.Item
                    name={`email${index}`}
                    label="E-mail"
                    rules={[
                      { type: 'email', message: 'E-mail inválido' }
                    ]}
                  >
                    <Input 
                      size="large" 
                      placeholder="email@exemplo.com"
                      prefix={<MailOutlined className="site-form-item-icon" />} 
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`setor_email${index}`}
                    label="Setor"
                  >
                    <Input 
                      size="large" 
                      placeholder="Ex: Financeiro" 
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ))}
        </Card>
      ),
    },
  ];

  return (
    <Modal
      title={
        <span>
          {modoEdicao ? (
            <><EditOutlined /> Editar Contador</>
          ) : (
            <><UserAddOutlined /> Novo Contador</>
          )}
        </span>
      }
      open={isOpen}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      footer={null}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Tabs
          items={items}
          activeKey={activeTab}
          onChange={setActiveTab}
          className="custom-tabs"
        />
        
        <Form.Item style={{ marginTop: 24, textAlign: 'right' }}>
          <Space>
            <Button 
              onClick={() => {
                onClose();
                form.resetFields();
              }}
            >
              Cancelar
            </Button>
            <Button 
              type="primary" 
              htmlType="submit"
              loading={loading}
            >
              {modoEdicao ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </Space>
        </Form.Item>
      </Form>

      <style jsx>{`
        .contact-section, .email-section {
          background: #fafafa;
          border-radius: 8px;
          padding: 24px;
          margin-bottom: 24px;
        }

        .section-header {
          margin-bottom: 16px;
        }

        :global(.custom-tabs .ant-tabs-nav) {
          margin-bottom: 24px;
        }

        :global(.ant-tabs-tab) {
          padding: 12px 24px;
          font-size: 15px;
        }

        :global(.ant-tabs-tab-active) {
          background: #e6f7ff;
          border-radius: 8px 8px 0 0;
        }

        :global(.ant-form-item-label > label) {
          font-size: 14px;
          height: 28px;
        }

        :global(.ant-input-prefix) {
          color: #bfbfbf;
          margin-right: 8px;
        }

        :global(.ant-badge-status-text) {
          font-size: 14px;
          font-weight: 500;
        }

        :global(.ant-modal-body) {
          padding: 24px;
          max-height: calc(100vh - 200px);
          overflow-y: auto;
        }

        :global(.ant-form-item) {
          margin-bottom: 24px;
        }

        :global(.ant-input) {
          border-radius: 8px;
        }

        :global(.ant-input:hover) {
          border-color: #40a9ff;
        }

        :global(.ant-input:focus) {
          border-color: #1890ff;
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        }

        :global(.site-form-item-icon) {
          color: #bfbfbf;
        }
      `}</style>
    </Modal>
  );
}