import { AreaBotoes, BtnPerfil, BtnSair, ContainerMenu, ContainerUsuario, EspacoLogo, EspacoMenu, EspacoUsuario, InfoUsuario, LogoUsuario, MenuStyled } from "./style";
import logoMCN from "./../../../Assets/logo_1.png";
import logoUsuario from "./../../../Assets/profile.png";
import contador from "./../../../Assets/contador.png";
import cliente from "./../../../Assets/cliente.png";
import remoto from "./../../../Assets/remoto.png";
import telefone from "./../../../Assets/telefone.png";
import WhatsApp from "./../../../Assets/whatsapp.png";
import funcionario from "./../../../Assets/funcionario.png";
import notas from "./../../../Assets/notas.png";
import dados from "./../../../Assets/dados.png";
import sistema from "./../../../Assets/sistema.png";
import erros from "./../../../Assets/erros.png";
import configuracoes from "./../../../Assets/configuracoes.png";
import dashboard from "./../../../Assets/dashboard.png";
import password from "./../../../Assets/password.png";
import { Link } from "react-router-dom";
import { useUsuario } from "../../../Contexts/auth";


function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem(<Link to={'/DashBoard'} style={{fontSize : '1.2em', fontWeight:'bold'}}>DashBoard</Link>, '0', <img src={dashboard} width={32} />),
  getItem(<Link to={'/Contadores'} style={{fontSize : '1.2em', fontWeight:'bold'}}>Contadores</Link>, '1', <img src={contador} width={32} />),
  getItem(<Link to={'/Clientes'} style={{fontSize : '1.2em', fontWeight:'bold'}}>Clientes</Link>, '2', <img src={cliente} width={32} />),
  getItem(<Link to={'/Contato'} style={{fontSize : '1.2em', fontWeight:'bold'}}>Contato</Link>, '3',  <img src={telefone} width={32} />),
  getItem(<Link to={'/WhatsApp'} style={{fontSize : '1.2em', fontWeight:'bold'}}>WhatsApp</Link>, '5',<img src={WhatsApp} width={28} />),
  getItem(<Link to={'/Remoto'} style={{fontSize : '1.2em', fontWeight:'bold'}}>Remoto</Link>, '11', <img src={remoto} width={32} />),
  getItem(<Link style={{fontSize : '1.2em', fontWeight:'bold'}}>Funcionarios</Link>, '34', <img src={funcionario} width={32} />,[
    getItem(<Link to={'/Escala'} style={{fontSize : '1em', fontWeight:'bold'}}>Escala</Link>, '30'),
    getItem(<Link to={'/Ferias'} style={{fontSize : '1em', fontWeight:'bold'}}>Previsão Ferias</Link>, '31'),    
    getItem(<Link to={'/Aniversarios'} style={{fontSize : '1em', fontWeight:'bold'}}>Aniversariantes</Link>, '32'),
  ]),
  getItem(<Link to={'/Anotacao'} style={{fontSize : '1.2em', fontWeight:'bold'}}>Anotações</Link>, '33', <img src={notas} width={32} /> ),  
  getItem(<Link to={'/Backup'} style={{fontSize : '1.2em', fontWeight:'bold'}}>Backups</Link>, '19', <img src={dados} width={32} /> ),
  getItem(<Link style={{fontSize : '1.2em', fontWeight:'bold'}}>Chamados</Link>, '14',<img src={sistema} width={32} /> ,[
    getItem(<Link to={'/AbrirChamados'} style={{fontSize : '1em', fontWeight:'bold'}}>Abrir Chamados</Link>, '15'),
    getItem(<Link to={'/ConsultarChamados'} style={{fontSize : '1em', fontWeight:'bold'}}>Consultar Chamados</Link>, '16'),    
    // getItem(<Link to={'/ChamadosProgramacao'} style={{fontSize : '1em', fontWeight:'bold'}}>Chamados Programação</Link>, '17')
  ]), 
  getItem(<Link to={'/ErrosClientes'} style={{fontSize : '1.2em', fontWeight:'bold'}}>Erros Clientes</Link>, '13', <img src={erros} width={32} />),
  getItem(<Link to={'/SenhasAcessos'} style={{fontSize : '1.2em', fontWeight:'bold'}}>Senhas/Acessos</Link>, '36', <img src={password} width={32} />),
  getItem(<Link  style={{fontSize : '1.2em', fontWeight:'bold'}}>Configurações</Link>, '20', <img src={configuracoes} width={32} />,[
    getItem(<Link to={'/Usuario'} style={{fontSize : '1em', fontWeight:'bold'}}>Usuarios</Link>, '21'),
    getItem(<Link to={'/Usuario'} style={{fontSize : '1em', fontWeight:'bold'}}>Perfis</Link>, '22'),
    getItem(<Link to={'/Usuario'} style={{fontSize : '1em', fontWeight:'bold'}}>Projetos</Link>, '27'),
    getItem(<Link to={'/Usuario'} style={{fontSize : '1em', fontWeight:'bold'}}>Modulos Sistema</Link>, '28'), 
    getItem(<Link to={'/Usuario'} style={{fontSize : '1em', fontWeight:'bold'}}>Telas Sistema</Link>, '29'),   
    getItem(<Link to={'/Usuario'} style={{fontSize : '1em', fontWeight:'bold'}}>Férias</Link>, '35'),
    getItem(<Link to={'/Usuario'} style={{fontSize : '1em', fontWeight:'bold'}}>Escala</Link>, '36'),
  ])
];



export function MenuLateral({collapsed}) {
  const { ...res } = useUsuario();

  function handleSubmit(e) {
    e.preventDefault();
    res.signOut();
  }
  
  return (
    <ContainerMenu>
      <EspacoLogo>
        <img src={logoMCN} height="32" />
      </EspacoLogo>
      <EspacoUsuario>
         <ContainerUsuario>
            <LogoUsuario>
               <img src={logoUsuario} height="40" /> 
            </LogoUsuario>
            <InfoUsuario style={{display : !collapsed?'flex':'none'}}>
                <h5>Usuario: {res.user.USUARIO}</h5>
                <h5>Cargo: {res.user.PERFIL_DESC}</h5>
                <AreaBotoes>
                  <BtnPerfil>Perfil</BtnPerfil>
                  <BtnSair onClick={(e)=>handleSubmit(e)}>Sair</BtnSair>
                </AreaBotoes>
            </InfoUsuario>
         </ContainerUsuario>
      </EspacoUsuario>
      <EspacoMenu>
          <MenuStyled
            style={{
              width: '98%',
              marginTop: '10px',
              padding : '8px',
              backgroundColor: '#FFF',
              borderRadius: '10px'
            }}

            defaultSelectedKeys={['0']}
            defaultOpenKeys={['sub1']}
            mode={'inline'}
            theme={'light'}
            items={items}
            itemProp
          />
      </EspacoMenu>
    </ContainerMenu>
  );
}
